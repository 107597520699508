import React, {useEffect, useState} from "react";
import arrowLeft from "../../../assets/image/icons/arrow-left.svg";
import arrowRight from "../../../assets/image/icons/arrow-right.svg";
import avatar from "../../../assets/image/default-avatar.png";
import print from "../../../assets/image/icons/print.svg";
import Calendar from "../../components/calendar";
import BeatLoader from "react-spinners/BeatLoader";
import add from "../../../assets/image/icons/add.svg";
import edit from "../../../assets/image/icons/edit.svg";
import deleteIcon from "../../../assets/image/icons/delete.svg";
import {Modal} from "react-bootstrap";
import close from "../../../assets/image/icons/close.svg";
import {toast, ToastContainer} from "react-toastify";
import {paymentActions, userActions} from "../../../redux/actions";
import {connect} from "react-redux";
import x from "../../../assets/image/icons/x.svg";
import doc from "../../../assets/image/icons/doc.png";
import pdf from "../../../assets/image/icons/pdf.png";
import xls from "../../../assets/image/icons/xls.png";

const PaymentCalculation = (props) => {
  const [count, setCount] = useState('5');
  const [page, setPage] = useState('1');
  const [loading, setLoading] = useState(false);
  const [modalAddPayment, setModalAddPayment] = useState(false);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [editUserBoolean, setEditUserBoolean] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteFileArray, setDeleteFileArray] = useState([]);
  const [form, setForm] = useState({
    user_id: '',
    total: '',
    notes: '',
    attachment: [],
    attachment_file: []
  });

  useEffect(() => {
    getPayment()
  },  [count, page]);

  const pageCount = (e) => {
    setCount(e.target.value)
  };

  const clickRight = () => {
    if(props.pagination) {
      if (page < props.pagination.count_pages){
        setPage(+page + 1)
      }
    }
  };

  const clickLeft = () => {
    if (page > 1) {
      setPage(+page - 1)
    }
  };

  const printPage = () =>{
    window.print();
  };

  const getPayment = (day) => {
    setLoading(true)
    if (day) {
      if (day && day.previousDay) {
        props.getPayments({
          previousDay: day.previousDay,
          page: page,
          count: count
        })
      } else if (day.start_date && day.end_date) {
        props.getPayments({
          start_date: day.start_date,
          end_date: day.end_date,
          page: page,
          count: count
        })
      } else if (day.start_date && !day.end_date && !day.previousDay) {
        props.getPayments({
          start_date: day.start_date,
          page: page,
          count: count
        })
      } else if (day.days) {
        props.getPayments({
          days: day.days,
          page: page,
          count: count
        })
      } else if (day.start_period && day.end_period) {
        props.getPayments({
          end_period: day.end_period,
          start_period: day.start_period,
          page: page,
          count: count
        })
      } else if (day.months) {
        props.getPayments({
          months: day.months,
          page: page,
          count: count
        })
      }
    } else {
      props.getPayments({
        previousDay: '6',
        page: page,
        count: count
      })
    }
    setLoading(false)
  };

  const filter = (calendar) => {
    if (calendar.day) {
      getPayment(calendar.day)
    }
  };

  const deletePayment = () => {
    props.deletePayment(deleteModalShow).then(res => {
      if (res.type === 'DELETE_PAYMENT_SUCCESS') {
        setDeleteModalShow(false)
        toast.success('Расчёт удален');
      } else {
        const data = res.error.data
        const keys = Object.keys(data)
        toast.error(data[keys[0]][0]);
      }
    })
  };

  const addPaymentModalShow = () => {
    setEditUserBoolean(false)
    props.getAllUsersList().then(res => {
      if (res.type === 'GET_ALL_USER_SUCCESS') {
        if (res.allUsers.data && res.allUsers.data.length) {
          setForm({...form, user_id: res.allUsers.data[0].id})
          setModalAddPayment(true)
        } else {
          toast.error("This branch don't have workers!")
          setModalAddPayment(false)
        }
      }
    });
  };

  const openEditModal = (payment) => {
    setForm({
      id: payment.id,
      user_id: payment.user.id,
      total: payment.total,
      notes: payment.notes,
      attachment: payment.attachment
    });
    setEditUserBoolean(true)
    setModalAddPayment(true)
  };
  const hideAddModal = () => {
    setForm({
      user_id: '',
      total: '',
      notes: '',
      attachment: [],
      attachment_file: []
    });
    setDeleteFileArray([])
    setModalAddPayment(false)
  };

  const paymentCreateUpdate = (e) => {
    e.preventDefault()
    setAddButtonDisabled(true)
    let formData = new FormData(e.currentTarget)
    if (!editUserBoolean) {
      props.paymentCreate(formData).then(res => {
        if (res) {
          if (res.type === 'CREATE_PAYMENT_SUCCESS') {
            hideAddModal()
            toast.success('Расчёт добавлен');
          } else {
            const data = res.error.data
            const keys = Object.keys(data)
            toast.error(data[keys[0]][0]);
          }
        } else (
          toast.error('Server error')
        );
        setAddButtonDisabled(false)
      });
    } else {
      formData.append('id',form.id)
      if (deleteFileArray && deleteFileArray.length) {
        props.deletePaymentFile(deleteFileArray)
      }
      props.paymentUpdate(formData).then(res => {
        if (res) {
          if (res.type === 'UPDATE_PAYMENT_SUCCESS') {
            hideAddModal()
            toast.success('Расчёт обновлён');
          } else {
            const data = res.error.data
            const keys = Object.keys(data)
            toast.error(data[keys[0]][0]);
          }
        } else (
          toast.error('Server error')
        );

        setAddButtonDisabled(false)
      });
    }
  };

  const uploadFile = (e) => {
    let files = e.target.files;
    let filesLists = []
    for (let i = 0; i < files.length; i++) {
      let fileType = files[i].name.match(/\.[0-9a-z]+$/i)[0]
      if (fileType === '.doc' || fileType === '.docx' || fileType === '.xls' || fileType === '.xlsx' || fileType === '.pdf') {
        filesLists.push(fileType)
      } else {
        let reader = new FileReader();
        reader.onload = (event) => {
          filesLists.push(event.target.result)
        };
        reader.readAsDataURL(files[i])
      }
    }
    setTimeout(() => {
      setForm({...form, attachment_file: filesLists})
    }, 5)

  };

  const removeFile = (id) => {
    let fileArr = [...form.attachment]
    let idList = [...deleteFileArray]
    idList.push(id)
    setDeleteFileArray(idList)
    fileArr = fileArr.filter(item => item.id !== id)
    setForm({...form, attachment: fileArr})
  };

  return (
    <div className="performance">
      {loading ?
        <div className="loading-block">
          <BeatLoader color="#0A65FF" loading={loading} size={20}/>
        </div> : null
      }
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bread-crumbs-mini"><p>Расчет оплаты</p></div>
      <div className="top-block d-flex align-items-center justify-content-end">
        <button className="add-button" type="button" onClick={() => addPaymentModalShow()}><img src={add} alt="Add"/><div>add payment</div></button>
      </div>
      <div className="performance-block">
        <Calendar calendarFilter={(calendar) => filter(calendar)}/>
        <div className="scroll-table bonuses-table calculation">
          <div className="scroll-touch scroll-div">
            <table>
              <thead>
              <tr>
                <th>User</th>
                <th>total</th>
                <th>notes</th>
                <th>attachment</th>
                <th>actions</th>
              </tr>
              </thead>
              <tbody>
              {props.payments && props.payments.length ?
              props.payments.map((item, index) => {
                return(
                <tr key={index}>
                  <td>
                    <div className="user">
                      <div className="avatar">
                        {item.user.avatar ?
                        <img src={item.user.avatar} alt=""/> :
                        <img src={avatar} alt=""/>
                        }
                      </div>
                      <div className="name">{item.user.first_name} {item.user.last_name}</div>
                    </div>
                  </td>
                  <td>
                    <div className="td-total">{item.total}$</div>
                  </td>
                  <td className="notes">{item.notes}</td>
                  <td className="attachment">
                    <div className="attachment-block">
                      {item.attachment && item.attachment.length ?
                      item.attachment.map((file, i) => {
                        return (
                        <a key={i} href={file.file_name} target="_blank">
                          {file.file_type === 'pdf' ?
                          <img src={pdf} alt="pdf" className="file"/> :
                          file.file_type === 'doc' || file.file_type === 'docx' ?
                          <img src={doc} alt="doc" className="file"/> :
                          file.file_type === 'xls' || file.file_type === 'xlsx' ?
                          <img src={xls} alt="xls" className="file"/> :
                          <img src={file.file_name} alt={file.file_name} className="file"/>
                          }
                        </a>
                        )
                      }) : null
                      }
                    </div>
                  </td>
                  <td>
                    <div className="bonus-action">
                      <button onClick={() => openEditModal(item)} type="button" className="edit-button"><img src={edit} alt="Edit"/><div>edit</div></button>
                      <button type="button" className="delete-button" onClick={() => setDeleteModalShow(item)}><img src={deleteIcon} alt="Delete"/><div>delete</div></button>
                    </div>
                  </td>
                </tr>
                )
              }) : null
              }
              </tbody>
            </table>
          </div>
        </div>
        <div className="general-total">
          <div className="item">
            <div className="key">total</div>
            <div className="value">${props.total}</div>
          </div>
        </div>
        <div className="table-pagination">
          <div className="page-count">
            <span>{props.pagination  && props.pagination.count_skip ? props.pagination.skip_page + 1 : '0'}-{props.pagination ? props.pagination.count_skip : ''}</span> of {props.pagination ? props.pagination.count : ''}
          </div>
          <div className="row-page">
            <div className="d-flex align-items-center mr-5">
              <p>Rows per page:</p>
              <select onChange={ (event) => {pageCount(event)}} className="select-picker" data-width="fit">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </div>
            <div>
              <img src={arrowLeft} onClick={() => {clickLeft()}} alt="Left" className="mr-5 cursor-pointer"/>
              <img src={arrowRight} onClick={() => {clickRight()}} alt="Right" className="cursor-pointer"/>
            </div>
          </div>
        </div>
      </div>
      <button type="button" onClick={printPage} className="print-page"><img src={print} alt="Print"/>Print this page</button>
      <Modal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        centered
        dialogClassName="user-delete-modal"
      >
        <Modal.Body>
          <div className="title">Вы действитльно хотите удалить расчёт?</div>
          <div className="text">Расчёт будет удален</div>
          <button onClick={() => deletePayment()} type="button">удалить</button>
          <div className="close" onClick={() => setDeleteModalShow(false)}>
            <img src={close} alt="Close"/>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalAddPayment}
        onHide={hideAddModal}
        centered
        dialogClassName="add-user-modal"
      >
        <Modal.Body>
          <form className="add-user" onSubmit={(e) => paymentCreateUpdate(e)}>
            <div className="personal-data">
              <div className="title">Расчёт</div>
              <div className="form-group">
                <div className="form-block position-relative">
                  <label htmlFor="total">Сумма</label>
                  <input onInvalid={(e) => e.target.setCustomValidity('Поле сумма обязательно для заполнения!')} onInput={(e) => e.target.setCustomValidity('')} name="total" className="mt-2" value={form.total} onChange={(e) => {e.target.value = e.target.value.replace(/[^0-9.]/g, ''); setForm({...form, total:e.target.value})}} required  type="text" id="total"/>
                </div>
                {!editUserBoolean ?
                  <div className="form-block position-relative">
                    <label htmlFor="user_id">Пользователь</label>
                    <select className="select-role mt-2" value={form.user_id} name="user_id" onChange={e => setForm({...form, user_id: e.target.value})} id="user_id" data-width="fit">
                      {props.allUsersList  && props.allUsersList.length ?
                        props.allUsersList.map((item, index) => {
                          return(
                            <option key={index} value={item.id}>{item.name}</option>
                          )
                        })
                        : null
                      }
                    </select>
                  </div> : null
                }
                <div className="form-block position-relative w-100">
                  <label htmlFor="notes">Описание</label>
                  <textarea name="notes" className="mt-2 w-100" value={form.notes} onChange={(e) => setForm({...form, notes:e.target.value})} id="notes"/>
                </div>
                {form.attachment && form.attachment.length ?
                  <div className="form-block w-100">
                    <label>Файлы</label>
                    <div className="upload-file-list">
                      {form.attachment.map((file, index) => {
                        return (
                          <div key={index} className="upload-file-item" onClick={() => window.open(file.file_name)}>
                            {file.file_type === 'pdf' ?
                              <img src={pdf} alt="" className="file"/> :
                            file.file_type === 'doc' || file.file_type === 'docx' ?
                              <img src={doc} alt="" className="file"/> :
                            file.file_type === 'xls' || file.file_type === 'xlsx' ?
                              <img src={xls} alt="" className="file"/> :
                              <img src={file.file_name} alt={file.file_name} className="file"/>
                            }
                            <div className="hover-file">
                              <img src={x} onClick={(e) => {
                                e.stopPropagation();
                                removeFile(file.id)
                              }} alt="X"/>
                            </div>
                          </div>
                        )
                      })
                      }
                    </div>
                  </div> : null
                }
                <label htmlFor="attachment" className="upload-file">
                  <input type="file" multiple accept=".xlsx,.xls,image/*,.doc, .docx,.pdf" id="attachment"
                         name="attachment[]"  onChange={(e) => uploadFile(e)} className="d-none"/>добавить файл
                </label>
                {form.attachment_file && form.attachment_file.length ?
                  <div className="upload-file-list">
                    {form.attachment_file.map((item, index) => {
                      return (
                        <div key={index} className="upload-file-item">
                          {item === '.pdf' ?
                            <img src={pdf} alt="" className="file"/> :
                          item === '.doc' || item === '.docx' ?
                            <img src={doc} alt="" className="file"/> :
                          item === '.xls' || item === '.xlsx' ?
                            <img src={xls} alt="" className="file"/> :
                            <img src={item} alt={item} className="file"/>
                          }
                        </div>
                      )
                    })
                    }
                  </div> : null
                }
              </div>
            </div>
            <button className={addButtonDisabled ? 'button-disabled submit' : 'submit'} disabled={addButtonDisabled}>{editUserBoolean ? 'Редактировать расчёт' : 'Добавить расчёт'}</button>
            <div className="close" onClick={hideAddModal}>
              <img src={close} alt="Close"/>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    payments: state.paymentReducer.payments,
    total: state.paymentReducer.total,
    pagination: state.paymentReducer.pagination,
    allUsersList: state.userReducer.allUsersList
  };
}

const mapDispatchToProps = branch => ({
  getPayments: (data) => branch(paymentActions.getPayments(data)),
  getAllUsersList: (data) => branch(userActions.getAllUsersList(data)),
  paymentCreate: (data) => branch(paymentActions.paymentCreate(data)),
  paymentUpdate: (data) => branch(paymentActions.paymentUpdate(data)),
  deletePayment: (data) => branch(paymentActions.deletePayment(data)),
  deletePaymentFile: (data) => branch(paymentActions.deletePaymentFile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCalculation);
