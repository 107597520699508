let url = "http://jjmanage_back.loc/api";

if(process.env.NODE_ENV === "production"){
  url = "https://mgmap.jjmanage.site/api";
}
const API_URL = url;

export {
  API_URL
}
