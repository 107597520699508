import {paymentsAccountingConstants} from '../constants/actionTypes';
import {paymentsAccountingService} from '../services';

export const paymentsAccountingActions = {
  getPaymentsAccounting,
  paymentsAccountingCreate,
  getRole,
  addUserWorkerType,
};

function getPaymentsAccounting(data) {
  return dispatch => {
    dispatch(request());
    return  paymentsAccountingService.paymentsAccounting(data)
    .then(response => {
      console.log('response.data', response.data);
      dispatch(success(response.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response));
    })
  };

  function request() { return { type: paymentsAccountingConstants.PAYMENTS_ACCOUNTING_REQUEST } }
  function success(paymentsAccounting) { return { type: paymentsAccountingConstants.PAYMENTS_ACCOUNTING_SUCCESS, paymentsAccounting } }
  function failure(error) { return { type: paymentsAccountingConstants.PAYMENTS_ACCOUNTING_FAILURE, error } }
}

function paymentsAccountingCreate(data) {
  return dispatch => {
    return  paymentsAccountingService.addPaymentsAccounting(data)
    .then(response => {
      return dispatch(success(response.data));
    })
    .catch(function (error) {
      return dispatch(failure(error.response));
    })
  };

  function success(paymentsAccounting) { return { type: paymentsAccountingConstants.CREATE_PAYMENTS_ACCOUNTING_SUCCESS, paymentsAccounting } }
  function failure(error) { return { type: paymentsAccountingConstants.CREATE_PAYMENTS_ACCOUNTING_FAILURE, error } }
}

function getRole() {
  return dispatch => {
    return  paymentsAccountingService.getRoleList()
    .then(response => {
      return dispatch(success(response.data));
    })
  };

  function success(role) { return { type: paymentsAccountingConstants.GET_ROLES_SUCCESS, role } }
}

function addUserWorkerType(params) {
  return dispatch => {
    return  paymentsAccountingService.userWorkerType(params)
    .then(response => {
      return dispatch(success(response.data));
    })
  };

  function success(workerType) { return { type: paymentsAccountingConstants.USER_WORKER_TYPE_SUCCESS, workerType } }
}


