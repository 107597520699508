import { checkConstants } from '../constants/actionTypes';

const initialState = {
  checkList: [],
  pagination: {}
};

export function checkReducer(state = initialState, action) {
  switch (action.type) {
    case checkConstants.CHECK_REQUEST:
      return {...state};
    case checkConstants.CHECK_SUCCESS:
      return {
        ...state,
        checkList: action.checkList.data,
        pagination: action.checkList.pagination,
      };
    case checkConstants.CHECK_FAILURE:
      return {
        ...state,
        checkList: [],
      };
    case checkConstants.CREATE_CHECK_REQUEST:
      return {...state};
    case checkConstants.CREATE_CHECK_SUCCESS:
      let checks = [...state.checkList];
      checks.unshift(action.check.data)
      return {
        ...state,
        checkList: checks,
      };
    case checkConstants.CREATE_CHECK_FAILURE:
      return {...state};
    default:
      return state;
  }
}
