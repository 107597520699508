import {http} from "../../modules/http";

export const salesReportsService = {
  salesReport,
  addSalesReport,
};

function salesReport(params) {
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params)
  return http.get('/sales-report?' + q.toString()).then(res => {
    return res
  })
}

function addSalesReport(params) {
  params.branch_id = localStorage.getItem('branch')
  return http.post('/sales-report', params).then(res => {
    return res
  })
}

