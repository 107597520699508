import React, {useEffect, useState} from "react";
import arrow from "../../../assets/image/icons/arrow.svg";
import left from "../../../assets/image/icons/left.svg";
import right from "../../../assets/image/icons/right.svg";
import { Line } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import print from "../../../assets/image/icons/print.svg";
import {connect} from "react-redux";
import {bonusActions} from "../../../redux/actions";
import Calendar from "../../components/calendar";
import BeatLoader from "react-spinners/BeatLoader";

const BonusSchedule = (props) => {
  const [filterDate, setFilterDate] = useState('Неделя');
  const [itemBonus, setItemBonus] = useState({bonus: [] , color: [] });
  const [loading, setLoading] = useState(false);


  const options = {
    spanGaps: false,
    maintainAspectRatio: false,
    layout: { padding: { left: 0, right: 0 } },
    elements: {
      point:{ radius: 4, borderWidth: 2, hoverRadius: 4, hoverBorderWidth: 5 }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        yAlign: 'bottom',
        backgroundColor: '#FFFFFF',
        titleColor: '#9F9F9F',
        titleFont: {
          weight: '400',
          size: '12'
        },
        titleMarginBottom: 10,
        bodyColor: '#282828',
        bodyFont: {
          weight: '600',
          size: '14'
        },
        padding: 15,
        cornerRadius: 8,
        displayColors: false,
        callbacks: {
          label: function(tooltipItem) {
            return "Total" + tooltipItem.formattedValue;
          },
          title: function(tooltipItem) {
            return tooltipItem[0].label;
          }
        }
      },
    },
    tension: 0.3,
    scales: {
      xAxes: [
        { gridLines: { display: false },
          ticks: { fontColor: "rgba(0, 0, 0, 0.54)" } } ],
      yAxes: [
        {
          gridLines: {
            tickMarkLength: 16,
            color: "rgba(0, 0, 0, 0.1)"
          },
          ticks:   {
            stepSize: 5,
            min: 0,
            fontColor: "rgba(0, 0, 0, 0.54)"
          }
        }
      ]
    },
  };
  const optionsReport = {
    responsive: true,
    plugins: {
      tooltip: {
        yAlign: 'bottom',
        backgroundColor: '#FFFFFF',
        bodyColor: '#282828',
        bodyFont: {
          weight: '400',
          size: '12'
        },
        padding: 15,
        cornerRadius: 8,
        displayColors: false,
        callbacks: {
          label: function(tooltipItem) {
            return tooltipItem.label + ' ' + tooltipItem.formattedValue;
          }
        }
      },
      legend: {
        display: false
      },
      title: {
        display: false,
      },
    }
  }

  const getBonus = (day) => {
    setLoading(true)
    if (day) {
      if (day && day.previousDay) {
        props.getBonusSchedule({
          previousDay: day.previousDay,
        }).finally(() => {
          setLoading(false)
        })
      } else if (day.start_date && day.end_date) {
        props.getBonusSchedule({
          start_date: day.start_date,
          end_date: day.end_date,
        }).finally(() => {
          setLoading(false)
        })
      } else if (day.start_date && !day.end_date && !day.previousDay) {
        props.getBonusSchedule({
          start_date: day.start_date,
        }).finally(() => {
          setLoading(false)
        })
      } else if (day.days) {
        props.getBonusSchedule({
          days: day.days,
        }).finally(() => {
          setLoading(false)
        })
      } else if (day.start_period && day.end_period) {
        props.getBonusSchedule({
          end_period: day.end_period,
          start_period: day.start_period,
        }).finally(() => {
          setLoading(false)
        })
      } else if (day.months) {
        props.getBonusSchedule({
          months: day.months,
        }).finally(() => {
          setLoading(false)
        })
      }
    }
  };


  useEffect(() => {
    setTime();
  } , [props.bonusSchedule])

  const setTime = () => {
    setTimeout(() => {
      if (Object.keys(props.bonusSchedule).length) {
        if (props.bonusSchedule.userBonusesTotal) {
          let bonus = []
          let color = []
          let user = []
          props.bonusSchedule.userBonusesTotal.map((item) => {
            bonus.push(item.bonus)
            color.push('#' + item.color)
            user.push(item.user.name + ' ' + item.user.worker_type_name)
          })
          setItemBonus({bonus: bonus , color: color, user: user})
        }
      }
    }, 0);
  }

  const filter = (calendar) => {
    if (calendar.day) {
      getBonus(calendar.day)
    }
  };

  const printPage = () =>{
    window.print();
  };

  return (
    <div className="staff-detail">
      {loading ?
        <div className="loading-block">
          <BeatLoader color="#0A65FF" loading={loading} size={20}/>
        </div> : null
      }
      <div onClick={() => props.history.goBack()} className="bread-crumbs"><img src={arrow} alt="arrow"/><p>Назад</p></div>
      <div className="bread-crumbs-mini"><img src={arrow} alt="arrow" onClick={() => props.history.goBack()}/><p>График бонусов</p></div>
      <div className="page-title">График бонусов</div>
      <div className="bonus-schedule">
        <div className="total">
          <div className="title">Отчет по общему количеству бонусов</div>
          <div className="chart-body">
            <div className="chart-options">
              <div className="choose">
                Choose Week
                <div className="date">
                  <button type="button"><img src={left} alt="left"/></button>
                  <Calendar calendarFilter={(calendar) => filter(calendar)}/>
                  <button type="button"><img src={right} alt="right"/></button>
                </div>
              </div>
              <div className="filter">
                <button type="button" className={filterDate === 'День' ? 'active-filter' : ''} onClick={() => {setFilterDate('День'); getBonus({previousDay: 1})}}>День</button>
                <button type="button" className={filterDate === 'Неделя' ? 'active-filter' : ''} onClick={() => {setFilterDate('Неделя'); getBonus({previousDay: 6})}}>Неделя</button>
                <button type="button" className={filterDate === 'Месяц' ? 'active-filter' : ''} onClick={() => {setFilterDate('Месяц'); getBonus({previousDay: 30})}}>Месяц</button>
              </div>
            </div>
            <div className="chart-info">
              <span></span>
              <div>— среднее количество бонусов</div>
            </div>
            <div className="chart">
              {props.bonusSchedule && props.bonusSchedule.bonusesTotal && !loading ?
                  <Line
                      data={{
                        labels: props.bonusSchedule.bonusesDate,
                        datasets: [
                          {
                            label: 'Dataset 1',
                            data: props.bonusSchedule.bonusesTotal,
                            borderColor: '#0A65FF',
                            backgroundColor: '#0A65FF',
                          },
                        ]
                      }}
                      options={options}
                  /> : ''
              }
            </div>
          </div>
        </div>
        <div className="day-report">
          <div className="title">Отчет по сотрудникам за день</div>
          <div className="chart-body">
            <div className="chart-options">
              {props.bonusSchedule.userBonusesTotal && !loading ? props.bonusSchedule.userBonusesTotal.map((item ,index) => {
                return (
                    <div key={index} className="item">
                      <span className="circle" style={{backgroundColor: '#' + item.color}}></span>
                      <div>—   {item.user.name}<span>({item.user.worker_type_name})</span></div>
                    </div>
                )
              }) : ''
              }
            </div>
            <div className="chart">
              {itemBonus.user && !loading ?
                  <Doughnut
                      data={{
                        labels: itemBonus.user,
                        datasets: [
                          {
                            label: 'Report',
                            data:  itemBonus.bonus,
                            backgroundColor: itemBonus.color,
                          }
                        ]
                      }}
                      options={optionsReport}
                  /> : ''
              }
            </div>
          </div>
        </div>
      </div>
      <button onClick={printPage} type="button" className="print-page"><img src={print} alt="Print"/>Print this page</button>
    </div>
  );
};




const mapStateToProps = state => {
  return {
    bonusSchedule: state.bonusReducer.bonusSchedule,
  };
}

const mapDispatchToProps = d => ({
  getBonusSchedule: (params) => d(bonusActions.getBonusSchedule(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BonusSchedule);

