import { paymentConstants } from '../constants/actionTypes';

const initialState = {
  payments: [],
  total: '',
  pagination: {}
};

export function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case paymentConstants.PAYMENT_REQUEST:
      return {...state};
    case paymentConstants.PAYMENT_SUCCESS:
      return {
        ...state,
        payments: action.payments.data,
        total: action.payments.total,
        pagination: action.payments.pagination,
      };
    case paymentConstants.PAYMENT_FAILURE:
      return {
        ...state,
        payments: [],
      };
    case paymentConstants.CREATE_PAYMENT_REQUEST:
      return {...state};
    case paymentConstants.CREATE_PAYMENT_SUCCESS:
      let paymentsList = [...state.payments];
      let total = state.total;
      paymentsList.unshift(action.payment.data)
      total += +action.payment.data.total
      return {
        ...state,
        total: total,
        payments: paymentsList,
      };
    case paymentConstants.CREATE_PAYMENT_FAILURE:
      return {...state};
    case paymentConstants.UPDATE_PAYMENT_REQUEST:
      return {...state};
    case paymentConstants.UPDATE_PAYMENT_SUCCESS:
      let totalUpdate = 0;
      let paymentsListData = [...state.payments];
      paymentsListData = paymentsListData.map(item => {
        if (item.id === action.payment.data.id) {
          item = action.payment.data
        }
        totalUpdate += +item.total
        return item
      });
      return {
        ...state,
        total: totalUpdate,
        payments: paymentsListData,
      };
    case paymentConstants.UPDATE_PAYMENT_FAILURE:
      return {...state};
    case paymentConstants.DELETE_PAYMENT_REQUEST:
      return {...state};
    case paymentConstants.DELETE_PAYMENT_SUCCESS:
      let deletePaymentsList = [...state.payments];
      deletePaymentsList = deletePaymentsList.filter(item => item.id !== action.payment.id);
      let totalSum = state.total
      return {
        ...state,
        payments: deletePaymentsList,
        total: totalSum - +action.payment.total,
      };
    case paymentConstants.DELETE_PAYMENT_FAILURE:
      return {...state};
    case paymentConstants.DELETE_PAYMENT_FILE_REQUEST:
      return {...state};
    case paymentConstants.DELETE_PAYMENT_FILE_SUCCESS:
      return {...state};
    case paymentConstants.DELETE_PAYMENT_FILE_FAILURE:
      return {...state};
    default:
      return state;
  }
}
