import { paymentsAccountingConstants } from '../constants/actionTypes';
import moment from "moment";

const initialState = {
  paymentsAccounting: {},
  pagination: {},
  roles: {},
  cashSales: '',
  paymentsAccountingMessage: null,
};

export function paymentsAccountingReducer(state = initialState, action) {
  switch (action.type) {
    case paymentsAccountingConstants.PAYMENTS_ACCOUNTING_REQUEST:
      return {...state,
        paymentsAccounting: {},
        paymentsAccountingMessage: null
      };
    case paymentsAccountingConstants.PAYMENTS_ACCOUNTING_SUCCESS:
      let paymentsAccountingMessage = null;
      if (action.paymentsAccounting.data) {
        let minutes = 0;
        Object.entries(action.paymentsAccounting.data).map(([ userKey, userItem], userIndex) => {
          let sum = {}
          Object.entries(userItem.performances).map(([perKay, perItem], perIndex) => {
            let  array = {...perItem}
            perItem['time_in'].price.map((pays, perfIndex) => {
              if (array['account'] && array['account'].price) {
                let text = array['account'].price.method;
                let myArray = text.split(",")
                for (let i = 0; i < myArray.length; i++) {
                  let myArr = myArray[i].split(" ")
                  for (let j = 0; j < myArr.length; j++) {
                    myArr[j] = myArr[j].toLowerCase()
                    if (array[myArr[j]]) {
                      if (j !== 0 && myArr[j] !== '%' && myArr[j] !== '$') {
                        if (array[myArr[j]].price[perfIndex].rating) {
                          myArr[j] = array[myArr[j]].price[perfIndex].rating
                        } else {
                          myArr[j] = 0
                        }
                      }
                    } else {
                      if (myArr[j][myArr[j].length - 1] !== '%' && myArr[j][myArr[j].length - 1] !== '$' && myArr[j] !== '-' && myArr[j] !== '+' && myArr[j] !== '*' && myArr[j] !== '/' && myArr[j] !== '=' && !+myArr[j]) {
                        paymentsAccountingMessage = 'Тhere are errors in the account method.';
                        return false
                      }
                    }
                  }
                  let string = myArr.join(' ')
                  let result = string.split(" = ")
                  let percent = result[1].split(" ")
                  for (let i = 0; i < percent.length; i++) {
                    if (percent[i][percent[i].length - 1] === '%') {
                      percent[i] = '( ( ' + percent[i - 2] + ' * ' + percent[i].split('%')[0] +' ) / 100 )'
                    } else if (percent[i][percent[i].length - 1] === '$') {
                      percent[i] = percent[i].split('$')[0]
                    }
                  }
                  result[1] = percent.join(' ');
                  array[result[0]].price[perfIndex].rating = eval(result[1]) > 0 ? eval(result[1]).toFixed(2) : null
                }
              }
              Object.entries(array).map(([ key, paymentsItem], paymentsIndex) => {
                if (key === 'net_pay') {
                  const netPay = +array[key].price[perfIndex].rating
                  if (netPay) {
                    const types = [
                      {
                        name: 'salary_percent',
                        id: 19
                      },
                      {
                        name: 'retention_with_salary',
                        id: 20
                      },
                      {
                        name: 'cash_percent',
                        id: 21
                      },
                      {
                        name: 'check_mdm',
                        id: 22
                      },
                      {
                        name: 'check',
                        id: 23
                      }
                    ]
                    types.map(type => {
                      let percent = +array[type.name].price[perfIndex].percent
                      let total = netPay * percent;
                      array[type.name].price[perfIndex].rating = total.toFixed(2)
                      // createPrice(total.toFixed(2), day, type.id, user_id, jobId)
                      // let sum = 0
                      // Object.entries(userItem.performances).map(([ kay, paymentsItem], paymentsIndex) => {
                      //   userItem.performances[kay][type.name].price.map((subtotalItem, hoursIndex) => {
                      //     if (subtotalItem && subtotalItem.rating) {
                      //       console.log(subtotalItem)
                      //       sum += +subtotalItem.rating
                      //       userItem.total[type.name] = sum
                      //     }
                      //   })
                      // })
                    })
                  }
                }

                if (key === 'hours') {
                    if (array[key].price[perfIndex].rating) {
                      let hours = +array[key].price[perfIndex].rating.split(':')[0]
                      let minute = +array[key].price[perfIndex].rating.split(':')[1]
                      let hoursMinute = hours * 60;
                      let minuteHours = hoursMinute + minute
                      minutes += minuteHours
                      action.paymentsAccounting.data[userKey].total['hours'] = Math.floor(minutes / 60) + ':' + minutes % 60
                    }
                  } else if (key === 'tipping_percentage'){} else if (key !== 'job' && key !== 'rate_per_hour' && key !== 'account' && key !== 'time_in' && key !== 'time_out' && key !== 'notes') {
                      if (array[key]) {
                         if (action.paymentsAccounting.data[userKey].total[key]) {
                           action.paymentsAccounting.data[userKey].total[key] += +array[key].price[perfIndex].rating
                         } else {
                           action.paymentsAccounting.data[userKey].total[key] = +array[key].price[perfIndex].rating
                         }
                       } else {
                        action.paymentsAccounting.data[userKey].total[key] = 0
                       }
                  } else {
                    action.paymentsAccounting.data[userKey].total[key] = key
                  }
              })
            })
          })
        })
      }

      let stateData = {};
      if (paymentsAccountingMessage) {
        stateData = {
          paymentsAccounting: {},
          pagination: {},
          cashSales: '',
          paymentsAccountingMessage: paymentsAccountingMessage
        }
      } else {
        stateData = {
          paymentsAccounting: action.paymentsAccounting.data,
          pagination: action.paymentsAccounting.pagination,
          cashSales: action.paymentsAccounting.cash_sales,
          paymentsAccountingMessage: null
        }
      }

      return {
        ...state,
        paymentsAccounting: stateData.paymentsAccounting,
        pagination: stateData.pagination,
        cashSales: stateData.cashSales,
        paymentsAccountingMessage: stateData.paymentsAccountingMessage

      };
    case paymentsAccountingConstants.PAYMENTS_ACCOUNTING_FAILURE:
      return {
        ...state,
        paymentsAccounting: {},
        paymentsAccountingMessage: null
      };
    case paymentsAccountingConstants.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.role.data,
      };

    case paymentsAccountingConstants.USER_WORKER_TYPE_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
}
