import {http} from "../../modules/http";

export const paymentService = {
  getPayment,
  addPayment,
  updatePayment,
  paymentDelete,
  deletePaymentFile
};

function getPayment(params) {
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params)
  return http.get('/payment-calculation?' + q.toString()).then(res => {
    return res
  })
}

function addPayment(params) {
  return http.post('/payment-calculation', params).then(res => {
    return res
  })
}

function updatePayment(params) {
  return http.post('/update-payment-calculation', params).then(res => {
    return res
  })
}

function paymentDelete(params) {
  return http.delete('/payment-calculation/' + params.id).then(res => {
    return res
  })
}

function deletePaymentFile(params) {
  return http.post('/delete-payment-attachment', params).then(res => {
    return res
  })
}

