import {accountConstants} from '../constants/actionTypes';
import {accountService} from '../services';

export const accountActions = {
  getAccountsList,
  accountCreate,
  accountUpdate,
  deleteAccount,
  getAccountType,
  getAccounts
};

function getAccountsList(data) {
  return dispatch => {
    dispatch(request());
    return accountService.getAccountsList(data)
    .then(response => {
      dispatch(success(response.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response));
    })
  };

  function request() { return { type: accountConstants.GET_ACCOUNTS_LIST_REQUEST } }
  function success(accounts) { return { type: accountConstants.GET_ACCOUNTS_LIST_SUCCESS, accounts } }
  function failure(error) { return { type: accountConstants.GET_ACCOUNTS_LIST_FAILURE, error } }
}

function accountCreate(data) {
  return dispatch => {
    dispatch(request());
    return accountService.addAccount(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: accountConstants.CREATE_ACCOUNT_REQUEST } }
  function success(account) { return { type: accountConstants.CREATE_ACCOUNT_SUCCESS, account } }
  function failure(error) { return { type: accountConstants.CREATE_ACCOUNT_FAILURE, error } }
}

function accountUpdate(data) {
  return dispatch => {
    dispatch(request());
    return accountService.updateAccount(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: accountConstants.UPDATE_ACCOUNT_REQUEST } }
  function success(account) { return { type: accountConstants.UPDATE_ACCOUNT_SUCCESS, account } }
  function failure(error) { return { type: accountConstants.UPDATE_ACCOUNT_FAILURE, error } }
}

function deleteAccount(data) {
  return dispatch => {
    dispatch(request());
    return accountService.deleteAccount(data)
      .then(() => {
        return dispatch(success(data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: accountConstants.DELETE_ACCOUNT_REQUEST } }
  function success(account) { return { type: accountConstants.DELETE_ACCOUNT_SUCCESS, account } }
  function failure(error) { return { type: accountConstants.DELETE_ACCOUNT_FAILURE, error } }
}

function getAccounts() {
  return dispatch => {
    dispatch(request());
    return accountService.getAccounts()
      .then(response => {
        return dispatch(success(response.data.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: accountConstants.GET_ACCOUNTS_REQUEST } }
  function success(accountsList) { return { type: accountConstants.GET_ACCOUNTS_SUCCESS, accountsList } }
  function failure(error) { return { type: accountConstants.GET_ACCOUNTS_FAILURE, error } }
}

function getAccountType() {
  return dispatch => {
    return accountService.accountType()
      .then(response => {
        return dispatch(success(response.data.data));
      })
  };

  function success(accountsType) { return { type: accountConstants.GET_ACCOUNT_TYPE_SUCCESS, accountsType } }
}


