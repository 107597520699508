import React, {useEffect, useState} from "react";
import arrowLeft from "../../../assets/image/icons/arrow-left.svg";
import arrowRight from "../../../assets/image/icons/arrow-right.svg";
import avatar from "../../../assets/image/default-avatar.png";
import print from "../../../assets/image/icons/print.svg";
import Calendar from "../../components/calendar";
import {bonusActions} from "../../../redux/actions";
import {userActions} from "../../../redux/actions";
import {connect} from "react-redux";
import add from "../../../assets/image/icons/add.svg";
import {Modal} from "react-bootstrap";
import close from "../../../assets/image/icons/close.svg";
import {toast, ToastContainer} from "react-toastify";
import edit from "../../../assets/image/icons/edit.svg";
import deleteIcon from "../../../assets/image/icons/delete.svg";
import BeatLoader from "react-spinners/BeatLoader";

const Bonuses = (props) => {
  const [count, setCount] = useState('5');
  const [page, setPage] = useState('1');
  const [modalAddBonus, setModalAddBonus] = useState(false);
  const [editUserBoolean, setЕditUserBoolean] = useState(false);
  const [addButtonDisabled, setАddButtonDisabled] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    user_id: '',
    bonuses: '',
    description: ''
  });

  useEffect(() => {
    getBonus()
  },  [count, page]);

  const pageCount = (e) => {
    setCount(e.target.value)
  };

  const clickRight = () => {
    if(props.pagination) {
      if (page < props.pagination.count_pages){
        setPage(+page + 1)
      }
    }
  };

  const clickLeft = () => {
    if (page > 1) {
      setPage(+page - 1)
    }
  };

  const printPage = () =>{
    window.print();
  };

  const getBonus = async (day) => {
    setLoading(true)
    let data = null;
    if (day) {
      if (day && day.previousDay) {
        data = {
          previousDay: day.previousDay,
        }
      } else if (day.start_date && day.end_date) {
        data = {
          start_date: day.start_date,
          end_date: day.end_date,
        }
      } else if (day.start_date && !day.end_date && !day.previousDay) {
        data = {
          start_date: day.start_date,
        }
      } else if (day.days) {
        data = {
          days: day.days,
        }
      } else if (day.start_period && day.end_period) {
        data = {
          end_period: day.end_period,
          start_period: day.start_period,
        }
      } else if (day.months) {
        data = {
          months: day.months,
        }
      }
    } else {
      data = {
        previousDay: '6',
      }
    }
    if (data) {
      data.page = page
      data.count = count
      await props.getBonuses(data)
    };
    setLoading(false)
  };

  const filter = (calendar) => {
    if (calendar.day) {
      getBonus(calendar.day)
    }
  };

  const addBonusModalShow = () => {
    setЕditUserBoolean(false)
    props.getAllUsersList().then(res => {
      if (res.type === 'GET_ALL_USER_SUCCESS') {
        if (res.allUsers.data && res.allUsers.data.length) {
          setForm({...form, user_id: res.allUsers.data[0].id})
          setModalAddBonus(true)
        } else {
          toast.error("This branch don't have workers!")
          setModalAddBonus(false)
        }
      }
    });
  };

  const openEditModal = (bonus) => {
    setForm({
      id: bonus.id,
      user_id: bonus.user.id,
      bonuses: bonus.bonuses,
      description: bonus.description
    });
    setЕditUserBoolean(true)
    setModalAddBonus(true)
  };

  const hideAddModal = () => {
    setForm({
      user_id: '',
      bonuses: '',
      description: ''
    });
    setModalAddBonus(false)
  };

  const deleteBonus = () => {
    props.deleteBonus(deleteModalShow).then(res => {
      if (res.type === 'DELETE_BONUS_SUCCESS') {
        setDeleteModalShow(false)
        toast.success('Бонус удален');
      } else {
        const data = res.error.data
        const keys = Object.keys(data)
        toast.error(data[keys[0]][0]);
      }
    })
  };

  const bonusCreateUpdate = (e) => {
    e.preventDefault()
    setАddButtonDisabled(true)
    if (!editUserBoolean) {
      props.bonusCreate(form).then(res => {
        if (res.type === 'CREATE_BONUS_SUCCESS') {
          hideAddModal()
          toast.success('Бонус добавлен');
        } else {
          const data = res.error.data
          const keys = Object.keys(data)
          toast.error(data[keys[0]][0]);
        }
        setАddButtonDisabled(false)
      });
    } else {
      props.bonusUpdate(form).then(res => {
        if (res.type === 'UPDATE_BONUS_SUCCESS') {
          hideAddModal()
          toast.success('Бонус обновлён');
        } else {
          const data = res.error.data
          const keys = Object.keys(data)
          toast.error(data[keys[0]][0]);
        }
        setАddButtonDisabled(false)
      });
    }
  };

  return (
    <div className="performance">
      {loading ?
        <div className="loading-block">
          <BeatLoader color="#0A65FF" loading={loading} size={20}/>
        </div> : null
      }
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bread-crumbs-mini"><p>Учет бонусов</p></div>
      <div className="top-block d-flex align-items-center justify-content-end">
        <button className="add-button" type="button" onClick={() => addBonusModalShow()}><img src={add} alt="Add"/><div>add bonus</div></button>
      </div>
      <div className="performance-block">
        <Calendar calendarFilter={(calendar) => filter(calendar)}/>
        <div className="scroll-table bonuses-table">
          <div className="scroll-touch scroll-div">
            <table>
              <thead>
              <tr>
                <th>User</th>
                <th>Bonuses</th>
                <th>Notes</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {props.bonuses && props.bonuses.length ?
              props.bonuses.map((item, index) => {
                return (
                <tr key={index}>
                  <td>
                    <div className="user">
                      <div className="avatar">
                        {item.user.avatar ?
                        <img src={item.user.avatar} alt=""/> :
                        <img src={avatar} alt=""/>
                        }
                      </div>
                      <div className="name">{item.user.first_name} {item.user.last_name}</div>
                    </div>
                  </td>
                  <td>
                    <div className="td-bonuses">
                      <p>{item.bonuses}</p>
                      <span>Paid on {item.date}</span>
                    </div>
                  </td>
                  <td className="notes">{item.description}</td>
                  <td>
                    <div className="bonus-action">
                      <button onClick={() => openEditModal(item)} type="button" className="edit-button"><img src={edit}
                                                                                                             alt="Edit"/>
                        <div>edit</div>
                      </button>
                      <button type="button" className="delete-button" onClick={() => setDeleteModalShow(item.id)}><img
                      src={deleteIcon} alt="Delete"/>
                        <div>delete</div>
                      </button>
                    </div>
                  </td>
                </tr>
                )
              }) : <tr>
                <td className='pl-0' align="center" colSpan="4">
                  <div className='m-4 alert alert-primary'>This branch don't have bonuses!</div>
                </td>
              </tr>
              }
              </tbody>
            </table>
          </div>
        </div>
        <div className="table-pagination">
          <div className="page-count">
            <span>{props.pagination  && props.pagination.count_skip ? props.pagination.skip_page + 1 : '0'}-{props.pagination ? props.pagination.count_skip : ''}</span> of {props.pagination ? props.pagination.count : ''}
          </div>
          <div className="row-page">
            <div className="d-flex align-items-center mr-5">
              <p>Rows per page:</p>
              <select onChange={ (event) => {pageCount(event)}} className="select-picker" data-width="fit">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </div>
            <div>
              <img src={arrowLeft} onClick={() => {clickLeft()}} alt="Left" className="mr-5 cursor-pointer"/>
              <img src={arrowRight} onClick={() => {clickRight()}} alt="Right" className="cursor-pointer"/>
            </div>
          </div>
        </div>
      </div>
      <button type="button" onClick={printPage} className="print-page"><img src={print} alt="Print"/>Print this page</button>
      <Modal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        centered
        dialogClassName="user-delete-modal"
      >
        <Modal.Body>
          <div className="title">Вы действитльно хотите удалить бонус?</div>
          <div className="text">Бонус будет удален</div>
          <button onClick={() => deleteBonus()} type="button">удалить</button>
          <div className="close" onClick={() => setDeleteModalShow(false)}>
            <img src={close} alt="Close"/>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalAddBonus}
        onHide={hideAddModal}
        centered
        dialogClassName="add-user-modal"
      >
        <Modal.Body>
          <form className="add-user" onSubmit={(e) => bonusCreateUpdate(e)}>
            <div className="personal-data">
              <div className="title">Бонус</div>
              <div className="form-group">
                <div className="form-block position-relative">
                  <label htmlFor="bonus">Бонус</label>
                  <input onInvalid={(e) => e.target.setCustomValidity('Поле бонус обязательно для заполнения!')} onInput={(e) => e.target.setCustomValidity('')} name="bonus" className="mt-2" value={form.bonuses} onChange={(e) => setForm({...form, bonuses:e.target.value})} required  type="number" id="bonus"/>
                </div>
                {!editUserBoolean ?
                  <div className="form-block position-relative">
                    <label htmlFor="user_id">Пользователь</label>
                    <select className="select-role mt-2" value={form.user_id} name="user_id" onChange={e => setForm({...form, user_id: e.target.value})} id="user_id" data-width="fit">
                      {props.allUsersList  && props.allUsersList.length ?
                        props.allUsersList.map((item, index) => {
                          return(
                            <option key={index} value={item.id}>{item.name}</option>
                          )
                        })
                        : null
                      }
                    </select>
                  </div> : null
                }
                <div className="form-block position-relative w-100">
                  <label htmlFor="description">Описание</label>
                  <textarea name="description" className="mt-2 w-100" value={form.description} onChange={(e) => setForm({...form, description:e.target.value})} id="description"/>
                </div>
              </div>
            </div>
            <button className={addButtonDisabled ? 'button-disabled submit' : 'submit'} disabled={addButtonDisabled}>{editUserBoolean ? 'Редактировать Бонус' : 'Добавить Бонус'}</button>
            <div className="close" onClick={hideAddModal}>
              <img src={close} alt="Close"/>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    bonuses: state.bonusReducer.bonuses,
    pagination: state.bonusReducer.pagination,
    allUsersList: state.userReducer.allUsersList
  };
}

const mapDispatchToProps = branch => ({
  getBonuses: (data) => branch(bonusActions.getBonuses(data)),
  getAllUsersList: (data) => branch(userActions.getAllUsersList(data)),
  bonusCreate: (data) => branch(bonusActions.bonusCreate(data)),
  bonusUpdate: (data) => branch(bonusActions.bonusUpdate(data)),
  deleteBonus: (data) => branch(bonusActions.deleteBonus(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bonuses);
