import {userConstants} from '../constants/actionTypes';
import {userService} from '../services';

export const userActions = {
  getUsers,
  showUser,
  uploadImage,
  deleteImage,
  deleteUser,
  blockUser,
  removeUsers,
  archiveUsers,
  getUserRole,
  createUser,
  updateUser,
  updateUserInfo,
  updateUserPassword,
  getHistory,
  getAllUsersList,
};

function getUsers(params) {
  return dispatch => {
    dispatch(request());
    return userService.getUsers(params)
    .then(response => {
      dispatch(success(response.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response.data));
    })
  };

  function request() { return { type: userConstants.GET_USER_REQUEST } }
  function success(user) { return { type: userConstants.GET_USER_SUCCESS, user } }
  function failure(error) { return { type: userConstants.GET_USER_FAILURE, error } }
}

function getAllUsersList() {
  return dispatch => {
    dispatch(request());
    return userService.getAllUsers()
    .then(response => {
      return dispatch(success(response.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response.data));
    })
  };

  function request() { return { type: userConstants.GET_ALL_USER_REQUEST } }
  function success(allUsers) { return { type: userConstants.GET_ALL_USER_SUCCESS, allUsers } }
  function failure(error) { return { type: userConstants.GET_ALL_USER_FAILURE, error } }
}

function createUser(params, role_id) {
  return dispatch => {
    return userService.addUser(params)
    .then(response => {
      return dispatch(success(response.data, role_id));
    })
    .catch(function (error) {
      return dispatch(failure(error.response));
    })
  };

  function success(user, role_id) { return { type: userConstants.CREATE_USER_SUCCESS, user, role_id } }
  function failure(error) { return { type: userConstants.CREATE_USER_FAILURE, error } }
}

function updateUser(params, array_role_id, role_id) {
  return dispatch => {
    return userService.editUser(params, role_id)
    .then(response => {
      return dispatch(success(response.data, array_role_id, role_id));
    })
    .catch(function (error) {
      return dispatch(failure(error.response));
    })
  };

  function success(user,array_role_id, role_id) { return { type: userConstants.UPDATE_USER_SUCCESS, user, array_role_id, role_id} }
  function failure(error) { return { type: userConstants.CREATE_USER_FAILURE, error } }
}

function updateUserInfo(params) {
  return dispatch => {
    return userService.editUserInfo(params)
    .then(response => {
      return dispatch(success(response.data));
    })
    .catch(function (error) {
      return dispatch(failure(error.response));
    })
  };

  function success(user) { return { type: userConstants.UPDATE_USER_INFO_SUCCESS, user} }
  function failure(error) { return { type: userConstants.CREATE_USER_INFO_FAILURE, error } }
}

function updateUserPassword(params) {
  return dispatch => {
    return userService.editUserPassword(params)
    .then(response => {
      return dispatch(success(response.data));
    })
    .catch(function (error) {
      return dispatch(failure(error.response));
    })
  };

  function success(password) { return { type: userConstants.UPDATE_USER_PASSWORD_SUCCESS, password} }
  function failure(error) { return { type: userConstants.CREATE_USER_PASSWORD_FAILURE, error } }
}

function showUser(params) {
  return dispatch => {
    dispatch(request());
    return userService.showUser(params)
    .then(response => {
      return dispatch(success(response.data.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response));
    })
  };

  function request() { return { type: userConstants.SHOW_USER_REQUEST } }
  function success(user) { return { type: userConstants.SHOW_USER_SUCCESS, user } }
  function failure(error) { return { type: userConstants.GET_USER_FAILURE, error } }
}

function archiveUsers(id) {
  return dispatch => {
    return userService.archiveUsers(id)
        .then(() => {
          dispatch(success(id));
        })
  };
  function success(id) { return { type: userConstants.ARCHIVE_USERS_SUCCESS, id } }
}

function uploadImage(params) {
  return dispatch => {
    return userService.uploadImage(params)
        .then((response) => {
          return  dispatch(success(response.data.data));
        })
        .catch(function (error) {
          return  dispatch(failure(error.response));
        })
  };
  function success(file) { return { type: userConstants.FILE_USER_SUCCESS, file } }
  function failure(error) { return { type: userConstants.FILE_USER_FAILURE, error } }
}

function deleteImage(id) {
  return dispatch => {
    userService.removeImage(id)
    .then(() => {
      dispatch(success(id));
    })
    .catch(function (error) {

    })
  };
  function success(id) { return { type: userConstants.REMOVE_FILE_USER_SUCCESS, id } }

}

function deleteUser(id, deleteUserThen) {
  return dispatch => {
    userService.deleteUser(id)
    .then(() => {
        deleteUserThen()
    })
    .catch(function (error) {

    })
  };
}

function removeUsers(id) {
  return dispatch => {
    return userService.deleteUsers(id)
    .then(() => {
      dispatch(success(id));
    })
  };
  function success(id) { return { type: userConstants.REMOVE_USERS_SUCCESS, id } }
}

function blockUser(id) {
  return dispatch => {
    userService.blockUser(id)
        .then(() => {
          // dispatch(success(id));
        })
        .catch(function (error) {

        })
  };
}

function getUserRole() {
  return dispatch => {
    return userService.getUserRole()
        .then(response => {
          dispatch(success(response.data));
          return response.data
        })
  };
  function success(data) { return { type: userConstants.USER_ROLE_SUCCESS, data } }
}

function getHistory(params) {
  return dispatch => {
    userService.userHistory(params)
        .then(response => {
          dispatch(success(response.data));
        })
  };
  function success(history) { return { type: userConstants.GET_USER_HISTORY_SUCCESS, history } }
}
