import React from "react";
import { Route as RouteDom, Switch, Route, Redirect } from "react-router-dom";
import BranchSelection from "../../components/pages/branchSelection";

const BranchPageLayout = () => {

  return (
    <div>
      <Switch>
          <RouteDom
              path={'/branch-selection'}
              key={'/branch-selection'}
              exact={true}
              component={BranchSelection}
          />
        <Route exact path="*">
          <Redirect to="/branch-selection"/>
        </Route>
      </Switch>
    </div>
  );
}

export default BranchPageLayout;
