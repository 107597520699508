import { spendingCashConstants } from '../constants/actionTypes';

const initialState = {
  spendingCashList: [],
  pagination: {}
};

export function spendingCashReducer(state = initialState, action) {
  switch (action.type) {
    case spendingCashConstants.SPENDING_CASH_REQUEST:
      return {...state};
    case spendingCashConstants.SPENDING_CASH_SUCCESS:
      return {
        ...state,
        spendingCashList: action.spendingCashList.data,
        pagination: action.spendingCashList.pagination,
      };
    case spendingCashConstants.SPENDING_CASH_FAILURE:
      return {
        ...state,
        spendingCashList: [],
      };
    case spendingCashConstants.CREATE_SPENDING_CASH_REQUEST:
      return {...state};
    case spendingCashConstants.CREATE_SPENDING_CASH_SUCCESS:
      let spendingCash = [...state.spendingCashList];
      spendingCash.unshift(action.spendingCash.data)
      return {
        ...state,
        spendingCashList: spendingCash,
      };
    case spendingCashConstants.CREATE_SPENDING_CASH_FAILURE:
      return {...state};
    case spendingCashConstants.CHANGE_REIMBURSED_SPENDING_CASH_REQUEST:
      return {...state};
    case spendingCashConstants.CHANGE_REIMBURSED_SPENDING_CASH_SUCCESS:
      let spending = [...state.spendingCashList];
      spending = spending.map(item => {
        if (action.spendingCash.data.id === item.id) {
          item = action.spendingCash.data
        }
        return item
      });
      return {
        ...state,
        spendingCashList: spending,
      };
    case spendingCashConstants.CHANGE_REIMBURSED_SPENDING_CASH_FAILURE:
      return {...state};
    case spendingCashConstants.CHANGE_RECEIPT_SPENDING_CASH_REQUEST:
      return {...state};
    case spendingCashConstants.CHANGE_RECEIPT_SPENDING_CASH_SUCCESS:
      let changeSpending = [...state.spendingCashList];
      changeSpending = changeSpending.map(item => {
        if (action.spendingCash.data.id === item.id) {
          item = action.spendingCash.data
        }
        return item
      });
      return {
        ...state,
        spendingCashList: changeSpending,
      };
    case spendingCashConstants.CHANGE_RECEIPT_SPENDING_CASH_FAILURE:
      return {...state};
    default:
      return state;
  }
}
