import {checkConstants} from '../constants/actionTypes';
import {checkService} from '../services';

export const checkActions = {
  getCheckList,
  checkCreate
};

function getCheckList(data) {
  return dispatch => {
    dispatch(request());
    return checkService.getCheck(data)
    .then(response => {
      return dispatch(success(response.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response));
    })
  };

  function request() { return { type: checkConstants.CHECK_REQUEST } }
  function success(checkList) { return { type: checkConstants.CHECK_SUCCESS, checkList } }
  function failure(error) { return { type: checkConstants.CHECK_FAILURE, error } }
}

function checkCreate(data) {
  return dispatch => {
    dispatch(request());
    return checkService.addCheck(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        return dispatch(failure(error.response));
      })
  };

  function request() { return { type: checkConstants.CREATE_CHECK_REQUEST } }
  function success(check) { return { type: checkConstants.CREATE_CHECK_SUCCESS, check } }
  function failure(error) { return { type: checkConstants.CREATE_CHECK_FAILURE, error } }
}


