import React from "react";
import { Route as RouteDom, Switch, Route, Redirect } from "react-router-dom";
import { routes } from "../../modules/pageLayout/authRoutes";

const AuthPageLayout = () => {

  return (
    <div>
      <Switch>
        {routes.map((r) => {
          return (
            <RouteDom
              path={r.path}
              key={r.path}
              exact={true}
              component={r.component}
            />
            );
        })}
        <Route exact path="*">
          <Redirect to="/login"/>
        </Route>
      </Switch>
    </div>
  );
}

export default AuthPageLayout;
