import React, {useEffect, useState} from "react";
import arrowLeft from "../../../../assets/image/icons/arrow-left.svg";
import arrowRight from "../../../../assets/image/icons/arrow-right.svg";
import print from "../../../../assets/image/icons/print.svg";
import {connect} from "react-redux";
import {roleActions} from "../../../../redux/actions";
import add from "../../../../assets/image/icons/add.svg";
import {Modal} from "react-bootstrap";
import close from "../../../../assets/image/icons/close.svg";
import {toast, ToastContainer} from "react-toastify";
import edit from "../../../../assets/image/icons/edit.svg";
import deleteIcon from "../../../../assets/image/icons/delete.svg";
import BeatLoader from "react-spinners/BeatLoader";
import arrow from "../../../../assets/image/icons/arrow.svg";

const Roles = (props) => {
  const [count, setCount] = useState('5');
  const [page, setPage] = useState('1');
  const [modalAddRole, setModalAddRole] = useState(false);
  const [editRoleBoolean, setЕditRoleBoolean] = useState(false);
  const [addButtonDisabled, setАddButtonDisabled] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    role_id: '',
    type_name: ''
  });

  useEffect(() => {
    getRoles()
  },  [count, page]);

  const pageCount = (e) => {
    setCount(e.target.value)
  };

  const clickRight = () => {
    if(props.pagination) {
      if (page < props.pagination.count_pages){
        setPage(+page + 1)
      }
    }
  };

  const printPage = () =>{
    window.print();
  };

  const clickLeft = () => {
    if (page > 1) {
      setPage(+page - 1)
    }
  };

  const getRoles = (day) => {
    setLoading(true)
    props.getRolesList({
      page: page,
      count: count
    }).then(() => {
      setLoading(false)
    });
  };

  const addRoleModalShow = () => {
    setЕditRoleBoolean(false);
    props.getRoleTypes().then(res => {
      if (res.type === 'GET_ROLE_TYPE_SUCCESS') {
        if (res.roleTypes && res.roleTypes.length) {
          setForm({...form, role_id: res.roleTypes[0].id})
        }
      }
    });
    setModalAddRole(true)
  };

  const openEditModal = (role) => {
    props.getRoleTypes()
    setForm({
      id: role.id,
      role_id: role.role_id,
      type_name: role.type_name,
      rate_per_hour: role.rate_per_hour
    });
    setЕditRoleBoolean(true)
    setModalAddRole(true)
  };

  const hideAddModal = () => {
    setForm({
      role_id: '',
      type_name: '',
      rate_per_hour: ''
    });
    setModalAddRole(false)
  };

  const deleteRole = () => {
    props.deleteRole(deleteModalShow).then(res => {
      if (res.type === 'DELETE_ROLE_SUCCESS') {
        setDeleteModalShow(false)
        toast.success('Должность удален');
      } else {
        const data = res.error.data
        const keys = Object.keys(data)
        toast.error(data[keys[0]][0]);
      }
    })
  };

  const roleCreateUpdate = (e) => {
    e.preventDefault()
    setАddButtonDisabled(true)
    if (!editRoleBoolean) {
      props.roleCreate(form).then(res => {
        if (res.type === 'CREATE_ROLE_SUCCESS') {
          hideAddModal()
          toast.success('Должность добавлен');
        } else {
          const data = res.error.data
          const keys = Object.keys(data)
          toast.error(data[keys[0]][0]);
        }
        setАddButtonDisabled(false)
      });
    } else {
      props.roleUpdate(form).then(res => {
        if (res.type === 'UPDATE_ROLE_SUCCESS') {
          hideAddModal()
          toast.success('Должность обновлён');
        } else {
          const data = res.error.data
          const keys = Object.keys(data)
          toast.error(data[keys[0]][0]);
        }
        setАddButtonDisabled(false)
      });
    }
  };

  return (
    <div className="performance">
      {loading ?
        <div className="loading-block">
          <BeatLoader color="#0A65FF" loading={loading} size={20}/>
        </div> : null
      }
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bread-crumbs-mini"><img src={arrow} alt="arrow" onClick={() => props.history.goBack()}/><p>Должности</p></div>
      <div className="top-block d-flex align-items-center justify-content-end">
        <button className="add-button" type="button" onClick={() => addRoleModalShow()}><img src={add} alt="Add"/><div>add role</div></button>
      </div>
      <div className="performance-block">
        <div className="scroll-table bonuses-table">
          <div className="scroll-touch scroll-div">
            <table>
              <thead>
              <tr className="th-role">
                <th>Role</th>
                <th>Worker Type</th>
                <th>Rate Per Hour</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {props.roles && props.roles.length ?
              props.roles.map((item, index) => {
                return(
                <tr key={index}>
                  <td className="notes">{item.role.role_name}</td>
                  <td className="notes">{item.type_name}</td>
                  <td className="notes">{item.rate_per_hour}</td>
                  <td>
                    <div className="bonus-action">
                      <button onClick={() => openEditModal(item)} type="button" className="edit-button"><img src={edit} alt="Edit"/><div>edit</div></button>
                      <button type="button" className="delete-button" onClick={() => setDeleteModalShow(item.id)}><img src={deleteIcon} alt="Delete"/><div>delete</div></button>
                    </div>
                  </td>
                </tr>
                )
              }) : null
              }
              </tbody>
            </table>
          </div>
        </div>
        <div className="table-pagination">
          <div className="page-count">
            <span>{props.pagination  && props.pagination.count_skip ? props.pagination.skip_page + 1 : '0'}-{props.pagination ? props.pagination.count_skip : ''}</span> of {props.pagination ? props.pagination.count : ''}
          </div>
          <div className="row-page">
            <div className="d-flex align-items-center mr-5">
              <p>Rows per page:</p>
              <select onChange={ (event) => {pageCount(event)}} className="select-picker" data-width="fit">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </div>
            <div>
              <img src={arrowLeft} onClick={() => {clickLeft()}} alt="Left" className="mr-5 cursor-pointer"/>
              <img src={arrowRight} onClick={() => {clickRight()}} alt="Right" className="cursor-pointer"/>
            </div>
          </div>
        </div>
      </div>
      <button type="button" onClick={printPage} className="print-page"><img src={print} alt="Print"/>Print this page</button>
      <Modal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        centered
        dialogClassName="user-delete-modal"
      >
        <Modal.Body>
          <div className="title">Вы действитльно хотите удалить должность?</div>
          <div className="text">Должность будет удален</div>
          <button onClick={() => deleteRole()} type="button">удалить</button>
          <div className="close" onClick={() => setDeleteModalShow(false)}>
            <img src={close} alt="Close"/>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalAddRole}
        onHide={hideAddModal}
        centered
        dialogClassName="add-user-modal"
      >
        <Modal.Body>
          <form className="add-user" onSubmit={(e) => roleCreateUpdate(e)}>
            <div className="personal-data">
              <div className="title">Должность</div>
              <div className="form-group">
                <div className="form-block position-relative">
                  <label htmlFor="type_name">Имя</label>
                  <input onInvalid={(e) => e.target.setCustomValidity('Поле имя обязательно для заполнения!')} onInput={(e) => e.target.setCustomValidity('')} name="type_name" className="mt-2" value={form.type_name} onChange={(e) => setForm({...form, type_name:e.target.value})} required  type="text" id="type_name"/>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="type_name">RATE PER HOUR</label>
                  <input onInvalid={(e) => e.target.setCustomValidity('Поле rate per hour обязательно для заполнения!')} onInput={(e) => e.target.setCustomValidity('')} name="rate_per_hour" className="mt-2" value={form.rate_per_hour} onChange={(e) => setForm({...form, rate_per_hour:e.target.value})} required  type="number" id="rate_per_hour"/>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="role_id">Role</label>
                  <select className="select-role mt-2" value={form.role_id} name="role_id" onChange={e => setForm({...form, role_id: e.target.value})} id="role_id" data-width="fit">
                    {props.roleTypes  && props.roleTypes.length ?
                      props.roleTypes.map((item, index) => {
                        return(
                          <option key={index} value={item.id}>{item.role_name}</option>
                        )
                      })
                      : null
                    }
                  </select>
                </div>
              </div>
            </div>
            <button className={addButtonDisabled ? 'button-disabled submit' : 'submit'} disabled={addButtonDisabled}>{editRoleBoolean ? 'Редактировать Должность' : 'Добавить Должность'}</button>
            <div className="close" onClick={hideAddModal}>
              <img src={close} alt="Close"/>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    roles: state.roleReducer.roles,
    pagination: state.roleReducer.pagination,
    roleTypes: state.roleReducer.roleTypes
  };
}

const mapDispatchToProps = branch => ({
  getRolesList: (data) => branch(roleActions.getRolesList(data)),
  getRoleTypes: (data) => branch(roleActions.getRoleTypes(data)),
  roleCreate: (data) => branch(roleActions.roleCreate(data)),
  roleUpdate: (data) => branch(roleActions.roleUpdate(data)),
  deleteRole: (data) => branch(roleActions.deleteRole(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
