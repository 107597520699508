import {http} from "../../modules/http";

export const authService = {
  login,
  logout,
  resetPassword,
  getUserMe,
};

function login(formData) {
  return http.post('/login', formData).then(res => {
    return res
  })
}

function logout() {
  return http.get('/logout').then(res => {
    return res
  })
}

function getUserMe() {
  return http.get('/me').then(res => {
    return res
  })
}

function resetPassword(data) {
  return http.post('/update-password', data).then(res => {
    return res
  })
}
