import React, { useEffect, useState } from "react";
import editImage from "../../../assets/image/icons/edit.svg";
import add from "../../../assets/image/icons/add.svg";
import branch from "../../../assets/image/branch-selection-test.png";
import { useHistory } from 'react-router-dom'
import { branchActions } from "../../../redux/actions/branchActions";
import { connect } from "react-redux";
import deleteIcon from "../../../assets/image/icons/delete.svg";
import {toast, ToastContainer} from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";

const BranchSelection = (props) => {
  const [form, setForm] = useState({name: '', image: ''});
  const [edit, setEdit] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');

  const history = useHistory()

  useEffect(() => {
    props.getBranch()
  },  []);

  const handleChange = (e) => {
    if (e.target.name === 'name' ) {
      if (e.target.value !== '') {
        setNameError('')
      } else {
        setNameError('This field is required')
      }
    }
    if (e.target.name === 'image') {
      if (e.target.value !== '') {
        setImageError('')
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = (event) => {
          setImage(event.target.result)
        };
        reader.readAsDataURL(file)
      } else {
        setImageError('This field is required')
      }
    }
    setErrorMsg('');
    let formData = form ;
    let newFormInput = {};
    const name =  e.target.name;
    let value
    if (name === 'image') {
      let file = e.target.files
      value = file[0]
    } else {
      value = e.target.value
    }
    newFormInput[name] = value;
    setForm({...formData, ...newFormInput});
  };

  const selectBranch = async (event, id) => {
    setLoading(true)
    event.preventDefault();
    localStorage.setItem('branch', id)
    props.showBranch(id).then(() => {
      setLoading(false)
      history.push('/staff?tab=3')
    })
  };


  const editBranch = (data) => {
    setForm(data)
    setImage(data.image)
    setEdit(true)
  };

  const deleteBranch = async (id) => {
    await props.deleteBranch(id).then(res => {
      if(res.type === 'DELETE_BRANCH_SUCCESS') {
        toast.success('Branch has been deleted successfully!');
      } else {
        toast.error('Server error')
      }
    })
  };

  const addBranch = async () => {
    setLoading(true)
    const formData =  new FormData();
    if (!edit) {
      if (form.name && form.image) {
        formData.append('name', form.name)
        formData.append('image', form.image)
        await props.createBranch(formData).then(res => {
          if(res.type === 'CREATE_BRANCH_SUCCESS') {
            toast.success('Branch has been created successfully!');
            setNameError('')
            setImage('')
            setImageError('')
            setForm({name: '', image: ''})
            setEdit(false)
          } else {
            toast.error('Server error')
          }
          setLoading(false)
        })
      } else {
        if (!form.name) {
          setNameError('This field is required')
        } else {
          setNameError('')
        }
        if (!form.image) {
          setImageError('This field is required')
        } else {
          setImageError('')
        }
        setLoading(false)
      }
    } else {
      if (form.name && form.image) {
        formData.append('name', form.name)
        formData.append('id', form.id)
        if (typeof form.image !== "string") {
          formData.append('image', form.image)
        }
        await props.updateBranch(formData).then(res => {
          if(res.type === 'UPDATE_BRANCH_SUCCESS') {
            toast.success('Branch has been updated successfully!');
            setNameError('')
            setImage('')
            setImageError('')
            setForm({name: '', image: '', id: ''})
            setEdit(false)
          } else {
            toast.error('Server error')
          }
          setLoading(false)
        })
      } else {
        if (!form.name) {
          setNameError('This field is required')
        } else {
          setNameError('')
        }
        if (!form.image) {
          setImageError('This field is required')
        } else {
          setImageError('')
        }
        setLoading(false)
      }
    }
  };

  return (
    <div className="branch-selection">
      {loading ?
        <div className="loading-block">
          <BeatLoader color="#0A65FF" loading={loading} size={20}/>
        </div> : null
      }
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="branch-selection-block">
        {props.branch && props.branch.length ?
        props.branch.map((item, index) => {
          return (
          <div key={index} className="branch-selection-block-card cursor-pointer" onClick={(e) => selectBranch(e, item.id)}>
            <div className="card-background">
              <div className="background-opacity"></div>
              <img src={item.image} alt=""/>
              <p>{item.name}</p>
            </div>
            {form.id !== item.id ?
              <button type="button" className="delete" onClick={(e) => {e.stopPropagation(); deleteBranch(item.id)}}><img src={deleteIcon} alt="Delete"/><p>delete</p></button> : null
            }
            <button className="edit" onClick={(e) => {e.stopPropagation(); editBranch(item)}}>
              <img src={editImage} alt="Edit"/>
              <p>Edit</p>
            </button>
          </div>
          )
        }) : ''
        }
        <form className="branch-selection-block-add">
          <div className="form-block position-relative">
            <label htmlFor="branch_name">please enter branch name</label>
            <input type="text" value={form.name} onChange={(e) => handleChange(e)} name="name" id="name"/>
            {nameError ?
                <span className="text-danger position-absolute top-10">{nameError ? nameError : ''}</span> :
                <span className="text-danger position-absolute top-10">{errorMsg ? errorMsg : ''}</span>
            }
          </div>
          <div className="branch-selection-block-add-button-group position-relative">
            <div className="branch-image">
              {image ?
                <img src={image}/> : null
              }
            </div>
            <label htmlFor="file" className="choose-picture">
              <input type="file"  name='image' value={''} onChange={(e) => handleChange(e)} accept="image/*" id="file" className="d-none"/>
              choose picture
            </label>
            {
              <span className="text-danger position-absolute top-10">{imageError ? imageError : ''}</span>
            }
            <button type="button" onClick={addBranch} className="add-new-branch">
              {edit ?
                <img src={editImage} alt="Edit"/> :
                <img src={add} alt="Add"/>
              }
              {edit ? 'edit branch' : 'add new branch'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { branch } = state.branchReducer;
  return { branch };
}

const mapDispatchToProps = branch => ({
  getBranch: () => branch(branchActions.getBranch()),
  createBranch: (data) => branch(branchActions.createBranch(data)),
  deleteBranch: (id) => branch(branchActions.deleteBranch(id)),
  updateBranch: (data) => branch(branchActions.updateBranch(data)),
  showBranch: (data) => branch(branchActions.showBranch(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchSelection);
