import axios from 'axios'
import {API_URL}  from "../config/Environment";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const http = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
  }
})

http.interceptors.request.use((config) => {
  config.headers = {
    Accept: 'application/json',
    ...config.headers
  }
  const access_token = cookies.get('access_token') ? cookies.get('access_token') : localStorage.getItem('access_token');
  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

http.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    window.location.href = '/'
  }
  return Promise.reject(error);
});

export {
  http
}

