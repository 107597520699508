import { createBrowserHistory } from "history";
import staff from "../../components/pages/staff";
import staffDetail from "../../components/pages/staff/detail";
import staffHistory from "../../components/pages/staff/history";
import reports from "../../components/pages/reports";
import bonusSchedule from "../../components/pages/reports/bonusSchedule";
import all from "../../components/pages/reports/all";
import performance from "../../components/pages/performance";
import paymentsAccounting from "../../components/pages/paymentsAccounting";
import bonuses from "../../components/pages/bonuses";
import paymentCalculation from "../../components/pages/paymentCalculation";
import salesReport from "../../components/pages/salesReport";
import spendingCash from "../../components/pages/spendingCash";
import checkRegistration from "../../components/pages/checkRegistration";
import search from "../../components/pages/search";
import roles from "../../components/pages/settings/roles";
import accounts from "../../components/pages/settings/accounts";
import settings from "../../components/pages/settings";
import profile from "../../components/pages/profile";

export const history = createBrowserHistory({
  hashType: "slash",
  basename: ""
});

export const routes = [
  {
    path: "/staff",
    component: staff,
    name: "staff",
  },
  {
    path: "/profile",
    component: profile,
    name: "profile",
  },
  {
    path: "/settings/roles",
    component: roles,
    name: "roles",
  },
  {
    path: "/settings/accounts",
    component: accounts,
    name: "accounts",
  },
  {
    path: "/settings",
    component: settings,
    name: "settings",
  },
  {
    path: "/staff/:id",
    component: staffDetail,
    name: "staffDetail",
  },
  {
    path: "/staff/history/:id",
    component: staffHistory,
    name: "history",
  },
  {
    path: "/reports",
    component: reports,
    name: "reports",
  },
  {
    path: "/reports/bonus-schedule",
    component: bonusSchedule,
    name: "bonusSchedule",
  },
  {
    path: "/reports/all",
    component: all,
    name: "all",
  },
  {
    path: "/performance",
    component: performance,
    name: "performance",
  },
  {
    path: "/payments-accounting",
    component: paymentsAccounting,
    name: "paymentsAccounting",
  },
  {
    path: "/bonuses",
    component: bonuses,
    name: "bonuses",
  },
  {
    path: "/payment-calculation",
    component: paymentCalculation,
    name: "paymentCalculation",
  },
  {
    path: "/sales-report",
    component: salesReport,
    name: "salesReport",
  },
  {
    path: "/spending-cash",
    component: spendingCash,
    name: "spendingCash",
  },
  {
    path: "/check-registration",
    component: checkRegistration,
    name: "checkRegistration",
  },
  {
    path: "/search",
    component: search,
    name: "search",
  },
];
