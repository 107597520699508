import React, {useEffect, useState} from "react";
import arrowLeft from "../../../assets/image/icons/arrow-left.svg";
import arrowRight from "../../../assets/image/icons/arrow-right.svg";
import avatar from "../../../assets/image/default-avatar.png";
import print from "../../../assets/image/icons/print.svg";
import Calendar from "../../components/calendar";
import {connect} from "react-redux";
import {paymentsAccountingActions} from "../../../redux/actions/paymentsAccountingActions";
import moment from "moment";
import {toast, ToastContainer} from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";
import add from "../../../assets/image/icons/add.svg";
import {Modal} from "react-bootstrap";
import close from "../../../assets/image/icons/close.svg";
import MultiSelect from "react-multi-select-component";

const PaymentsAccounting = (props) => {
  const [newDay, setNewDay] = useState();
  const [userJob, setUserJob] = useState([]);
  const [form, setForm] = useState();
  const [count, setCount] = useState('5');
  const [page, setPage] = useState('1');
  const [cashSales, setCashSales] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalAddUserJobShow, setModalAddUserJobShow] = useState(false);
  const [optionsSelect, setOptionsSelect] = useState([]);
  const [workerTypeSelect, setWorkerTypeSelect] = useState([]);
  const [workerType, setWorkerType] = useState('');
  const [workerTypeItem, setWorkerTypeItem] = useState({});
  const [tippingPercentage, setTippingPercentage] = useState(null);
  const [roles, setRoles] = useState([]);
  const [userId, setUserId] = useState('');
  const [day, setDay] = useState('');
  const [workerTypesError, setWorkerTypesError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [timeoutData, setTimeoutData] = useState(null);
  const [timeoutDataTipOut, setTimeoutDataTipOut] = useState(null);
  const [timeoutDataNetPay, setTimeoutDataNetPay] = useState(null);
  const [timeoutDataAverage, setTimeoutDataAverage] = useState(null);
  const [valueTimeData, setValueTimeData] = useState(null);
  const [addedData, setAddedData] = useState({value: '', date: '', typeId: '', userId: '', job_id: '', blur: ''});



  useEffect(() => {
    setCashSales(props.cashSales);
  } , [props.cashSales])

  useEffect(() => {
    if (props.roles && props.roles.length) {
      let userRolesList = [...props.roles]
      let editRoles = []
      userRolesList.map((item) => {
        editRoles.push({label: item.role_name, value: item.id, disabled: false})
      })
      setOptionsSelect(editRoles)
    }
  } , [props.roles])

  useEffect(() => {
    rolesSelect()
  } , [roles]);

  const rolesSelect = () => {
    let rolesArr = [...roles];
    if (rolesArr && !rolesArr.length) {
      setWorkerTypeItem({rate_per_hour : null, label:  null})
    } else {
      setRoleError(false)
    }
    let workerArr = []
    let disabledWorkerArr = []
    rolesArr.map((item, index) => {
      props.roles[index].worker_types.map(type => {
        if(userJob.includes(type.type_name)) {
          disabledWorkerArr.push({label: type.type_name, id: type.id, rate_per_hour: type.rate_per_hour, disabled: true})
        } else {
          workerArr.push({label: type.type_name, id: type.id, rate_per_hour: type.rate_per_hour, disabled: false})
        }
      });
    });
    if (workerArr[0]) {
      setWorkerType(workerArr[0].id)
      setWorkerTypeItem({rate_per_hour : workerArr[0].rate_per_hour, label:  workerArr[0].label})
    } else {
      setWorkerType('')
    }
    const workerArrAllList = workerArr.concat(disabledWorkerArr)
    if (workerArrAllList && workerArrAllList.length) {
      setWorkerTypesError(false)
    }

    setWorkerTypeSelect(workerArrAllList)
  };

  useEffect(() => {
    getPayments()
  },  [count, page]);

  const pageCount = (e) => {
    setCount(e.target.value)
  };

  const printPage = () =>{
    window.print();
  };

  const addUserJob = () => {
    if (roles && !roles.length) {
      setRoleError(true)
    }
    if (workerType) {
      props.addUserWorkerType({"user_id": userId, "date": day , "worker_type_id" : workerType, 'tipping_percentage': tippingPercentage}).then(() => {
        getPayments()
      })
      setRoleError(false)
      setTippingPercentage(null)
      setWorkerTypesError(false)
      setModalAddUserJobShow(false)
    } else {
      setWorkerTypesError(true)
    }
  };

  const clickRight = () => {
    if(props.pagination) {
      if (page < props.pagination.count_pages){
        setPage(+page + 1)
      }
    }
  };

  const clickLeft = () => {
    if (page > 1) {
      setPage(+page - 1)
    }
  };
  const  getPayments = async (day = undefined) => {
    let data = await getDayData(day)
    if (data) {
      data.page = page
      data.count = count
      setLoading(true)
      props.getPaymentsAccounting(data).finally(() => {
        setLoading(false);
      })
    }
  }

  const getDayData = async (day) => {
    if (day || newDay) {
      if (!day) {
        day = newDay
      }
      if (day && day.previousDay) {
        return {
          previousDay: day.previousDay,
        }
      } else if (day.start_date && day.end_date) {
        return {
          start_date: day.start_date,
          end_date: day.end_date,
        }
      } else if (day.start_date && !day.end_date && !day.previousDay) {
        return {
          start_date: day.start_date,
        }
      } else if (day.days) {
        return {
          days: day.days,
        }
      } else if (day.start_period && day.end_period) {
        return {
          end_period: day.end_period,
          start_period: day.start_period,
        }
      } else if (day.months) {
        return {
          months: day.months,
        }
      }
    } else {
      return  {
        previousDay: '6',
      }
    }
  }

  const filter = (calendar) => {
    if (calendar.day) {
      setNewDay(calendar.day)
      getPayments(calendar.day)
    }
  };

  const changeNotes = (e, userId, date, typeName, jobIndex) => {
    let value = e.target.value
    let data = {...props.paymentsAccounting}
    data[userId].performances[date][typeName].price[jobIndex].rating = value
    setForm(data)
  }

  const changePrice = (e, userId, date, typeName, jobIndex, day, user_id, jobId, typeId) => {
    let value = e.target.value
    let valueTime = 200
    let valTimeId
    if (valueTimeData) {
      clearTimeout(valueTimeData);
    }
    valTimeId = setTimeout(() => {
      const addData = {
        value: value,
        date: date,
        userId: userId,
        job_id: jobId,
        typeId: typeId,
        blur: false
      }
      localStorage.setItem('data', JSON.stringify(addData))
      setAddedData(addData)
    }, valueTime)
    setValueTimeData(valTimeId)


    let data = {...props.paymentsAccounting}
    if (!value) {
      let type_id = data[userId].performances[date][typeName].type_id
      data[userId].performances[date][typeName].price[jobIndex].rating = '0'
      createPrice(null, day, type_id, user_id, jobId)
    }
    let totalCache = 0;
    if (typeName === 'time_in' || typeName === 'time_out') {
      data[userId].performances[date][typeName].price[jobIndex].rating = value
    } else {
      data[userId].performances[date][typeName].price[jobIndex].rating = value
    }


    let timeIn = data[userId].performances[date]['time_in'].price[jobIndex].rating
    let timeOut = data[userId].performances[date]['time_out'].price[jobIndex].rating
    let minutesDiff = 0

    if (timeIn && timeOut) {
      let startTime = moment(timeIn, 'hh:mm:ss');
      let endTime = moment(timeOut, 'hh:mm:ss');
      let hoursDiff = endTime.diff(startTime, 'hours' );
      minutesDiff = endTime.diff(startTime, 'minutes' );
      let diif = hoursDiff + ':' + minutesDiff % 60
      if (hoursDiff < 9 && minutesDiff % 60 < 9) {
        diif = '0' + hoursDiff + ':' + '0' + minutesDiff % 60
      } else if (hoursDiff < 9) {
        diif = '0' + hoursDiff + ':' + minutesDiff % 60
      } else if (minutesDiff % 60 < 9){
        diif = hoursDiff + ':' + '0' + minutesDiff % 60
      }  else {
        diif = hoursDiff + ':' + minutesDiff % 60
      }
      let changeDiif = diif.split('');
      if (changeDiif.includes('-')) {
        diif = '00:00'
      }
      data[userId].performances[date]['hours'].price[jobIndex].rating = diif
      let rate = data[userId].performances[date]['rate_per_hour'].price[jobIndex].rating
      let subtotal = minutesDiff * (rate / 60)
      subtotal = subtotal.toFixed(2)
      data[userId].performances[date]['subtotal'].price[jobIndex].rating = subtotal < 0 ? 0 : subtotal
      if (typeName === 'time_in' || typeName === 'time_out') {
        createPrice(diif, day, 4, user_id, jobId)
        createPrice(subtotal, day, 6, user_id, jobId)
      }
    }

    let cashTips = +data[userId].performances[date]['cash_tips'].price[jobIndex].rating ? +data[userId].performances[date]['cash_tips'].price[jobIndex].rating : 0;
    let cctips = +data[userId].performances[date]['cctips'].price[jobIndex].rating ? +data[userId].performances[date]['cctips'].price[jobIndex].rating : 0;
    let gratuity = +data[userId].performances[date]['gratuity'].price[jobIndex].rating ? +data[userId].performances[date]['gratuity'].price[jobIndex].rating : 0;
    let subtotal  = +data[userId].performances[date]['subtotal'].price[jobIndex].rating
    let tippingPercentage  = +data[userId].performances[date]['tipping_percentage'].price[jobIndex].rating
    let tipsAfterTipOut = 0
    let tipOut = (cashTips + cctips + gratuity)
    let tipOutPersantage = tipOut * tippingPercentage
    data[userId].performances[date]['tip_out_amount'].price[jobIndex].rating = tipOutPersantage ? tipOutPersantage.toFixed(2) : 0;
    let tipsAfter = tipOut - tipOutPersantage
    tipsAfterTipOut = data[userId].performances[date]['tips_after_tip_out'].price[jobIndex].rating = tipsAfter ? tipsAfter.toFixed(2) : 0;
    if (typeName === 'cash_tips' || typeName === 'cctips' || typeName === 'gratuity' || typeName === 'time_in' || typeName === 'time_out') {
      let time = 200;
      let cctips = null;
      if (timeoutDataTipOut) {
        clearTimeout(timeoutDataTipOut);
      }
      cctips = setTimeout(() => {
        createPrice(tipOutPersantage, day, 12, user_id, jobId)
        createPrice(tipsAfterTipOut, day, 13, user_id, jobId)
      }, time)
      setTimeoutDataTipOut(cctips)

      let netPay = 0;
      if (subtotal >= 0 && +tipsAfterTipOut >= 0) {
        let total = subtotal + +tipsAfterTipOut
        netPay = data[userId].performances[date]['net_pay'].price[jobIndex].rating = total.toFixed(2)
        let time = 200;
        let net = null;
        if (timeoutDataNetPay) {
          clearTimeout(timeoutDataNetPay);
        }
        net = setTimeout(() => {
          createPrice(netPay, day, 14, user_id, jobId)
        }, time)
        setTimeoutDataNetPay(net)

        if (netPay) {
          const types = [
            {
              name: 'salary_percent',
              id: 19
            },
            {
              name: 'retention_with_salary',
              id: 20
            },
            {
              name: 'cash_percent',
              id: 21
            },
            {
              name: 'check_mdm',
              id: 22
            },
            {
              name: 'check',
              id: 23
            }
          ]
          types.map(type => {
            let percent = data[userId].performances[date][type.name].price[jobIndex].percent
            let total = netPay * percent
            data[userId].performances[date][type.name].price[jobIndex].rating = total.toFixed(2)
            // createPrice(total.toFixed(2), day, type.id, user_id, jobId)
            let sum = 0
            Object.entries(data[userId].performances).map(([ kay, paymentsItem], paymentsIndex) => {
              data[userId].performances[kay][type.name].price.map((subtotalItem, hoursIndex) => {
                if (subtotalItem && subtotalItem.rating) {
                  sum += +subtotalItem.rating
                  data[userId].total[type.name] = sum
                }
              })
            })
          })
        }

      }
      if (minutesDiff && netPay) {
        let minutes = minutesDiff / 60
        let average = minutes < 1 ? netPay * minutes : netPay / minutes
        average = average.toFixed(2)
        data[userId].performances[date]['average_hourty'].price[jobIndex].rating = average
        let time = 200;
        let hourty = null;
        if (timeoutDataAverage) {
          clearTimeout(timeoutDataAverage);
        }
        hourty = setTimeout(() => {
          createPrice(average, day, 15, user_id, jobId)
        }, time)
        setTimeoutDataAverage(hourty)
      }
    }
    if (data[userId].performances[date]['account'] && data[userId].performances[date]['account'].price) {
      let text = data[userId].performances[date]['account'].price.method;
      let myArray = text.split(",")
      for (let i = 0; i < myArray.length; i++) {
        let myArr = myArray[i].split(" ")
        for (let j = 0; j < myArr.length; j++) {
          myArr[j] = myArr[j].toLowerCase()
          if (data[userId].performances[date][myArr[j]] && j !== 0 && myArr[j] !== '%' && myArr[j] !== '$') {
            if (data[userId].performances[date][myArr[j]].price[jobIndex].rating) {
              myArr[j] = data[userId].performances[date][myArr[j]].price[jobIndex].rating
            } else {
              myArr[j] = 0
            }
          }
        }
        let string = myArr.join(' ')
        let result = string.split(" = ")
        let percent = result[1].split(" ")
        for (let i = 0; i < percent.length; i++) {
          if (percent[i][percent[i].length - 1] === '%') {
            percent[i] = '( ( ' + percent[i - 2] + ' * ' + percent[i].split('%')[0] +' ) / 100 )'
          } else if (percent[i][percent[i].length - 1] === '$') {
            percent[i] =  percent[i].split('$')[0]
          }
        }
        result[1] = percent.join(' ')
        if (eval(result[1]) > 0) {
          setTimeout(() => {
            createPrice(eval(result[1]), day, data[userId].performances[date][result[0]].type_id, user_id, jobId)
            let sum = 0
            Object.entries(data[userId].performances).map(([ kay, paymentsItem], paymentsIndex) => {
              data[userId].performances[kay][result[0]].price.map((subtotalItem, hoursIndex) => {
                if (subtotalItem && subtotalItem.rating) {
                  sum += +subtotalItem.rating
                  data[userId].total[result[0]] = sum
                }
              })
            })
          }, 500)
          data[userId].performances[date][result[0]].price[jobIndex].rating = eval(result[1]) > 0 ? eval(result[1]).toFixed(2) : null
        }
      }
    }


    let sum = 0;
    let minutes = 0;
    let subtotalSum = 0;
    let persantage = 0;
    let tipsAfterSum = 0;
    let netPay = 0;
    let average = 0;
    Object.entries(data[userId].performances).map(([ kay, paymentsItem], paymentsIndex) => {
      if (typeName === 'time_in' || typeName === 'time_out') {
        data[userId].performances[kay]['hours'].price.map((hoursItem, hoursIndex) => {
          if (hoursItem && hoursItem.rating) {
            let hours = +hoursItem.rating.split(':')[0]
            let minute = +hoursItem.rating.split(':')[1]
            let hoursMinute = hours * 60;
            let minuteHours = hoursMinute + minute
            minutes += minuteHours
            data[userId].total['hours'] = Math.floor(minutes / 60) + ':' + minutes % 60
          }
        })
        sum = typeName
        data[userId].performances[kay]['subtotal'].price.map((subtotalItem, hoursIndex) => {
          if (subtotalItem && subtotalItem.rating) {
            subtotalSum += +subtotalItem.rating
            data[userId].total['subtotal'] = subtotalSum
          }
        })
      } else if (typeName === 'notes') {
        sum = 'notes'
      } else {

        data[userId].performances[kay]['tip_out_amount'].price.map((subtotalItem, hoursIndex) => {
          if (subtotalItem && subtotalItem.rating) {
            persantage += +subtotalItem.rating
            data[userId].total['tip_out_amount'] = persantage
          }
        })
        data[userId].performances[kay]['tips_after_tip_out'].price.map((subtotalItem, hoursIndex) => {
          if (subtotalItem && subtotalItem.rating) {
            tipsAfterSum += +subtotalItem.rating
            data[userId].total['tips_after_tip_out'] = tipsAfterSum
          }
        })
        data[userId].performances[kay]['net_pay'].price.map((subtotalItem, hoursIndex) => {
          if (subtotalItem && subtotalItem.rating) {
            netPay += +subtotalItem.rating
            data[userId].total['net_pay'] = netPay
          }
        })
        data[userId].performances[kay]['average_hourty'].price.map((subtotalItem, hoursIndex) => {
          if (subtotalItem && subtotalItem.rating) {
            average += +subtotalItem.rating
            data[userId].total['average_hourty'] = average
          }
        })
        data[userId].performances[kay][typeName].price.map((item, index) => {
            if (item && item.rating) {
              sum += +item.rating
            }
        })
      }
    })

    data[userId].total[typeName] = sum
    for (let i = 0; i < data.length; i++) {
      totalCache += data[i].total[9]
    }
    setCashSales(totalCache)
    setForm(data)
  };



  useEffect(() => {
    let addedData = localStorage.getItem('data')
    addedData = JSON.parse(addedData)
    if (addedData && addedData.value) {
        props.paymentsAccountingCreate({
          payments_accounting_type_id: addedData.typeId,
          user_id: addedData.userId,
          date: addedData.date,
          job_id: addedData.job_id,
          rating: addedData.value,
        }).then(res => {
          localStorage.removeItem('data')
        })
      }
  }, [window.beforeunload])

  const createPrice = (e, day, id, user_id, jobId, blur) => {
    let val = '';
    let tim = null;
    let time = 200;
    if (timeoutData) {
      clearTimeout(timeoutData);
    }
    tim = setTimeout(() => {
      if (typeof e === 'string' || typeof e === 'number' || e === null) {
        val = e
      } else {
        val = e.target.value
      }

      if (val !== '') {
        props.paymentsAccountingCreate({
          payments_accounting_type_id: id,
          user_id: user_id,
          date: day,
          job_id: jobId,
          rating: val,
        }).then(res => {
          if (blur) {
            if (res.type === 'CREATE_PAYMENTS_ACCOUNTING_FAILURE') {
              if (res.error.status === 422) {
                const data = res.error.data;
                const keys = Object.keys(data);
                toast.error(data[keys[0]][0])
              } else {
                toast.error(res.error.data.message)
              }
            } else {
              toast.success('Значение добавлено');
            }
          }
          const addData = {
            value: '',
            date: '',
            userId: '',
            job_id: '',
            id: '',
            blur: ''
          };
          setAddedData(addData)
        })
      }
    }, time);
    setTimeoutData(tim)
  };


  const openJobModal = (user_id, day, price) => {
    let jobs = []
    price.map(item => {
      jobs.push(item.rating)
    })
    setUserJob(jobs)
    setUserId(user_id)
    setDay(day)
    props.getRole()
    setModalAddUserJobShow(true)
    setWorkerTypeItem({})
    setRoles([])
    setWorkerType('')
    setWorkerTypeSelect('')
  };

  const changeWorkerType = (id) => {
    setWorkerType(id)
    workerTypeSelect.map(item => {
      if (item.id === +id) {
        setWorkerTypeItem({"rate_per_hour": item.rate_per_hour, "label" : item.label})
      }
      return item
    })
  };


  return (
    <div className="performance">
      {loading ?
        <div className="loading-block">
          <BeatLoader color="#0A65FF" loading={loading} size={20}/>
        </div> : null
      }
      <ToastContainer
      position="top-right"
      autoClose={1500}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
      <div className="bread-crumbs-mini"><p>Учет выплат</p></div>
      <div className="performance-block">
        <Calendar calendarFilter={(calendar) => filter(calendar)}/>
        <div className="scroll-table  payments-table">
          <div className="scroll-touch scroll-div">
            {props.paymentsAccounting && !Array.isArray(props.paymentsAccounting) ? Object.entries(props.paymentsAccounting).map(([key,item],index)=>{
            return (
                <table key={index}>
                  <thead>
                  <tr>
                    <th>
                      <div className="user">
                        <div className="avatar">
                          {item.user.avatar?
                            <img src={item.user.avatar} alt=""/> :
                            <img src={avatar} alt=""/>
                          }
                        </div>
                        <div className="name">
                          {item.user.name}
                        </div>
                      </div>
                    </th>
                    <th className="performance-th">job</th>
                    <th className="performance-th">time in</th>
                    <th className="performance-th">time out</th>
                    <th className="performance-th">hours</th>
                    <th className="performance-th">rate per hour</th>
                    <th className="performance-th">subtotal</th>
                    <th className="performance-th">bonus</th>
                    <th className="performance-th">cash tips</th>
                    <th className="performance-th">cctips</th>
                    <th className="performance-th">gratuity</th>
                    <th className="performance-th">del feel/tips</th>
                    <th className="performance-th">tip out amount</th>
                    <th className="performance-th">tips after tip out</th>
                    <th className="performance-th">net pay</th>
                    <th className="performance-th">average hour</th>
                    <th className="performance-th">account</th>
                    <th className="performance-th">check gross</th>
                    <th className="performance-th">check net</th>
                    <th className="performance-th">W</th>
                    <th className="performance-th">1099</th>
                    <th className="performance-th">cash</th>
                    <th className="performance-th">CHECK MDM</th>
                    <th className="performance-th">CHECK</th>
                    <th className="performance-th">notes</th>
                  </tr>
                  </thead>
                  <tbody>
                  {item.performances ? Object.entries(item.performances).map(([perKay,performancesItem], perIndex) => {
                    return (
                    <tr key={perIndex}>
                      <td>{moment(perKay).locale('EN').format('dddd')}<span>({perKay})</span></td>
                      {performancesItem ? Object.entries(performancesItem).map(([typeKay,it], ind) => {
                        if (typeKay === 'tipping_percentage') {

                        } else {
                        return (
                        typeKay === 'hours' || typeKay === 'rate_per_hour' || typeKay === 'subtotal'|| typeKay === 'tip_out_amount'|| typeKay === 'tips_after_tip_out'|| typeKay === 'net_pay'|| typeKay === 'average_hourty'?
                        <td className="border-bottom-table" key={ind}>
                          {it.price ? it.price.map((job, jobInd ) => {
                            return (
                            <input  readOnly={true} key={jobInd} value={job.rating} type="text"/>
                            )
                          }) : ''
                          }
                        </td>
                        :
                        typeKay === 'job' ?
                        <td className="border-bottom-table table-job width-td time-table-td" key={ind}>
                          {it.price && typeof it.price !== "string" ? it.price.map((job, jobInd ) => {
                            return (
                            <input className="table-input" readOnly={true} key={jobInd} value={job.rating} type="text"/>
                            )
                          }) :
                          <input readOnly={true} value={it.price} type="text"/>
                          }
                          <div className="table-input">
                            <button className="add-job" type="button" onClick={() => openJobModal(item.user.id, it.day, it.price)}><img src={add} alt="Add"/></button>
                          </div>
                        </td>
                        :
                        typeKay === 'account' ?
                        <td key={ind}>
                          {it.price ?
                          <input readOnly={true} value={it.price.name} type="text"/> : ''
                          }
                        </td>
                        :
                        typeKay === 'notes' ?
                        <td className="border-bottom-table" key={ind}>
                            {it.price ?
                            it.price.map((num, numIndex) => {
                              return (
                              <input key={numIndex} value={num.rating}
                                     onInput={(e) =>  {changeNotes(e, key, perKay, typeKay, numIndex)}}
                                     onBlur={(e) =>  createPrice(e, it.day, it.type_id, item.user.id, num.job_id, true)}
                                     type="text"/>
                              )
                            })
                            : null}
                        </td> :
                        typeKay === 'time_in' || typeKay === 'time_out' ?
                        <td className="border-bottom-table time-table-td" key={ind}>
                          <div className="block">
                            {it.price ? it.price.map((day, dayIndex) => {
                              return (
                                <input  key={dayIndex} value={day.rating}
                                        onChange={(e) => {changePrice(e, key, perKay, typeKay, dayIndex, it.day, item.user.id, day.job_id)}}
                                        onInput={(e) => createPrice(e, it.day, it.type_id, item.user.id, day.job_id, true)} type="time"
                                />
                              )
                            }) : null
                            }
                          </div>
                        </td> :
                        <td className="border-bottom-table" key={ind}>
                          {it.price ?
                          it.price.map((num, numIndex) => {
                            return (
                                <input key={numIndex} value={num.rating}
                                       onChange={(e) => {{e.target.value = e.target.value.replace(/[^0-9.]/g, '')}; e.target.value === '00' ? e.target.value = '' : changePrice(e, key, perKay, typeKay, numIndex, it.day, item.user.id, num.job_id, it.type_id); }}
                                       onBlur={(e) =>  {{e.target.value = e.target.value.replace(/[^0-9.]/g, '')}; createPrice(e, it.day, it.type_id, item.user.id, num.job_id, true);}}
                                       type="text"/>
                            )
                          })
                         : null}
                        </td>
                        )
                        }
                      }) : ''
                      }
                    </tr>
                    )
                  }): ''
                  }
                  <tr>
                    <td className="total">total</td>
                    {item.total ? Object.entries(item.total).map(([totalKay,totalValue], totalIndex) => {
                      return (
                        <td key={totalIndex}  className="total">{totalValue && typeof totalValue === "number" ? totalValue.toFixed(2) : totalValue}</td>
                      )
                    })
                      : ''
                    }
                  </tr>
                  </tbody>
                </table>
            )
             }) : <div className='m-4 alert alert-primary'>This branch don't have workers!</div>}
          </div>
          {props.paymentsAccountingMessage ?
            <div className='m-4 alert alert-primary'>{props.paymentsAccountingMessage}</div> : null
          }
        </div>
        {form && form.length ?
            <div className="general-total">
              <div className="item">
                <div className="key">total</div>
                <div className="value">$0</div>
              </div>
              <div className="item">
                <div className="key">cash sales</div>
                <div className="value">${cashSales}</div>
              </div>
              <div className="item">
                <div className="key">withdraw from bank</div>
                <div className="value">$0</div>
              </div>
            </div> : null
        }
        <div className="table-pagination">
          <div className="page-count">
            <span>{props.pagination  && props.pagination.count_skip ? props.pagination.skip_page + 1 : '0'}-{props.pagination ? props.pagination.count_skip : ''}</span> of {props.pagination ? props.pagination.count : ''}
          </div>
          <div className="row-page">
            <div className="d-flex align-items-center mr-5">
              <p>Rows per page:</p>
              <select className="select-picker cursor-pointer" onChange={ (event) => {pageCount(event)}} data-width="fit">
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='15'>15</option>
              </select>
            </div>
            <div>
              <img src={arrowLeft} onClick={() => {clickLeft()}} alt="Left" className="cursor-pointer mr-5"/>
              <img src={arrowRight} onClick={() => {clickRight()}} className="cursor-pointer" alt="Right"/>
            </div>
          </div>
        </div>
      </div>
      <button type="button" onClick={printPage} className="print-page"><img src={print} alt="Print"/>Print this page</button>
      <Modal
        show={modalAddUserJobShow}
        onHide={() => setModalAddUserJobShow(false)}
        centered
        size="lg"
        dialogClassName="user-job-modal"
      >
        <Modal.Body>
          <div className="title">{'добавить новую должность'}</div>
          <div className="w-100 d-flex justify-content-between flex-wrap">
            <div className="form-block-select">
              <label className="label-title" htmlFor="role">Role</label>
              <MultiSelect
                disableSearch
                hasSelectAll={false}
                options={optionsSelect}
                value={roles}
                onChange={setRoles}
                labelledBy="Select"
                id="role"
              />
              {roleError ?
              <p className="text-danger">The role field is required</p> : ''
              }
            </div>
              <div className="form-block-select">
                    <label className="label-title" htmlFor="work_type">Worker Types</label>
                    <select className="select" required value={workerType} onChange={(e) => changeWorkerType(e.target.value)} name="" id="">
                      {workerTypeSelect ?
                        workerTypeSelect.map((item, index) => {
                          return (
                            <option value={item.id} disabled={item.disabled} key={index}>{item.label}</option>
                          )
                        }) : ''
                      }
                    </select>
                {workerTypesError  && workerTypeItem && !workerTypeItem.rate_per_hour?
                <p className="text-danger">The worker type field is required</p> : ''
                }
              </div>
              {workerTypeItem && workerTypeItem.rate_per_hour ?
                  <div>
                    <div className="form-block">
                      <label htmlFor="rate">rate per hour</label>
                      <input disabled={true} name="rate_per_hour" value={workerTypeItem.rate_per_hour}  type="text" id="rate"/>
                    </div>
                    <div className="form-block">
                      <label htmlFor="rate">Tipping Percentage %</label>
                      <input
                      name="tipping_percentage[]"
                      placeholder='Tipping Percentage %'
                      value={tippingPercentage}
                      onChange={(e) => setTippingPercentage(e.target.value)}
                      onInput={(e) => e.target.setCustomValidity('')}
                      onInvalid={(e) => e.target.setCustomValidity('This field is required')} required type="number"
                      id="tipping_percentage"
                      />
                    </div>
                  </div>
                 : ''
              }
              {workerTypeItem && workerTypeItem.label?
                <div className="form-block">
                    <label htmlFor="rate">Worker types</label>
                  <div>
                      <div className="text-nowrap worker-type">{workerTypeItem.label}</div>
                  </div>
                </div> : ''
              }
          </div>
          <button className="save-button" onClick={() => { addUserJob()}} type="button">Save</button>
          <div className="close" onClick={() => setModalAddUserJobShow(false)}>
            <img src={close} alt="Close"/>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};


const mapStateToProps = state => {
  return {
    paymentsAccounting: state.paymentsAccountingReducer.paymentsAccounting,
    pagination: state.paymentsAccountingReducer.pagination,
    cashSales: state.paymentsAccountingReducer.cashSales,
    roles: state.paymentsAccountingReducer.roles,
    paymentsAccountingMessage: state.paymentsAccountingReducer.paymentsAccountingMessage

  };
};

const mapDispatchToProps = d => ({
  getPaymentsAccounting: (params) => d(paymentsAccountingActions.getPaymentsAccounting(params)),
  getRole: () => d(paymentsAccountingActions.getRole()),
  paymentsAccountingCreate: (params) => d(paymentsAccountingActions.paymentsAccountingCreate(params)),
  addUserWorkerType: (params) => d(paymentsAccountingActions.addUserWorkerType(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsAccounting);

