import { searchConstants } from '../constants/actionTypes';

const initialState = {
  usersList: []
};

export function searchReducer(state = initialState, action) {
  switch (action.type) {
    case searchConstants.SEARCH_REQUEST:
      return {...state};
    case searchConstants.SEARCH_SUCCESS:
      return {
        ...state,
        usersList: action.users.data
      };
    case searchConstants.SEARCH_FAILURE:
      return {
        ...state,
        usersList: [],
      };
    default:
      return state;
  }
}
