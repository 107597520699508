import {http} from "../../modules/http";

export const userService = {
  getUsers,
  showUser,
  uploadImage,
  removeImage,
  deleteUser,
  blockUser,
  deleteUsers,
  archiveUsers,
  getUserRole,
  addUser,
  editUser,
  editUserInfo,
  editUserPassword,
  userHistory,
  getAllUsers,
};

function getUsers(params) {
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params)
  return http.get('/users?' + q.toString()).then(res => {
    return res
  })
}

function getAllUsers() {
  let params = {}
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params)
  return http.get('/all-users?' + q.toString()).then(res => {
    return res
  })
}

function showUser(params) {
  return http.put('/show-user/' + params).then(res => {
    return res
  })
}
//
function uploadImage(data) {
  return http.post('/create-user-file', data).then(res => {
    return res
  })
}

function addUser(data) {
  data.append('branch_id', localStorage.getItem('branch'))
  return http.post('/user', data).then(res => {
    return res
  })
}

function editUser(data) {
  return http.post('/update-user', data).then(res => {
    return res
  })
}

function editUserInfo(data) {
  return http.post('/update-user-info', data).then(res => {
    return res
  })
}

function editUserPassword(data) {
  return http.post('/update-user-password', data).then(res => {
    return res
  })
}
function removeImage(id) {
  return http.delete('/delete-user-file/' + id).then(res => {
    return res
  })
}
function deleteUser(id) {
  return http.delete('/delete-user/' + id).then(res => {
    return res
  })
}
function blockUser(id) {
  return http.put('/block-user/' + id).then(res => {
    return res
  })
}

function deleteUsers(id) {
  return http.post('/delete-users', id).then(res => {
    return res
  })
}

function archiveUsers(id) {
  return http.post('/archive-users', id).then(res => {
    return res
  })
}

function getUserRole() {
  let params = {}
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params);
  return http.get('/roles?' + q.toString() ).then(res => {
    return res
  })
}


function userHistory(params) {
  const q = new URLSearchParams(params)
  return http.get('/history?' + q.toString()).then(res => {
    return res
  })
}
