import React, {useEffect, useState} from "react";
import close from "../../../assets/image/icons/close.svg";
import swal from 'sweetalert';
import { authActions } from "../../../redux/actions";
import { connect } from "react-redux";
import validator from 'validator';

const ForgotPassword = (props) => {

  const [form, setForm] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (e) => {
    setErrorMsg('');
    let formData = form ;
    let newFormInput = {};
    const name =  e.target.name;
    const value =  e.target.value;
    newFormInput[name] = value;
    setForm({...formData, ...newFormInput});
  };


  useEffect(() => {
    if (form !== false && form.email !== "") {
      if (form.email) {
        if (!validator.isEmail(form.email)) {
          setErrorEmail('This email is not a valid.')
        } else {
          setErrorEmail('')
        }
      } else {
        setErrorEmail('This email is required')
      }
    } else {
      setErrorEmail(false)
    }
  },  [form]);

  const reset = async (e) => {
    e.preventDefault();
    setButtonDisabled(true)
    if (form.email) {
      if (!validator.isEmail(form.email)) {
        setErrorEmail('This email is not a valid.')
      } else {
        setErrorEmail('')
      }
    } else {
      setErrorEmail('This email is required')
    }
    if (errorEmail === '') {
      const formData = form;
      if (formData) {
        await props.resetPassword(formData).then(() => {
          swal("Успешно", "Новый пароль отправлен на ваш Email", "success").then(() => {
            props.history.push('/login')
          })
        }).finally(() => {
          setButtonDisabled(false)
        })
      }
    }
  };

  useEffect(() => {
    if (props.resetPasswordError) {
      if (props.resetPasswordError.status === 422) {
        const data = props.resetPasswordError.data
        const keys = Object.keys(data)
        setErrorMsg(data[keys[0]][0])
      } else {
        setErrorMsg(props.resetPasswordError.data.message)
      }
    }
  },  [props.resetPasswordError]);


  return (
    <div className="login">
      <div className="login-block forgot-password-block">
        <div className="login-title">Restore password</div>
        <form className="login-form" onSubmit={e => reset(e)}>
          <div className="form-block">
            <label htmlFor="mail">please enter your Mail</label>
            <input
                type="email"
                name='email'
                onChange={handleChange}
                id="mail"/>
          </div>
          {errorEmail ?
              <p className="text-danger position-absolute">{errorEmail ? errorEmail : ''}</p> :
              <p className="text-danger position-absolute">{errorMsg ? errorMsg : ''}</p>
          }
          <p className="forgot-password-text mt-4">You will recieve password on your email</p>
          <button type="submit" disabled={buttonDisabled} className={buttonDisabled ? 'button-disabled login-button restore-password' : 'login-button restore-password'}>restore</button>
        </form>
        <div className="close">
          <img src={close} alt="Close"/>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { resetPasswordError } = state.authReducer;
  return { resetPasswordError };
}


const mapDispatchToProps = d => ({
  resetPassword: (data) => d(authActions.resetPassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
