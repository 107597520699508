import {searchConstants} from '../constants/actionTypes';
import {searchService} from '../services';

export const searchActions = {
  searchUsersList
};

function searchUsersList(data) {
  return dispatch => {
    dispatch(request());
    return searchService.searchUsers(data)
    .then(response => {
      return dispatch(success(response.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response));
    })
  };

  function request() { return { type: searchConstants.SEARCH_REQUEST } }
  function success(users) { return { type: searchConstants.SEARCH_SUCCESS, users } }
  function failure(error) { return { type: searchConstants.SEARCH_FAILURE, error } }
}


