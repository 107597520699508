import React, {useEffect} from 'react'
import {connect} from "react-redux";
import search from "../../../assets/image/icons/search.svg";
import menu from "../../../assets/image/icons/menu.svg";
import avatar from "../../../assets/image/default-avatar.png";
import {Link, NavLink, useHistory} from 'react-router-dom'
import {authActions} from "../../../redux/actions";

const Header = (props) => {
  const history = useHistory()

    useEffect(() => {
        props.getUserMe()
    },  []);

  const openMenu = () => {
    let sidebar = document.getElementById('sidebar')
    sidebar.style.display = 'block'
  };

  const keyPress = (e) => {
    if(e.keyCode === 13){
      history.push('/search?value=' + e.target.value)
    }
  };

  const selectBranchPage = () => {
    if (props.admin.role_id === 1) {
      localStorage.removeItem('branch')
      history.push('/branch-selection')
    }
  };

  const selectPage = () => {
    if (props.breadcrumbs.subPage) {
      history.push(props.breadcrumbs.page.path)
    }

  };

    return (
    <div className="main-header" id="header">
      <div className="bread-crumbs cursor-pointer">
        <p>Jibek Jolu&#160;&#160;/&#160;&#160;</p>
        <p onClick={selectBranchPage}>{props.activeBranch ? props.activeBranch.name : ''}&#160;&#160;/&#160;&#160;</p>
        <p onClick={selectPage} className={props.breadcrumbs && props.breadcrumbs.subPage ? '' : "active"}>{props.breadcrumbs && props.breadcrumbs.page.name}</p>
        {props.breadcrumbs && props.breadcrumbs.subPage &&
          <p>&#160;&#160;/&#160;&#160;</p>
        }
        {props.breadcrumbs && props.breadcrumbs.subPage &&
          <p className="active">{props.breadcrumbs.subPage.name}</p>
        }
      </div>
      <div className="menu-icon" onClick={openMenu}>
        <img src={menu} alt="Menu"/>
      </div>
      <div className="d-flex justify-content-between header-left-block">
        <div className="search">
          <input type="search" placeholder="Search Users by Name or Email" onKeyDown={keyPress}/>
          <img src={search} alt="Search"/>
          <NavLink to={'/search'}>
            <img src={search} alt="Search"/>
          </NavLink >
        </div>
        <Link to="/profile" className="graph-type">
          <div className="user">
            <div className="avatar">
              {props.admin && props.admin.avatar ?
              <img src={props.admin.avatar} alt="User"/>
              :
              <img src={avatar} alt="User"/>
              }
            </div>
            <div className="user-info">
              <div className="user-name">{props.admin ? props.admin.last_name : ''}</div>
              <div className="user-role">{props.admin && props.admin.role ? props.admin.role.role_name : ''}</div>
            </div>
          </div>
        </Link>
      </div>
    </div>
)
};

function mapStateToProps(state) {
  const {admin} = state.authReducer;
  const {activeBranch} = state.branchReducer;
  return {admin, activeBranch};
}

const mapDispatchToProps = d => ({
    getUserMe: () => d(authActions.getUserMe()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
