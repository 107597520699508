import {http} from "../../modules/http";

export const spendingCashService = {
  getSpendingCashListData,
  addSpendingCash,
  changeReimbursed,
  changeReceipt,
};

function getSpendingCashListData(params) {
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params)
  return http.get('/spending-cash?' + q.toString()).then(res => {
    return res
  })
}

function addSpendingCash(params) {
  params.branch_id = localStorage.getItem('branch')
  return http.post('/spending-cash', params).then(res => {
    return res
  })
}

function changeReimbursed(params) {
  return http.post('/spending-cash-reimbursed-updater', params).then(res => {
    return res
  })
}

function changeReceipt(params) {
  return http.post('/spending-cash-receipt-updater', params).then(res => {
    return res
  })
}

