import {paymentConstants} from '../constants/actionTypes';
import {paymentService} from '../services';

export const paymentActions = {
  getPayments,
  paymentCreate,
  paymentUpdate,
  deletePayment,
  deletePaymentFile,
};

function getPayments(data) {
  return dispatch => {
    dispatch(request());
    return paymentService.getPayment(data)
    .then(response => {
      dispatch(success(response.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response));
    })
  };

  function request() { return { type: paymentConstants.PAYMENT_REQUEST } }
  function success(payments) { return { type: paymentConstants.PAYMENT_SUCCESS, payments } }
  function failure(error) { return { type: paymentConstants.PAYMENT_FAILURE, error } }
}

function paymentCreate(data) {
  return dispatch => {
    dispatch(request());
    return paymentService.addPayment(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: paymentConstants.CREATE_PAYMENT_REQUEST } }
  function success(payment) { return { type: paymentConstants.CREATE_PAYMENT_SUCCESS, payment } }
  function failure(error) { return { type: paymentConstants.CREATE_PAYMENT_FAILURE, error } }
}

function paymentUpdate(data) {
  return dispatch => {
    dispatch(request());
    return paymentService.updatePayment(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: paymentConstants.UPDATE_PAYMENT_REQUEST } }
  function success(payment) { return { type: paymentConstants.UPDATE_PAYMENT_SUCCESS, payment } }
  function failure(error) { return { type: paymentConstants.UPDATE_PAYMENT_FAILURE, error } }
}

function deletePayment(data) {
  return dispatch => {
    dispatch(request());
    return paymentService.paymentDelete(data)
      .then(response => {
        return dispatch(success(data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: paymentConstants.DELETE_PAYMENT_REQUEST } }
  function success(payment) { return { type: paymentConstants.DELETE_PAYMENT_SUCCESS, payment } }
  function failure(error) { return { type: paymentConstants.DELETE_PAYMENT_FAILURE, error } }
}

function deletePaymentFile(data) {
  return dispatch => {
    dispatch(request());
    return paymentService.deletePaymentFile(data)
      .then(response => {
        return dispatch(success(data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: paymentConstants.DELETE_PAYMENT_FILE_REQUEST } }
  function success(payment) { return { type: paymentConstants.DELETE_PAYMENT_FILE_SUCCESS, payment } }
  function failure(error) { return { type: paymentConstants.DELETE_PAYMENT_FILE_FAILURE, error } }
}


