import React from "react";
import roles from "../../../assets/image/icons/roles.svg";
import accounts from "../../../assets/image/icons/accounts.svg";
import arrowRight from "../../../assets/image/icons/arrow-right.svg";
import {Link} from "react-router-dom";

const Settings = () => {
  return (
    <div className="reports">
      <div className="page-title">Настройки</div>
      <Link to="/settings/roles" className="graph-type">
        <div className="d-flex align-items-center">
          <img src={roles} alt="Graph"/>
          <div className="title">
            Должности
          </div>
        </div>
        <div className="right-arrow"><img src={arrowRight} alt="Arrow"/></div>
      </Link>
      <Link to="/settings/accounts" className="graph-type">
        <div className="d-flex align-items-center">
          <img src={accounts} alt="Graph"/>
          <div className="title">
            Аккаунты
          </div>
        </div>
        <div className="right-arrow"><img src={arrowRight} alt="Arrow"/></div>
      </Link>
    </div>
  );
};

export default Settings;
