import {http} from "../../modules/http";

export const paymentsAccountingService = {
  paymentsAccounting,
  addPaymentsAccounting,
  getRoleList,
  userWorkerType,
};

function paymentsAccounting(params) {
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params)
  return http.get('/payments-accounting?' + q.toString()).then(res => {
    return res
  })
}

function addPaymentsAccounting(params) {
  return http.post('/payments-accounting', params).then(res => {
    return res
  })
}

function getRoleList() {
  return http.get('/get-roles').then(res => {
    return res
  })
}

function userWorkerType(params) {
  return http.post('/temporary-user-worker-type' , params).then(res => {
    return res
  })
}

