import React, {useEffect, useState} from "react";
import arrow from "../../../assets/image/icons/arrow.svg";
import arrowLeft from "../../../assets/image/icons/arrow-left.svg";
import arrowRight from "../../../assets/image/icons/arrow-right.svg";
import print from "../../../assets/image/icons/print.svg";
import arrowTable from "../../../assets/image/icons/arrow-table-filter.svg";
import {userActions} from "../../../redux/actions";
import {connect} from "react-redux";
import { useHistory } from 'react-router-dom'

const History = (props) => {
  const history = useHistory()
  const [count, setCount] = useState('5');
  const [page, setPage] = useState('1');


  useEffect(() => {
    getUserHistory()
  },  [count, page]);



  const pageCount = (e) => {
    setCount(e.target.value)
  };

  const clickRight = () => {
    if(props.pagination) {
      if (page < props.pagination.count_pages){
        setPage(+page + 1)
      }
    }
  };

  const clickLeft = () => {
    if (page > 1) {
      setPage(+page - 1)
    }
  };

  const getUserHistory = () => {
    props.getHistory({
      "id" :props.match.params.id,
      "page": page,
      "count": count
    })
  }

  useEffect(() => {
    getUserHistory()
  },  []);


  return (
    <div className="staff-history staff-detail">
      <div onClick={() => history.goBack()} className="bread-crumbs"><img src={arrow} alt="arrow"/><p>Назад</p></div>
      <div className="bread-crumbs-mini"><img src={arrow} alt="arrow" onClick={() => history.goBack()}/><p>История изменений </p></div>
      <div className="page-title">История изменений</div>
      <div className="history-table">
        <table>
          <thead>
            <tr className="th-filter">
              <th>
                <div>
                  date
                  <img src={arrowTable} alt="Filter"/>
                </div>
              </th>
              <th>
                <div>
                  action
                  <img src={arrowTable} alt="Filter"/>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
          {props.history ?
              props.history.map((item, index) => {
                return (
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>{item.action}</td>
                      {/*<td><span>mark</span> 5 for Mira Herwitz in the <span>punctuality</span></td>*/}
                    </tr>
                )
              }): ''
          }

          </tbody>
        </table>
        <div className="table-pagination">
          <div className="page-count">
            <span>{props.pagination  && props.pagination.count_skip ? props.pagination.skip_page + 1 : '0'}-{props.pagination ? props.pagination.count_skip : ''}</span> of {props.pagination ? props.pagination.count : ''}
          </div>
          <div className="row-page">
            <div className="d-flex align-items-center mr-5">
              <p>Rows per page:</p>
              <select className="select-picker cursor-pointer" onChange={ (event) => {pageCount(event)}} data-width="fit">
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='15'>15</option>
              </select>
            </div>
            <div>
              <img src={arrowLeft} onClick={() => {clickLeft()}} alt="Left" className="cursor-pointer mr-5"/>
              <img src={arrowRight} onClick={() => {clickRight()}} className="cursor-pointer" alt="Right"/>
            </div>
          </div>
        </div>
      </div>
      <button type="button" className="print-page"><img src={print} alt="Print"/>Print this page</button>
    </div>
  );
};


function mapStateToProps(state) {
  const { history, pagination } = state.userReducer;
  return { history, pagination};
}

const mapDispatchToProps = d => ({
  getHistory: (params) => d(userActions.getHistory(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);

