import {spendingCashConstants} from '../constants/actionTypes';
import {spendingCashService} from '../services';

export const spendingCashActions = {
  getSpendingCashList,
  spendingCashCreate,
  changeReimbursedData,
  changeReceiptData
};

function getSpendingCashList(data) {
  return dispatch => {
    dispatch(request());
    return spendingCashService.getSpendingCashListData(data)
    .then(response => {
      return dispatch(success(response.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response));
    })
  };

  function request() { return { type: spendingCashConstants.SPENDING_CASH_REQUEST } }
  function success(spendingCashList) { return { type: spendingCashConstants.SPENDING_CASH_SUCCESS, spendingCashList } }
  function failure(error) { return { type: spendingCashConstants.SPENDING_CASH_FAILURE, error } }
}

function spendingCashCreate(data) {
  return dispatch => {
    dispatch(request());
    return spendingCashService.addSpendingCash(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        return dispatch(failure(error.response));
      })
  };

  function request() { return { type: spendingCashConstants.CREATE_SPENDING_CASH_REQUEST } }
  function success(spendingCash) { return { type: spendingCashConstants.CREATE_SPENDING_CASH_SUCCESS, spendingCash } }
  function failure(error) { return { type: spendingCashConstants.CREATE_SPENDING_CASH_FAILURE, error } }
}

function changeReimbursedData(data) {
  return dispatch => {
    dispatch(request());
    return spendingCashService.changeReimbursed(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        return dispatch(failure(error.response));
      })
  };

  function request() { return { type: spendingCashConstants.CHANGE_REIMBURSED_SPENDING_CASH_REQUEST } }
  function success(spendingCash) { return { type: spendingCashConstants.CHANGE_REIMBURSED_SPENDING_CASH_SUCCESS, spendingCash } }
  function failure(error) { return { type: spendingCashConstants.CHANGE_REIMBURSED_SPENDING_CASH_FAILURE, error } }
}

function changeReceiptData(data) {
  return dispatch => {
    dispatch(request());
    return spendingCashService.changeReceipt(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        return dispatch(failure(error.response));
      })
  };

  function request() { return { type: spendingCashConstants.CHANGE_RECEIPT_SPENDING_CASH_REQUEST } }
  function success(spendingCash) { return { type: spendingCashConstants.CHANGE_RECEIPT_SPENDING_CASH_SUCCESS, spendingCash } }
  function failure(error) { return { type: spendingCashConstants.CHANGE_RECEIPT_SPENDING_CASH_FAILURE, error } }
}


