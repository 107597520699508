import { accountConstants } from '../constants/actionTypes';

const initialState = {
  accounts: [],
  accountsList: [],
  pagination: {},
  accountType: {}
};

export function accountReducer(state = initialState, action) {
  switch (action.type) {
    case accountConstants.GET_ROLES_LIST_REQUEST:
      return {...state};

   case accountConstants.GET_ACCOUNT_TYPE_SUCCESS:
     return {...state,
        accountType: action.accountsType,
      };
    case accountConstants.GET_ACCOUNTS_LIST_SUCCESS:
      return {...state,
        accounts: action.accounts.data,
        pagination: action.accounts.pagination
      };
    case accountConstants.GET_ACCOUNTS_LIST_FAILURE:
      return {...state};
    case accountConstants.GET_ACCOUNTS_REQUEST:
      return {...state};
    case accountConstants.GET_ACCOUNTS_SUCCESS:
      return {...state,
        accountsList: action.accountsList
      };
    case accountConstants.GET_ACCOUNTS_FAILURE:
      return {...state};
    case accountConstants.CREATE_ACCOUNT_REQUEST:
      return {...state};
    case accountConstants.CREATE_ACCOUNT_SUCCESS:
      let accountsList = [...state.accounts];
      accountsList.unshift(action.account.data)
      return {
        ...state,
        accounts: accountsList,
      };
    case accountConstants.CREATE_ACCOUNT_FAILURE:
      return {...state};
    case accountConstants.UPDATE_ACCOUNT_REQUEST:
      return {...state};
    case accountConstants.UPDATE_ACCOUNT_SUCCESS:
      let accountsListData = [...state.accounts];
      accountsListData = accountsListData.map(item => {
        if (item.id === action.account.data.id) {
          item = action.account.data
        }
        return item
      });
      return {
        ...state,
        accounts: accountsListData,
      };
    case accountConstants.UPDATE_ACCOUNT_FAILURE:
      return {...state};
    case accountConstants.DELETE_ACCOUNT_REQUEST:
      return {...state};
    case accountConstants.DELETE_ACCOUNT_SUCCESS:
      let deleteAccountsList = [...state.accounts];
      deleteAccountsList = deleteAccountsList.filter(item => item.id !== action.account);
      return {
        ...state,
        accounts: deleteAccountsList,
      };
    case accountConstants.DELETE_ACCOUNT_FAILURE:
      return {...state};
    default:
      return state;
  }
}
