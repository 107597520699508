import {http} from "../../modules/http";

export const checkService = {
  getCheck,
  addCheck
};

function getCheck(params) {
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params)
  return http.get('/check-registration?' + q.toString()).then(res => {
    return res
  })
}

function addCheck(params) {
  params.branch_id = localStorage.getItem('branch')
  return http.post('/check-registration', params).then(res => {
    return res
  })
}

