import React, {useEffect, useState} from "react";
import { Route as RouteDom, Switch, Route, Redirect } from "react-router-dom";
import { routes } from "../../modules/pageLayout/routes";
import Header from "../components/header";
import Sidebar from "../components/sidebar";
import staff from "../../assets/image/icons/sidebar/staff.svg";
import reports from "../../assets/image/icons/sidebar/reports.svg";
import performance from "../../assets/image/icons/sidebar/performance.svg";
import paymentsAccounting from "../../assets/image/icons/sidebar/payments-accounting.svg";
import bonuses from "../../assets/image/icons/sidebar/bonuses.svg";
import paymentCalculation from "../../assets/image/icons/sidebar/payment-calculation.svg";
import spendingCash from "../../assets/image/icons/sidebar/spending-cash.svg";
import salesReport from "../../assets/image/icons/sidebar/sales-report.svg";
import checkRegistration from "../../assets/image/icons/sidebar/check-registration.svg";
import settings from "../../assets/image/icons/sidebar/settings.svg";

const navigation = [
  {
    name: 'Сотрудники',
    links: {
      name: 'Страница сотрудников',
      path: '/staff?tab=3',
      icon: staff,
      show: true
    },
    subPath: [
      {
        name: 'Сотрудник',
        path: '/staff/:id',
      },
      {
        name: 'История изменений',
        path: '/staff/history/:id',
      }
    ]
  },

  {
    name: 'Отчеты',
    links: {
      name: 'Отчеты',
      path: '/reports',
      icon: reports,
      show: true
    },
    subPath: [
      {
        name: 'График бонусов',
        path: '/reports/bonus-schedule'
      },
      {
        name: 'Все отчеты',
        path: '/reports/all'
      },
    ]
  },

  {
    name: 'Производительность',
    links: {
      name: 'Производительность',
      path: '/performance',
      icon: performance,
      show: true
    }
  },
  {
    name: 'Учет выплат',
    links: {
      name: 'Учет выплат',
      path: '/payments-accounting',
      icon: paymentsAccounting,
      show: true
    }
  },
  {
    name: 'Учет бонусов',
    links: {
      name: 'Учет бонусов',
      path: '/bonuses',
      icon: bonuses,
      show: true
    }
  },
  {
    name: 'Расчет оплаты',
    links: {
      name: 'Расчет оплаты',
      path: '/payment-calculation',
      icon: paymentCalculation,
      show: true
    }
  },
  {
    name: 'Траты кэш',
    links: {
      name: 'Траты кэш',
      path: '/spending-cash',
      icon: spendingCash,
      show: true
    }
  },
  {
    name: 'Недельный отчет по продажам',
    links: {
      name: 'Недельный отчет по продажам',
      path: '/sales-report',
      icon: salesReport,
      show: true
    }
  },
  {
    name: 'Регистрация чеков',
    links: {
      name: 'Регистрация чеков',
      path: '/check-registration',
      icon: checkRegistration,
      show: true
    }
  },
  {
    name: 'Настройки',
    links: {
      name: 'Настройки',
      path: '/settings',
      icon: settings,
      show: true
    },
    subPath: [
      {
        name: 'Должности',
        path: '/settings/roles'
      },
      {
        name: 'Аккаунты',
        path: '/settings/accounts'
      },
    ]
  },
  {
    name: 'Поиск',
    links: {
      name: 'Поиск',
      path: '/search?value=',
      show: false
    }
  },
  {
    name: 'Профиль',
    links: {
      name: 'Профиль',
      path: '/profile',
      show: false
    }
  }
];

const PageLayout = () => {
  const [breadcrumbs, setBreadcrumbs] = useState('');

  useEffect(() => {
    for (let i = 0; i < navigation.length; i++) {
      if (window.location.pathname === navigation[i].links.path.split('?')[0] || window.location.pathname === navigation[i].links.path) {
        setBreadcrumbs({page: navigation[i].links})
      } else if (navigation[i].subPath) {
        for (let j = 0; j < navigation[i].subPath.length; j++) {
          let pathNameArray = navigation[i].subPath[j].path.split('/');
          let windowLocationPathNameArray = window.location.pathname.split('/');
          if (pathNameArray[pathNameArray.length - 1] === ':id') {
            pathNameArray = pathNameArray.filter(item => item !== ':id');
            windowLocationPathNameArray = windowLocationPathNameArray.filter((item, index) => index !== (windowLocationPathNameArray.length - 1));
            if (JSON.stringify(pathNameArray) === JSON.stringify(windowLocationPathNameArray)) {
              setBreadcrumbs({page: navigation[i].links, subPage: navigation[i].subPath[j]})
            }
          } else {
            if (navigation[i].subPath[j].path === window.location.pathname) {
              setBreadcrumbs({page: navigation[i].links, subPage: navigation[i].subPath[j]})
            }
          }
        }
      }
    }
  },  [window.location.pathname, window.location.href]);

  return (
    <div className="page-wrap">
      <Sidebar navigationPages={navigation}/>
      <Header breadcrumbs={breadcrumbs}/>
      <div className="page-container">
        <Switch>
          {routes.map((r, i) => {
            return (
              <RouteDom
                path={r.path}
                key={i}
                exact={true}
                component={r.component}
              />);
          })}
          <Route exact path="/">
            <Redirect to="/staff" />
          </Route>
          <Route exact path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default PageLayout;
