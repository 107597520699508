import React, {useEffect, useState} from "react";
import arrowLeft from "../../../assets/image/icons/arrow-left.svg";
import arrowRight from "../../../assets/image/icons/arrow-right.svg";
import arrowTable from "../../../assets/image/icons/arrow-table-filter.svg";
import print from "../../../assets/image/icons/print.svg";
import add from "../../../assets/image/icons/add.svg";
import Calendar from "../../components/calendar";
import {Modal} from "react-bootstrap";
import close from "../../../assets/image/icons/close.svg";
import {spendingCashActions} from "../../../redux/actions/spendingCashActions";
import {connect} from "react-redux";
import {toast, ToastContainer} from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";

const SpendingCash = (props) => {
  const [count, setCount] = useState('5');
  const [newDay, setNewDay] = useState();
  const [page, setPage] = useState('1');
  const [modalAddReceipt, setModalAddReceipt] = useState(false);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [spendingCash, setSpendingCash] = useState([]);
  const [form, setForm] = useState({
    store: "",
    purchased_by: "",
    item_purchased: "",
    amount: "",
    reimbursed_peding: "reimbursed",
    receipt: "yes"
  });
  const [tableField, setTableField] = useState({
    date: false,
    store: false,
    purchased_by: false,
    item_purchased: false,
    amount: false,
    reimbursed_peding: false,
    receipt: false,
  });

  useEffect(() => {
    getSpendingCash()
  },  [count, page]);

  useEffect(() => {
    setSpendingCash(props.spendingCashList)
  },  [props.spendingCashList]);


  const pageCount = (e) => {
    setCount(e.target.value)
  };

  const printPage = () =>{
    window.print();
  };

  const clickRight = () => {
    if(props.pagination) {
      if (page < props.pagination.count_pages){
        setPage(+page + 1)
      }
    }
  };

  const clickLeft = () => {
    if (page > 1) {
      setPage(+page - 1)
    }
  };

  const changeReimbursed = (event, id) => {
    props.changeReimbursedData({id: id, reimbursed_peding: event.target.value})
  };
  const changeReceipt = (event, id) => {
    props.changeReceiptData({id: id, receipt: event.target.value})
  };

  const filter = (calendar) => {
    if (calendar.day) {
      getSpendingCash(calendar.day)
      setNewDay(calendar.day)
    }
  };

  const getSpendingCash = (day) => {
    if (day || newDay) {
      if (!day) {
        day = newDay
      }
      setLoading(true)
      if (day && day.previousDay) {
        props.getSpendingCashList({
          previousDay: day.previousDay,
          page: page,
          count: count
        }).then(() => {
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        })
      } else if (day.start_date && day.end_date) {
        props.getSpendingCashList({
          start_date: day.start_date,
          end_date: day.end_date,
          page: page,
          count: count
        }).then(() => {
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        })
      } else if (day.start_date && !day.end_date && !day.previousDay) {
        props.getSpendingCashList({
          start_date: day.start_date,
          page: page,
          count: count
        }).then(() => {
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        })
      } else if (day.days) {
        props.getSpendingCashList({
          days: day.days,
          page: page,
          count: count
        }).then(() => {
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        })
      } else if (day.start_period && day.end_period) {
        props.getSpendingCashList({
          end_period: day.end_period,
          start_period: day.start_period,
          page: page,
          count: count
        }).then(() => {
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        })
      } else if (day.months) {
        props.getSpendingCashList({
          months: day.months,
          page: page,
          count: count
        }).then(() => {
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        })
      }
    } else {
      props.getSpendingCashList({
        previousDay: '6',
        page: page,
        count: count
      }).then(() => {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      })
    }
  }

  const hideAddModal = () => {
    setModalAddReceipt(false)
    setForm({
      store: "",
      purchased_by: "",
      item_purchased: "",
      amount: "",
      reimbursed_peding: "reimbursed",
      receipt: "yes"
    })
  };

  const createReceipt = (e) => {
    e.preventDefault()
    setAddButtonDisabled(true)
    props.spendingCashCreate(form).then(res => {
      if (res) {
        if (res.type === 'CREATE_SPENDING_CASH_SUCCESS') {
          hideAddModal()
          toast.success('Квитанция добавлена');
        } else {
          const data = res.error.data
          const keys = Object.keys(data)
          toast.error(data[keys[0]][0]);
        }
      } else (
        toast.error('Server error')
      );
      setAddButtonDisabled(false)
    });
  };

  const sortField = (field) => {
    if (spendingCash && spendingCash.length) {
      let spendingCashList = [...spendingCash]
      spendingCashList = spendingCashList.sort((a, b) => {
        if (tableField[field]) {
          if (a[field] > b[field]) {
            return 1
          }
          if (a[field] < b[field]) {
            return -1
          }
          return 0
        } else {
          if (a[field] < b[field]) {
            return 1
          }
          if (a[field] > b[field]) {
            return -1
          }
          return 0
        }
      });
      setSpendingCash(spendingCashList)
      setTableField({...tableField, [field]: !tableField[field]})
    }
  };

  return (
    <div className="performance">
      {loading ?
        <div className="loading-block">
          <BeatLoader color="#0A65FF" loading={loading} size={20}/>
        </div> : null
      }
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bread-crumbs-mini"><p>Траты кэш</p></div>
      <div className="top-block d-flex align-items-center justify-content-end" onClick={() => setModalAddReceipt(true)}>
        <button className="add" type="button"><img src={add} alt="Add"/>add a receipt</button>
      </div>
      <div className="mobile-buttons">
        <button className="add" type="button"><img src={add} alt="Add"/></button>
      </div>
      <div className="performance-block">
        <Calendar calendarFilter={(calendar) => filter(calendar)}/>
        <div className="scroll-table header-filter-table">
          <div className="scroll-touch scroll-div">
            <table>
              <thead>
              <tr>
                <th>
                  <div onClick={() => sortField('date')}>
                    date
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
                <th>
                  <div onClick={() => sortField('store')}>
                    store
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
                <th>
                  <div onClick={() => sortField('purchased_by')}>
                    purchased by
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
                <th>
                  <div onClick={() => sortField('item_purchased')}>
                    item purchased
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
                <th>
                  <div onClick={() => sortField('amount')}>
                    amount
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
                <th>
                  <div onClick={() => sortField('reimbursed_peding')}>
                    reimbursed/peding
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
                <th>
                  <div onClick={() => sortField('receipt')}>
                    receipt
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              {spendingCash && spendingCash.length ? spendingCash.map((item, index) => (
              <tr key={index}>
                <td>{item.date}</td>
                <td>{item.store}</td>
                <td>{item.purchased_by}</td>
                <td>{item.item_purchased}</td>
                <td className="amount">{item.amount}</td>
                <td className="reimbursed" style={item.reimbursed_peding === 'reimbursed' ? {backgroundColor: '#EAF9FF'} : {backgroundColor: '#e9be45'}}>
                  <select className="select-picker" data-width="fit" value={item.reimbursed_peding} onChange={(e) => changeReimbursed(e, item.id)}>
                    <option>reimbursed</option>
                    <option>pending</option>
                  </select>
                </td>
                <td className="reimbursed" style={item.receipt === 'yes' ? {backgroundColor: '#ECF8E8'} : {backgroundColor: '#FFE8E8'}}>
                  <select className="select-picker" data-width="fit" value={item.receipt} onChange={(e) => changeReceipt(e, item.id)}>
                    <option>yes</option>
                    <option>no</option>
                  </select>
                </td>
              </tr>
              )) : null
              }
              </tbody>
            </table>
          </div>
        </div>
        <div className="table-pagination">
          <div className="page-count">
            <span>{props.pagination  && props.pagination.count_skip ? props.pagination.skip_page + 1 : '0'}-{props.pagination ? props.pagination.count_skip : ''}</span> of {props.pagination ? props.pagination.count : ''}
          </div>
          <div className="row-page">
            <div className="d-flex align-items-center mr-5">
              <p>Rows per page:</p>
              <select className="select-picker cursor-pointer" onChange={ (event) => {pageCount(event)}} data-width="fit">
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='15'>15</option>
              </select>
            </div>
            <div>
              <img src={arrowLeft} onClick={() => {clickLeft()}} alt="Left" className="cursor-pointer mr-5"/>
              <img src={arrowRight} onClick={() => {clickRight()}} className="cursor-pointer" alt="Right"/>
            </div>
          </div>
        </div>
      </div>
      <button type="button" onClick={printPage} className="print-page"><img src={print} alt="Print"/>Print this page</button>
      <Modal
        show={modalAddReceipt}
        onHide={hideAddModal}
        centered
        dialogClassName="add-user-modal"
      >
        <Modal.Body>
          <form className="add-user" onSubmit={e => createReceipt(e)}>
            <div className="personal-data">
              <div className="title">Квитанция</div>
              <div className="form-group">
                <div className="form-block">
                  <label htmlFor="store">store</label>
                  <input onInvalid={(e) => e.target.setCustomValidity('Поле обязательно для заполнения!')} onInput={(e) => e.target.setCustomValidity('')} required type="text" id="store" name="store" value={form.store} onChange={(e) => setForm({...form, store: e.target.value})}/>
                </div>
                <div className="form-block">
                  <label htmlFor="purchased_by">purchased by</label>
                  <input onInvalid={(e) => e.target.setCustomValidity('Поле обязательно для заполнения!')} onInput={(e) => e.target.setCustomValidity('')} required type="text" id="purchased_by" name="purchased_by" value={form.purchased_by} onChange={(e) => setForm({...form, purchased_by: e.target.value})}/>
                </div>
                <div className="form-block">
                  <label htmlFor="item_purchased">item purchased</label>
                  <input onInvalid={(e) => e.target.setCustomValidity('Поле обязательно для заполнения!')} onInput={(e) => e.target.setCustomValidity('')} required type="text" id="item_purchased" name="item_purchased" value={form.item_purchased} onChange={(e) => setForm({...form, item_purchased: e.target.value})}/>
                </div>
                <div className="form-block">
                  <label htmlFor="amount">amount</label>
                  <input onInvalid={(e) => e.target.setCustomValidity('Поле обязательно для заполнения!')} onInput={(e) => e.target.setCustomValidity('')} required type="text" id="amount" name="amount" value={form.amount} onChange={(e) => setForm({...form, amount: e.target.value})}/>
                </div>
                <div className="form-block">
                  <label htmlFor="reimbursed_pending">reimbursed pending</label>
                  <select data-width="fit" id="reimbursed_pending" name="reimbursed_peding" value={form.reimbursed_peding} onChange={(e) => setForm({...form, reimbursed_peding: e.target.value})}>
                    <option value="reimbursed">reimbursed</option>
                    <option value="pending">pending</option>
                  </select>
                </div>
                <div className="form-block">
                  <label htmlFor="receipt">receipt</label>
                  <select data-width="fit" id="receipt" name="receipt" value={form.receipt} onChange={(e) => setForm({...form, receipt: e.target.value})}>
                    <option value="yes">yes</option>
                    <option value="no">no</option>
                  </select>
                </div>
              </div>
            </div>
            <button className={addButtonDisabled ? 'button-disabled submit' : 'submit'} disabled={addButtonDisabled}>Добавить Квитанцию</button>
            <div className="close" onClick={hideAddModal}>
              <img src={close} alt="Close"/>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    spendingCashList: state.spendingCashReducer.spendingCashList,
    pagination: state.spendingCashReducer.pagination

  };
}

const mapDispatchToProps = d => ({
  getSpendingCashList: (params) => d(spendingCashActions.getSpendingCashList(params)),
  spendingCashCreate: (params) => d(spendingCashActions.spendingCashCreate(params)),
  changeReimbursedData: (params) => d(spendingCashActions.changeReimbursedData(params)),
  changeReceiptData: (params) => d(spendingCashActions.changeReceiptData(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpendingCash);
