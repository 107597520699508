import { branchConstants } from '../constants/actionTypes';

const initialState = {
  branch: [],
  activeBranch: {}
};

export function branchReducer(state = initialState, action) {
  switch (action.type) {
    case branchConstants.GET_BRANCH_REQUEST:
      return {
        ...state,
        branch: [],
        activeBranch: {}
      };
    case branchConstants.GET_BRANCH_SUCCESS:
      return {
        ...state,
        branch: action.branch
      };
    case branchConstants.SHOW_BRANCH_SUCCESS:
      return { ...state, activeBranch: action.branch };

     case branchConstants.CREATE_BRANCH_SUCCESS:
       let arr = [...state.branch];
        arr.push(action.branch)
       return { ...state, branch: arr };

     case branchConstants.UPDATE_BRANCH_SUCCESS:
       let array = [...state.branch];
       array = array.map((i) => {
         if (i.id  === action.branch.id) {
           i = action.branch
         }
         return i
       });
       return { ...state, branch: array };
    case branchConstants.GET_BRANCH_FAILURE:
      return {
        ...state,
        branch: [],
      };
    case branchConstants.DELETE_BRANCH_SUCCESS:
      let branchData = [...state.branch];
      branchData = branchData.filter(item => item.id !== action.id)
      return {
        ...state,
        branch: branchData,
      };
      case branchConstants.DELETE_BRANCH_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
}
