import React, {useEffect, useState} from "react";
import arrow from "../../../assets/image/icons/arrow.svg";
import left from "../../../assets/image/icons/left.svg";
import right from "../../../assets/image/icons/right.svg";
import { Line } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import print from "../../../assets/image/icons/print.svg";
import {bonusActions} from "../../../redux/actions";
import {connect} from "react-redux";
import MultiSelect from "react-multi-select-component";
import Calendar from "../../components/calendar";
import BeatLoader from "react-spinners/BeatLoader";

const All = (props) => {
  const [filterBonus, setFilterBonus] = useState('Неделя');
  const [selected, setSelected] = useState([]);
  const [newDay, setNewDay] = useState();
  const [optionsSelect, setOptionsSelect] = useState();
  const [itemBonus, setItemBonus] = useState({bonus: [] , color: [] });
  const [totalBonus, setTotalBonus] = useState({});
  const [loading, setLoading] = useState(false);
  const options = {
    responsive: true,
    spanGaps: false,
    maintainAspectRatio: false,
    layout: { padding: { left: 0, right: 0 } },
    elements: {
      point:{ radius: 4, borderWidth: 2, hoverRadius: 4, hoverBorderWidth: 5 }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        yAlign: 'bottom',
        backgroundColor: '#FFFFFF',
        titleColor: '#9F9F9F',
        titleFont: {
          weight: '400',
          size: '12'
        },
        titleMarginBottom: 10,
        bodyColor: '#282828',
        bodyFont: {
          weight: '600',
          size: '14'
        },
        padding: 15,
        cornerRadius: 8,
        displayColors: false,
        callbacks: {
          label: function(tooltipItem) {
            return tooltipItem.dataset.label+ ' ' + tooltipItem.formattedValue;
          },
          title: function(tooltipItem) {
            return tooltipItem[0].label;
          }
        }
      },
    },
    tension: 0.3,
    scales: {
      xAxes: [
        { gridLines: { display: false },
          ticks: { fontColor: "rgba(0, 0, 0, 0.54)" } } ],
      yAxes: [
        {
          gridLines: {
            tickMarkLength: 16,
            color: "rgba(0, 0, 0, 0.1)"
          },
          ticks:   {
            stepSize: 5,
            min: 0,
            fontColor: "rgba(0, 0, 0, 0.54)"
          }
        }
      ]
    },
  };
  const optionsReport = {
    responsive: true,
    plugins: {
      tooltip: {
        yAlign: 'bottom',
        backgroundColor: '#FFFFFF',
        bodyColor: '#282828',
        bodyFont: {
          weight: '400',
          size: '12'
        },
        padding: 15,
        cornerRadius: 8,
        displayColors: false,
        callbacks: {
          label: function(tooltipItem) {
            return tooltipItem.label + ' ' +tooltipItem.formattedValue;
          }
        }
      },
      legend: {
        display: false
      },
      title: {
        display: false,
      },
    }
  }

  useEffect(() => {
    getBonus()
  },[selected])

  const getBonus = (day) => {
    setLoading(true)
    let type_id = []
    if (day || newDay) {
      if (!day) {
        day = newDay
      }
      selected.map((item) => {
        type_id.push(item.value)
      })
      if (day && day.previousDay) {
        props.getAllReports({
          previousDay: day.previousDay,
          type_id: type_id
        }).finally(() => {
          setLoading(false)
        })
      } else if (day.start_date && day.end_date) {
        props.getAllReports({
          start_date: day.start_date,
          end_date: day.end_date,
          type_id: type_id,
        }).finally(() => {
          setLoading(false)
        })
      } else if (day.start_date && !day.end_date && !day.previousDay) {
        props.getAllReports({
          start_date: day.start_date,
          type_id: type_id,
        }).finally(() => {
          setLoading(false)
        })
      } else if (day.days) {
        props.getAllReports({
          days: day.days,
          type_id: type_id,
        }).finally(() => {
          setLoading(false)
        })
      } else if (day.start_period && day.end_period) {
        props.getAllReports({
          end_period: day.end_period,
          start_period: day.start_period,
          type_id: type_id,
        }).finally(() => {
          setLoading(false)
        })
      } else if (day.months) {
        props.getAllReports({
          months: day.months,
          type_id: type_id,
        }).finally(() => {
          setLoading(false)
        })
      }
    }
  };

  useEffect(() => {
    setTime();
  } , [props.bonusSchedule])

  const setTime = () => {
    setTimeout(() => {
      if (Object.keys(props.bonusSchedule).length) {
        if (props.bonusSchedule.userBonusesTotal) {
          let bonus = []
          let color = []
          let user = []
          props.bonusSchedule.userBonusesTotal.map((item) => {
            bonus.push(item.bonus)
            color.push('#' + item.color)
            user.push(item.user.name + ' ' + item.user.worker_type_name)
          })
          setItemBonus({bonus: bonus , color: color, user: user})
        }
        if (props.bonusSchedule.bonusesTotal) {
          let total = []
          for (const [p, [kay, val]] of Object.entries(Object.entries(props.bonusSchedule.bonusesTotal))) {
            let data =[]
            if (val && val.total) {
              val.total.map((i) => data.push(i.sum))
            }
            total.push({
              label: kay,
              data: data,
              borderColor: val.color,
              backgroundColor: val.bgColor,
            })
          }
          setTotalBonus(total)
        }
      }
    }, 0);
  }

  useEffect(() => {
    let label = []
    if (props.paymentsType) {
      props.paymentsType.map((item) => {
        label.push({label: item.type_name, value: item.id})
      })
      setOptionsSelect(label)
    }
  } , [props.paymentsType])

  const filter = (calendar) => {
    if (calendar.day) {
      setNewDay(calendar.day)
      getBonus(calendar.day)
    }
  };

  const printPage = () =>{
    window.print();
  };

  useEffect(() => {
    props.getPaymentsType().then(() => {
    })
  }, [])

  return (
    <div className="staff-detail">
      {loading ?
        <div className="loading-block">
          <BeatLoader color="#0A65FF" loading={loading} size={20}/>
        </div> : null
      }
      <div onClick={() => props.history.goBack()} className="bread-crumbs"><img src={arrow} alt="arrow"/><p>Назад</p></div>
      <div className="bread-crumbs-mini"><img src={arrow} alt="arrow" onClick={() => props.history.goBack()}/><p>Все отчеты</p></div>
      <div className="page-title">Все отчеты</div>
      <div className="bonus-schedule">
        <div className="total">
          <div className="title">Отчет по общему количеству бонусов</div>
          <div className="chart-body">
            <div className="chart-options">
              <div className="choose">
                Choose Week
                <div className="date">
                  <button type="button"><img src={left} alt="left"/></button>
                  <Calendar calendarFilter={(calendar) => filter(calendar)}/>
                  <button type="button"><img src={right} alt="right"/></button>
                </div>
              </div>
              <div className="filter">
                <button type="button" className={filterBonus === 'День' ? 'active-filter' : ''} onClick={() => {setFilterBonus('День'); getBonus({previousDay: 1})}}>День</button>
                <button type="button" className={filterBonus === 'Неделя' ? 'active-filter' : ''} onClick={() => {setFilterBonus('Неделя');  getBonus({previousDay: 6})}}>Неделя</button>
                <button type="button" className={filterBonus === 'Месяц' ? 'active-filter' : ''} onClick={() => {setFilterBonus('Месяц');  getBonus({previousDay: 30})}}>Месяц</button>
              </div>
            </div>
            <div className="chart-info">
              <div>
                {optionsSelect ?
                    <MultiSelect
                        options={optionsSelect}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                    /> : ''
                }

              </div>
            </div>
            {!loading ?
              <div className="chart">
                {totalBonus.length ?
                  <Line
                    data={{
                      labels: props.bonusSchedule.bonusesDate,
                      datasets: totalBonus
                    }}
                    options={options}
                  /> :
                  <Line
                    data={{
                      labels: props.bonusSchedule.bonusesDate,
                      datasets: [
                        {
                          label: `Dataset`,
                          data: [0,0,0,0,0,0],
                          borderColor: '#2135ff',
                          backgroundColor: '#2135ff',
                        }
                      ]
                    }}
                    options={options}
                  />
                }
              </div> : null
            }
          </div>
        </div>
        <div className="day-report">
          <div className="title">Отчет по сотрудникам за день</div>
          <div className="chart-body">
            <div className="chart-options">
              {props.bonusSchedule.userBonusesTotal && !loading ? props.bonusSchedule.userBonusesTotal.map((item ,index) => {
                return (
                    <div key={index} className="item">
                      <span className="circle" style={{backgroundColor: '#' + item.color}}></span>
                      <div>—   {item.user.name}<span>({item.user.worker_type_name})</span></div>
                    </div>
                )
              }) : ''
              }
            </div>
            <div className="chart">
              {itemBonus.user && !loading ?
                  <Doughnut
                      data={{
                        labels: itemBonus.user,
                        datasets: [
                          {
                            label: 'Report',
                            data:  itemBonus.bonus,
                            backgroundColor: itemBonus.color,
                          }
                        ]
                      }}
                      options={optionsReport}
                  /> : ''
              }
            </div>
          </div>
        </div>
      </div>
      <button type="button" onClick={printPage} className="print-page"><img src={print} alt="Print"/>Print this page</button>
    </div>
  );
};


const mapStateToProps = state => {
  return {
    paymentsType: state.bonusReducer.paymentsType,
    bonusSchedule: state.bonusReducer.bonusSchedule,
  };
}
const mapDispatchToProps = d => ({
  getPaymentsType: () => d(bonusActions.getPaymentsType()),
  getAllReports: (params) => d(bonusActions.getAllReports(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(All);
