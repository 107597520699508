import React, {useEffect, useState} from "react";
import arrowLeft from "../../../../assets/image/icons/arrow-left.svg";
import arrowRight from "../../../../assets/image/icons/arrow-right.svg";
import print from "../../../../assets/image/icons/print.svg";
import {connect} from "react-redux";
import {accountActions} from "../../../../redux/actions";
import add from "../../../../assets/image/icons/add.svg";
import {Modal} from "react-bootstrap";
import close from "../../../../assets/image/icons/close.svg";
import {toast, ToastContainer} from "react-toastify";
import edit from "../../../../assets/image/icons/edit.svg";
import deleteIcon from "../../../../assets/image/icons/delete.svg";
import BeatLoader from "react-spinners/BeatLoader";
import arrow from "../../../../assets/image/icons/arrow.svg";

const Accounts = (props) => {
  const [count, setCount] = useState('5');
  const [page, setPage] = useState('1');
  const [modalAddAccount, setModalAddAccount] = useState(false);
  const [editAccountBoolean, setЕditAccountBoolean] = useState(false);
  const [addButtonDisabled, setАddButtonDisabled] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    method: '',
    name: ''
  });

  useEffect(() => {
    getAccounts()
  },  [count, page]);

  const pageCount = (e) => {
    setCount(e.target.value)
  };

  const clickRight = () => {
    if(props.pagination) {
      if (page < props.pagination.count_pages){
        setPage(+page + 1)
      }
    }
  };

  const clickLeft = () => {
    if (page > 1) {
      setPage(+page - 1)
    }
  };

  const printPage = () =>{
    window.print();
  };

  const getAccounts = (day) => {
    setLoading(true)
    props.getAccountsList({
      page: page,
      count: count
    }).then(() => {
      setLoading(false)
    });
  };

  const addAccountModalShow = () => {
    setЕditAccountBoolean(false);
    props.getAccountType()
    setModalAddAccount(true)
  };

  const openEditModal = (account) => {
    setForm({
      id: account.id,
      method: account.method,
      name: account.name
    });
    props.getAccountType()
    setЕditAccountBoolean(true)
    setModalAddAccount(true)
  };

  const hideAddModal = () => {
    setForm({
      method: '',
      name: ''
    });
    setModalAddAccount(false)
  };

  const deleteAccount = () => {
    props.deleteAccount(deleteModalShow).then(res => {
      if (res.type === 'DELETE_ACCOUNT_SUCCESS') {
        setDeleteModalShow(false)
        toast.success('Аккаунт удален');
      } else {
        const data = res.error.data
        const keys = Object.keys(data)
        toast.error(data[keys[0]][0]);
      }
    })
  };

  const accountCreateUpdate = (e) => {
    e.preventDefault()
    setАddButtonDisabled(true)
    if (!editAccountBoolean) {
      props.accountCreate(form).then(res => {
        if (res.type === 'CREATE_ACCOUNT_SUCCESS') {
          hideAddModal()
          toast.success('Аккаунт добавлен');
        } else {
          const data = res.error.data
          const keys = Object.keys(data)
          toast.error(data[keys[0]][0]);
        }
        setАddButtonDisabled(false)
      });
    } else {
      props.accountUpdate(form).then(res => {
        if (res.type === 'UPDATE_ACCOUNT_SUCCESS') {
          hideAddModal()
          toast.success('Аккаунт обновлён');
        } else {
          const data = res.error.data
          const keys = Object.keys(data)
          toast.error(data[keys[0]][0]);
        }
        setАddButtonDisabled(false)
      });
    }
  };

  return (
    <div className="performance">
      {loading ?
        <div className="loading-block">
          <BeatLoader color="#0A65FF" loading={loading} size={20}/>
        </div> : null
      }
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bread-crumbs-mini"><img src={arrow} alt="arrow" onClick={() => props.history.goBack()}/><p>Аккаунты</p></div>
      <div className="top-block d-flex align-items-center justify-content-end">
        <button className="add-button" type="button" onClick={() => addAccountModalShow()}><img src={add} alt="Add"/><div>add account</div></button>
      </div>
      <div className="performance-block">
        <div className="scroll-table bonuses-table">
          <div className="scroll-touch scroll-div">
            <table>
              <thead>
              <tr className="th-role">
                <th>Account</th>
                <th>Method</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {props.accounts && props.accounts.length ?
              props.accounts.map((item, index) => {
                return(
                <tr key={index}>
                  <td className="notes">{item.name}</td>
                  <td className="notes">{item.method}</td>
                  <td>
                    <div className="bonus-action">
                      <button onClick={() => openEditModal(item)} type="button" className="edit-button"><img src={edit} alt="Edit"/><div>edit</div></button>
                      <button type="button" className="delete-button" onClick={() => setDeleteModalShow(item.id)}><img src={deleteIcon} alt="Delete"/><div>delete</div></button>
                    </div>
                  </td>
                </tr>
                )
              }) : null
              }
              </tbody>
            </table>
          </div>

        </div>
        <div className="table-pagination">
          <div className="page-count">
            <span>{props.pagination  && props.pagination.count_skip ? props.pagination.skip_page + 1 : '0'}-{props.pagination ? props.pagination.count_skip : ''}</span> of {props.pagination ? props.pagination.count : ''}
          </div>
          <div className="row-page">
            <div className="d-flex align-items-center mr-5">
              <p>Rows per page:</p>
              <select onChange={ (event) => {pageCount(event)}} className="select-picker" data-width="fit">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </div>
            <div>
              <img src={arrowLeft} onClick={() => {clickLeft()}} alt="Left" className="mr-5 cursor-pointer"/>
              <img src={arrowRight} onClick={() => {clickRight()}} alt="Right" className="cursor-pointer"/>
            </div>
          </div>
        </div>
      </div>
      <button type="button" onClick={printPage} className="print-page"><img src={print} alt="Print"/>Print this page</button>
      <Modal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        centered
        dialogClassName="user-delete-modal"
      >
        <Modal.Body>
          <div className="title">Вы действитльно хотите удалить аккаунт?</div>
          <div className="text">Аккаунт будет удален</div>
          <button onClick={() => deleteAccount()} type="button">удалить</button>
          <div className="close" onClick={() => setDeleteModalShow(false)}>
            <img src={close} alt="Close"/>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalAddAccount}
        onHide={hideAddModal}
        centered
        dialogClassName="add-user-modal"
      >
        <Modal.Body>
          <form className="add-user" onSubmit={(e) => accountCreateUpdate(e)}>
            <div className="personal-data">
              <div className="title">Аккаунт</div>
              <div className="form-group">
                <div className="form-block position-relative">
                  <label htmlFor="name">Имя</label>
                  <input onInvalid={(e) => e.target.setCustomValidity('Поле имя обязательно для заполнения!')} onInput={(e) => e.target.setCustomValidity('')} name="name" className="mt-2" value={form.name} onChange={(e) => setForm({...form, name:e.target.value})} required  type="text" id="name"/>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="method">Метод</label>
                  <input onInvalid={(e) => e.target.setCustomValidity('Поле метод обязательно для заполнения!')} onInput={(e) => e.target.setCustomValidity('')} name="method" placeholder="net_pay = check_gross - 15%" className="mt-2" value={form.method} onChange={(e) => setForm({...form, method:e.target.value})} required  type="text" id="method"/>
                </div>
              </div>
            </div>
            <div className="account-type">
              <h6>Например: </h6>
              <h6 className="ml-3 mr-5">check_gross = net_pay,check_net = check_gross - 15% </h6>
              <h6 className="ml-2 mr1">check_gross = net_pay / 2,check_net = check_gross - 50$,cash_percent = net_pay / 2 </h6>
              {props.accountType && props.accountType.length ? props.accountType.map((item, index) => {
                return (
                  <div key={index}>{item.type_name} ,</div>
                )
              }) : ''
              }
            </div>
            <button className={addButtonDisabled ? 'button-disabled submit' : 'submit'} disabled={addButtonDisabled}>{editAccountBoolean ? 'Редактировать Аккаунт' : 'Добавить Аккаунт'}</button>
            <div className="close" onClick={hideAddModal}>
              <img src={close} alt="Close"/>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    accounts: state.accountReducer.accounts,
    pagination: state.accountReducer.pagination,
    accountType: state.accountReducer.accountType
  };
}

const mapDispatchToProps = branch => ({
  getAccountsList: (data) => branch(accountActions.getAccountsList(data)),
  getAccountType: () => branch(accountActions.getAccountType()),
  accountCreate: (data) => branch(accountActions.accountCreate(data)),
  accountUpdate: (data) => branch(accountActions.accountUpdate(data)),
  deleteAccount: (data) => branch(accountActions.deleteAccount(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
