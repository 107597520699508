import { roleConstants } from '../constants/actionTypes';

const initialState = {
  roles: [],
  roleTypes: [],
  pagination: {}
};

export function roleReducer(state = initialState, action) {
  switch (action.type) {
    case roleConstants.GET_ROLES_LIST_REQUEST:
      return {...state};
    case roleConstants.GET_ROLES_LIST_SUCCESS:
      return {...state,
        roles: action.roles.data,
        pagination: action.roles.pagination
      };
    case roleConstants.GET_ROLES_LIST_FAILURE:
      return {...state};
    case roleConstants.GET_ROLE_TYPE_REQUEST:
      return {...state};
    case roleConstants.GET_ROLE_TYPE_SUCCESS:
      return {...state,
        roleTypes: action.roleTypes
      };
    case roleConstants.GET_ROLE_TYPE_FAILURE:
      return {...state};
    case roleConstants.CREATE_ROLE_REQUEST:
      return {...state};
    case roleConstants.CREATE_ROLE_SUCCESS:
      let rolesList = [...state.roles];
      rolesList.unshift(action.role.data)
      return {
        ...state,
        roles: rolesList,
      };
    case roleConstants.CREATE_ROLE_FAILURE:
      return {...state};
    case roleConstants.UPDATE_ROLE_REQUEST:
      return {...state};
    case roleConstants.UPDATE_ROLE_SUCCESS:
      let rolesListData = [...state.roles];
      rolesListData = rolesListData.map(item => {
        if (item.id === action.role.data.id) {
          item = action.role.data
        }
        return item
      });
      return {
        ...state,
        roles: rolesListData,
      };
    case roleConstants.UPDATE_ROLE_FAILURE:
      return {...state};
    case roleConstants.DELETE_ROLE_REQUEST:
      return {...state};
    case roleConstants.DELETE_ROLE_SUCCESS:
      let deleteRolesList = [...state.roles];
      deleteRolesList = deleteRolesList.filter(item => item.id !== action.role);
      return {
        ...state,
        roles: deleteRolesList,
      };
    case roleConstants.DELETE_ROLE_FAILURE:
      return {...state};
    default:
      return state;
  }
}
