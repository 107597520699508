import React, {useEffect, useState} from "react";
import queryString from 'query-string';
import search from "../../../assets/image/icons/search.svg";
import avatar from "../../../assets/image/default-avatar.png";
import {searchActions} from "../../../redux/actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const Search = (props) => {

  let query = queryString.parse(props.location.search)
  const [searchValue, setSearchValue] = useState(query.value);

  useEffect(() => {
    setSearchValue(query.value)
  },  [query.value]);

  useEffect(() => {
    searchUsers()
  },  [searchValue]);

  const searchUsers = () => {
    props.searchUsersList({value: searchValue})
  };

  return (
    <div className="search-page">
      <div className="bread-crumbs-mini"><p>Поиск</p></div>
      <div className="search">
        <input type="search" placeholder="Search Users by Name or Email" value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>
        <img src={search} alt="Search"/>
      </div>
      <div className="search-result">
        {props.usersList && props.usersList.length ? props.usersList.map((item, index) => {
          return (
            <Link to={'/staff/' + item.id} key={index} className="result">
              <div className="name">{item.first_name} {item.last_name}</div>
              <div className="avatar">
                {item.avatar ?
                  <img src={item.avatar} alt={item.avatar}/> :
                  <img src={avatar} alt="user_avatar"/>
                }
              </div>
            </Link>
          )
        }) :
          <div>По вашему запросу нету результатов.</div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    usersList: state.searchReducer.usersList
  };
};

const mapDispatchToProps = d => ({
  searchUsersList: (params) => d(searchActions.searchUsersList(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
