import React, {useEffect, useState} from "react";
import arrowLeft from "../../../assets/image/icons/arrow-left.svg";
import arrowRight from "../../../assets/image/icons/arrow-right.svg";
import arrowTable from "../../../assets/image/icons/arrow-table-filter.svg";
import print from "../../../assets/image/icons/print.svg";
import add from "../../../assets/image/icons/add.svg";
import Calendar from "../../components/calendar";
import {Modal} from "react-bootstrap";
import close from "../../../assets/image/icons/close.svg";
import {toast, ToastContainer} from "react-toastify";
import {checkActions, userActions} from "../../../redux/actions";
import {connect} from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

const CheckRegistration = (props) => {
  const [modalAddReceipt, setModalAddReceipt] = useState(false);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [count, setCount] = useState('5');
  const [newDay, setNewDay] = useState();
  const [page, setPage] = useState('1');
  const [loading, setLoading] = useState(false);
  const [checkListData, setCheckListData] = useState([]);
  const [form, setForm] = useState({
    description_of_transaction: "",
    paid_to: "",
    amount: "",
    paid_by: ""
  });
  const [tableField, setTableField] = useState({
    id: false,
    date: false,
    description_of_transaction: false,
    paid_to: false,
    amount: false,
    paid_by: false
  });

  useEffect(() => {
    getCheck()
  },  [count, page]);

  useEffect(() => {
    setCheckListData(props.checkList)
  },  [props.checkList]);


  const pageCount = (e) => {
    setCount(e.target.value)
  };

  const printPage = () =>{
    window.print();
  }

  const clickRight = () => {
    if(props.pagination) {
      if (page < props.pagination.count_pages){
        setPage(+page + 1)
      }
    }
  };

  const clickLeft = () => {
    if (page > 1) {
      setPage(+page - 1)
    }
  };

  const filter = (calendar) => {
    if (calendar.day) {
      getCheck(calendar.day)
      setNewDay(calendar.day)
    }
  };

  const getCheck = async (day) => {
    let data = null;
    setLoading(true)
    if (day || newDay) {
      if (!day) {
        day = newDay
      }
      if (day && day.previousDay) {
        data = {
          previousDay: day.previousDay,
        }
      } else if (day.start_date && day.end_date) {
        data = {
          start_date: day.start_date,
          end_date: day.end_date,
        }
      } else if (day.start_date && !day.end_date && !day.previousDay) {
        data = {
          start_date: day.start_date,
        }
      } else if (day.days) {
        data = {
          days: day.days,
        }
      } else if (day.start_period && day.end_period) {
        data = {
          end_period: day.end_period,
          start_period: day.start_period,
        }
      } else if (day.months) {
        data = {
          months: day.months,
        }
      }
    } else {
      data = {
        previousDay: '6',
      }
    }
    if (data) {
      data.page = page
      data.count = count
      await props.getCheckList(data).then(() => {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      })
    };
  };

  const hideAddModal = () => {
    setModalAddReceipt(false)
    setForm({
      description_of_transaction: "",
      paid_to: "",
      amount: "",
      paid_by: ""
    })
  };

  const createCheck = (e) => {
    e.preventDefault()
    setAddButtonDisabled(true)
    props.checkCreate(form).then(res => {
      if (res) {
        if (res.type === 'CREATE_CHECK_SUCCESS') {
          hideAddModal()
          toast.success('Чек добавлен');
        } else {
          const data = res.error.data
          const keys = Object.keys(data)
          toast.error(data[keys[0]][0]);
        }
      } else (
        toast.error('Server error')
      );
      setAddButtonDisabled(false)
    });
  };

  const sortField = (field) => {
    if (checkListData && checkListData.length) {
      let checks = [...checkListData]
      checks = checks.sort((a, b) => {
        if (tableField[field]) {
          if (a[field] > b[field]) {
            return 1
          }
          if (a[field] < b[field]) {
            return -1
          }
          return 0
        } else {
          if (a[field] < b[field]) {
            return 1
          }
          if (a[field] > b[field]) {
            return -1
          }
          return 0
        }
      });
      setCheckListData(checks)
      setTableField({...tableField, [field]: !tableField[field]})
    }
  };

  return (
    <div className="performance">
      {loading ?
        <div className="loading-block">
          <BeatLoader color="#0A65FF" loading={loading} size={20}/>
        </div> : null
      }
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bread-crumbs-mini"><p>Регистрация чеков</p></div>
      <div className="top-block d-flex align-items-center justify-content-end">
        <button className="add" type="button" onClick={() => {setModalAddReceipt(true); props.getAllUsersList()}}><img src={add} alt="Add"/>add a check</button>
      </div>
      <div className="mobile-buttons">
        <button className="add" type="button" onClick={() => {setModalAddReceipt(true); props.getAllUsersList()}}><img src={add} alt="Add"/></button>
      </div>
      <div className="performance-block">
        <Calendar calendarFilter={(calendar) => filter(calendar)}/>
        <div className="scroll-table header-filter-table">
          <div className="scroll-touch scroll-div">
            <table>
              <thead>
              <tr>
                <th>
                  <div onClick={() => sortField('id')}>
                    check#
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
                <th>
                  <div onClick={() => sortField('date')}>
                    date
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
                <th>
                  <div onClick={() => sortField('description_of_transaction')}>
                    description of transaction
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
                <th>
                  <div onClick={() => sortField('paid_to')}>
                    paid to
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
                <th>
                  <div onClick={() => sortField('amount')}>
                    amount
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
                <th>
                  <div onClick={() => sortField('paid_by')}>
                    paid by
                    <img src={arrowTable} alt="Filter"/>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              {checkListData && checkListData.length ? checkListData.map((item, index) => {
                return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.date}</td>
                  <td>{item.description_of_transaction}</td>
                  <td>{item.paid_to}</td>
                  <td className="amount">{item.amount}</td>
                  <td>{item.user.name}</td>
                </tr>
                )
              }) : null
              }
              </tbody>
            </table>
          </div>
        </div>
        <div className="table-pagination">
          <div className="page-count">
            <span>{props.pagination  && props.pagination.count_skip ? props.pagination.skip_page + 1 : '0'}-{props.pagination ? props.pagination.count_skip : ''}</span> of {props.pagination ? props.pagination.count : ''}
          </div>
          <div className="row-page">
            <div className="d-flex align-items-center mr-5">
              <p>Rows per page:</p>
              <select className="select-picker cursor-pointer" onChange={ (event) => {pageCount(event)}} data-width="fit">
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='15'>15</option>
              </select>
            </div>
            <div>
              <img src={arrowLeft} onClick={() => {clickLeft()}} alt="Left" className="cursor-pointer mr-5"/>
              <img src={arrowRight} onClick={() => {clickRight()}} className="cursor-pointer" alt="Right"/>
            </div>
          </div>
        </div>
      </div>
      <button type="button" onClick={printPage} className="print-page"><img src={print} alt="Print"/>Print this page</button>
      <Modal
        show={modalAddReceipt}
        onHide={() => setModalAddReceipt(false)}
        centered
        dialogClassName="add-user-modal"
      >
        <Modal.Body>
          <form className="add-user" onSubmit={e => createCheck(e)}>
            <div className="personal-data">
              <div className="title">Чек</div>
              <div className="form-group">
                <div className="form-block">
                  <label htmlFor="description_of_transaction">description of transaction</label>
                  <input type="text" id="description_of_transaction" value={form.description_of_transaction} onChange={(e) => setForm({...form, description_of_transaction: e.target.value})}/>
                </div>
                <div className="form-block">
                  <label htmlFor="amount">amount</label>
                  <input type="text" id="amount" value={form.amount} onChange={(e) => setForm({...form, amount: e.target.value})}/>
                </div>
                <div className="form-block">
                  <label htmlFor="paid_to">paid to</label>
                  <input type="text" id="paid_to" value={form.paid_to} onChange={(e) => setForm({...form, paid_to: e.target.value})}/>
                </div>
                    <div className="form-block">
                      <label htmlFor="user_id">paid by</label>
                      <select className="select-role mt-2" value={form.user_id} name="user_id" onChange={e => setForm({...form, user_id: e.target.value})} id="user_id" data-width="fit">
                        {props.allUsersList  && props.allUsersList.length ?
                            props.allUsersList.map((item, index) => {
                              return(
                                  <option key={index} value={item.id}>{item.name}</option>
                              )
                            })
                            : null
                        }
                      </select>
                    </div>
              </div>
            </div>
            <button className={addButtonDisabled ? 'button-disabled submit' : 'submit'} disabled={addButtonDisabled}>Добавить чек</button>
            <div className="close" onClick={() => setModalAddReceipt(false)}>
              <img src={close} alt="Close"/>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    checkList: state.checkReducer.checkList,
    pagination: state.checkReducer.pagination,
    allUsersList: state.userReducer.allUsersList

  };
}

const mapDispatchToProps = d => ({
  getCheckList: (params) => d(checkActions.getCheckList(params)),
  getAllUsersList: (data) => d(userActions.getAllUsersList(data)),
  checkCreate: (params) => d(checkActions.checkCreate(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckRegistration);
