import {http} from "../../modules/http";

export const searchService = {
  searchUsers
};

function searchUsers(params) {
  return http.post('search', params).then(res => {
    return res
  })
}

