import {http} from "../../modules/http";

export const bonusService = {
  getBonus,
  addBonus,
  updateBonus,
  bonusDelete,
  allPaymentsType,
  allReports,
  bonusSchedule
};

function getBonus(params) {
  if (params) {
    params.branch_id = localStorage.getItem('branch')
  }
  const q = new URLSearchParams(params)
  return http.get('/bonuses?' + q.toString()).then(res => {
    return res
  })
}

function allReports(params) {
  params.branch_id = localStorage.getItem('branch')
  return http.post('/all-reports', params).then(res => {
    return res
  })
}

function addBonus(params) {
  return http.post('/bonuses', params).then(res => {
    return res
  })
}

function allPaymentsType() {
  return http.get('/all-payments-accounting-type').then(res => {
    return res
  })
}

function bonusSchedule(params) {
  params.branch_id = localStorage.getItem('branch')
  return http.post('/bonus-schedule', params).then(res => {
    return res
  })
}

function updateBonus(params) {
  return http.put('/bonuses/' + params.id, params).then(res => {
    return res
  })
}

function bonusDelete(params) {
  return http.delete('/bonuses/' + params).then(res => {
    return res
  })
}

