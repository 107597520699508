import React, { useEffect, useState } from "react";
import arrow from "../../../assets/image/icons/arrow.svg";
import avatar from "../../../assets/image/default-avatar.png";
import edit from "../../../assets/image/icons/edit.svg";
import close from "../../../assets/image/icons/close.svg";
import userPhoto from "../../../assets/image/icons/user-photo.svg";
import {Modal} from "react-bootstrap";
import {accountActions, userActions} from "../../../redux/actions";
import { connect } from "react-redux";
import validator from "validator";
import {toast, ToastContainer} from "react-toastify";


const StaffDetail = (props) => {
  const [modalAddUserShow, setModalAddUserShow] = useState(false);
  const [modalPasswordShow, setModalPasswordShow] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const openEditModal = () => {
     setEditUser(props.admin)
     setModalAddUserShow(true)
  };

  const hideAddModal = () => {
    setModalAddUserShow(false)
    setEditUser({})
  }

  const openEditPasswordModal = () => {
     setModalPasswordShow(true)
  };

  const hidePasswordModal = () => {
    setModalPasswordShow(false)
  }

  const handleChange = (e) => {
    const name =  e.target.name;
    if (name === 'avatar') {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (event) => {
      setEditUser({...editUser, avatar: event.target.result})
      };
      reader.readAsDataURL(file)
    }
  };

  useEffect(() => {
    if (props.error) {
      if (props.error.status === 422) {
        const data = props.error.data
        const keys = Object.keys(data)
        setErrorMsg(data[keys[0]][0])
      } else {
        setErrorMsg(props.error.data)
      }
    }
  },  [props.error]);


  const userUpdate = (e) => {
    e.preventDefault()
    setAddButtonDisabled(true)
    let formData = new FormData(e.currentTarget)
    if (!editUser.avatar) {
      formData.delete('avatar')
    }
     formData.append('id', editUser.id)
     props.updateUserInfo(formData, '', '').then(res => {
       if (res.type === 'UPDATE_USER_INFO_SUCCESS') {
         hideAddModal()
         toast.success('Пользователь обновлён');
       } else {
         toast.error(errorMsg);
       }
     }).finally(() => {
       setAddButtonDisabled(false)
     })
  };

  const updatePassword = (e) => {
    e.preventDefault()
    setAddButtonDisabled(true)
    if (password === confirmPassword) {
      let formData = {
        password: password,
        confirm_password: confirmPassword,
      }
      props.updateUserPassword(formData).then(res => {
        if (res.type === 'UPDATE_USER_PASSWORD_SUCCESS') {
          hidePasswordModal()
          toast.success('обновление пароля прошло успешно');
        } else {
          toast.error(errorMsg);
        }
      }).finally(() => {
        setAddButtonDisabled(false)
      })
    } else {
      toast.error('Подтвердите неверный пароль.')
      setAddButtonDisabled(false)
    }
  };

  const validatorEmail = (e) => {
    if (!e.target.value) {
      e.target.setCustomValidity('This field is required')
    } else {
      if (!validator.isEmail(e.target.value)) {
        e.target.setCustomValidity("This email is not a valid");
      }
      else{
        e.target.setCustomValidity("");
      }
    }
  };


  return (
    <div className="staff-detail">
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bread-crumbs-mini"><img src={arrow} alt="arrow" onClick={() => props.history.goBack()}/><p>Профиль</p></div>
      <div className="page-title">Профиль</div>
      <div className="staff-user-detail">
        <div className="user">
          <div className="avatar">
            {props.admin && props.admin.avatar ?
            <img src={props.admin.avatar} alt="User"/> :
            <img src={avatar} alt="User"/>
            }
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="name">
              <p>{props.admin.first_name} {props.admin.last_name}</p>
            </div>
          </div>
        </div>
        <div className="user-info">
          <div className="subtitle">Информация</div>
          <div className="info d-flex justify-content-between">
            <div className="item">
              <div className="item-title">number</div>
              <div className="item-text">{props.admin.phone_number}</div>
            </div>
            <div className="item">
              <div className="item-title">mail</div>
              <div className="item-text">{props.admin.email}</div>
            </div>
          </div>
          <button onClick={openEditModal} type="button" className="edit-button"><img src={edit} alt="Edit"/>edit</button>
          <button onClick={openEditPasswordModal} type="button" className="edit-button ml-5"><img src={edit} alt="Edit"/>Update Password</button>
        </div>
      </div>
      {/*user edit modal*/}
      <Modal
          show={modalAddUserShow}
          onHide={() => setModalAddUserShow(false)}
          centered
          dialogClassName="add-user-modal"
      >
        <Modal.Body>
          <form className="add-user" onSubmit={(e) => userUpdate(e)}>
            <div className="user-avatar">
              <div className="avatar">
                {editUser && editUser.avatar ?
                    <img src={editUser.avatar} alt="User"/> :
                    <img src={userPhoto} alt="User"/>
                }
              </div>
              <label htmlFor="avatar" className="upload-file">
                <input type="file" id="avatar"
                       name="avatar"  onChange={(e) => handleChange(e)} className="d-none"/>
                добавить фото
              </label>
            </div>
            <div className="personal-data">
              <div className="title">Личные данные</div>
              <div className="form-group">
                <div className="form-block position-relative">
                  <label htmlFor="name">Имя</label>
                  <input name="last_name" value={editUser.last_name} onInput={(e) => e.target.setCustomValidity('')} onChange={(e) => setEditUser({...editUser, last_name:e.target.value})} onInvalid={(e) => e.target.setCustomValidity('This field is required')} required  type="text" id="name"/>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="number">Номер</label>
                  <input name="phone_number" value={editUser.phone_number} onInput={(e) => e.target.setCustomValidity('')} onChange={(e) => setEditUser({...editUser, phone_number:e.target.value})}  onInvalid={(e) => e.target.setCustomValidity('This field is required')} required  type="number" id="number"/>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="lastName">Фамилия</label>
                  <input name="first_name" value={editUser.first_name} onInput={(e) => e.target.setCustomValidity('')} onChange={(e) => setEditUser({...editUser, first_name:e.target.value})} onInvalid={(e) => e.target.setCustomValidity('This field is required')} required  type="text" id="lastName"/>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="email">Mail</label>
                  <input name="email"  value={editUser.email} onInvalid={(e) => validatorEmail(e)} required onChange={(e) => {validatorEmail(e);setEditUser({...editUser, email:e.target.value})}} type="text" id="email"/>
                </div>
              </div>
            </div>
            <button className={addButtonDisabled ? 'button-disabled submit' : 'submit'} disabled={addButtonDisabled}>Редактировать</button>
            <div className="close" onClick={() => setModalAddUserShow(false)}>
              <img src={close} alt="Close"/>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/*user password edit modal*/}
      <Modal
      show={modalPasswordShow}
      onHide={() => setModalPasswordShow(false)}
      centered
      dialogClassName="add-user-modal"
      >
        <Modal.Body>
          <form className="add-user" onSubmit={(e) => updatePassword(e)}>
            <div className="personal-data">
              <div className="title">обновление пароля</div>
              <div className="form-group">
                <div className="form-block position-relative">
                  <label htmlFor="name">Пароль</label>
                  <input name="password" value={password} onInput={(e) => e.target.setCustomValidity('')} onChange={(e) => setPassword(e.target.value)} onInvalid={(e) => e.target.setCustomValidity('This field is required')} required  type="password" id="name"/>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="number">Подтвердить Пароль</label>
                  <input name="confirm_password" value={confirmPassword} onInput={(e) => e.target.setCustomValidity('')} onChange={(e) => setConfirmPassword(e.target.value)}  onInvalid={(e) => e.target.setCustomValidity('This field is required')} required  type="password" id="number"/>
                </div>
              </div>
            </div>
            <button className={addButtonDisabled ? 'button-disabled submit' : 'submit'} disabled={addButtonDisabled}>Редактировать</button>
            <div className="close" onClick={() => setModalPasswordShow(false)}>
              <img src={close} alt="Close"/>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};


function mapStateToProps(state) {
  const { admin } = state.authReducer;
  const { user, error } = state.userReducer;
  return { user, error, admin };
}

const mapDispatchToProps = d => ({
  showUser: (params) => d(userActions.showUser(params)),
  updateUserInfo: (params) => d(userActions.updateUserInfo(params)),
  updateUserPassword: (params) => d(userActions.updateUserPassword(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetail);
