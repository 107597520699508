import {http} from "../../modules/http";

export const branchService = {
  getBranchAll,
  addBranch,
  editBranch,
  showBranch,
  deleteBranch
};

function getBranchAll() {
  return http.get('/branch').then(res => {
    return res
  })
}

function addBranch(data) {
  return http.post('/branch', data).then(res => {
    return res
  })
}

function editBranch(data) {
  return http.post('/update-branch', data).then(res => {
    return res.data
  })
}


function showBranch(data) {
  return http.get('/branch/' + data).then(res => {
    return res.data
  })
}

function deleteBranch(id) {
  return http.delete('/branch/' + id).then(res => {
    return res.data
  })
}

