import {bonusConstants} from '../constants/actionTypes';
import {bonusService} from '../services';

export const bonusActions = {
  getBonuses,
  bonusCreate,
  bonusUpdate,
  getBonusSchedule,
  getPaymentsType,
  getAllReports,
  deleteBonus
};

function getBonuses(data) {
  return dispatch => {
    dispatch(request());
    return bonusService.getBonus(data)
    .then(response => {
      dispatch(success(response.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response));
    })
  };

  function request() { return { type: bonusConstants.BONUS_REQUEST } }
  function success(bonuses) { return { type: bonusConstants.BONUS_SUCCESS, bonuses } }
  function failure(error) { return { type: bonusConstants.BONUS_FAILURE, error } }
}

function bonusCreate(data) {
  return dispatch => {
    dispatch(request());
    return bonusService.addBonus(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: bonusConstants.CREATE_BONUS_REQUEST } }
  function success(bonus) { return { type: bonusConstants.CREATE_BONUS_SUCCESS, bonus } }
  function failure(error) { return { type: bonusConstants.CREATE_BONUS_FAILURE, error } }
}

function bonusUpdate(data) {
  return dispatch => {
    dispatch(request());
    return bonusService.updateBonus(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: bonusConstants.UPDATE_BONUS_REQUEST } }
  function success(bonus) { return { type: bonusConstants.UPDATE_BONUS_SUCCESS, bonus } }
  function failure(error) { return { type: bonusConstants.UPDATE_BONUS_FAILURE, error } }
}

function deleteBonus(data) {
  return dispatch => {
    dispatch(request());
    return bonusService.bonusDelete(data)
      .then(response => {
        return dispatch(success(data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: bonusConstants.DELETE_BONUS_REQUEST } }
  function success(bonus) { return { type: bonusConstants.DELETE_BONUS_SUCCESS, bonus } }
  function failure(error) { return { type: bonusConstants.DELETE_BONUS_FAILURE, error } }
}

function getBonusSchedule(data) {
  return dispatch => {
    dispatch(request());
    return bonusService.bonusSchedule(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: bonusConstants.DELETE_BONUS_REQUEST } }
  function success(bonusSchedule) { return { type: bonusConstants.SCHEDULE_BONUS_SUCCESS, bonusSchedule } }
  function failure(error) { return { type: bonusConstants.DELETE_BONUS_FAILURE, error } }
}

function getAllReports(data) {
  return dispatch => {
    dispatch(request());
    return bonusService.allReports(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: bonusConstants.DELETE_BONUS_REQUEST } }
  function success(bonusSchedule) { return { type: bonusConstants.SCHEDULE_BONUS_SUCCESS, bonusSchedule } }
  function failure(error) { return { type: bonusConstants.DELETE_BONUS_FAILURE, error } }
}

function getPaymentsType() {
  return dispatch => {
    dispatch(request());
    return bonusService.allPaymentsType()
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: bonusConstants.PAYMENTS_TYPE_REQUEST } }
  function success(paymentsType) { return { type: bonusConstants.PAYMENTS_TYPE_SUCCESS, paymentsType } }
  function failure(error) { return { type: bonusConstants.PAYMENTS_TYPE_FAILURE, error } }
}


