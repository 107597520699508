import React, {useEffect, useRef, useState} from 'react'
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { DatePicker, Space } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import OutsideClickHandler from 'react-outside-click-handler';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const monthFormat = 'MM/YYYY';

const Calendar = ({ calendarFilter }) => {
  const [activeCalendar, setActiveCalendar] = useState('lasts');
  const [lastCalendarType, setLastCalendarType] = useState('last7days');
  const [last7days, setLast7days] = useState({});
  const [last30days, setLast30days] = useState({});
  const [last90days, setLast90days] = useState({});
  const [lastWeek, setLastWeek] = useState({});
  const [lastMonth, setLastMonth] = useState({});
  const [show, setShow] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState(moment(new Date(), 'YYYY-MM-DD'));
  const [calendar, setCalendar] = useState({startDay: '', endDay: ''});
  const [day, setDay] = useState({previousDay: '', start_date: '', end_date: '', days: '', months: '', start_period: '', end_period: ''});
  const target = useRef(null);

  useEffect(() => {
    setCalendarType('lasts')
    changeLastCalendarType('last7days')
  },  []);

  useEffect(() => {
    calendarFilter({calendar, day})
  },  [calendar]);

  const setCalendarType = (calendarType) => {
    setDatePickerValue(moment(new Date(), 'YYYY-MM-DD'));
    if (calendarType === 'weeks' || calendarType === 'lasts') {
      let day = new Date();
      let dayNumber = day.getDay();
      let calendarDay = {startDay: '', endDay: ''}
      calendarDay.startDay = moment(new Date(day)).add(-(dayNumber - 1), 'days').format('DD.MM.YYYY')
      calendarDay.endDay = moment(new Date(day)).add((7-dayNumber), 'days').format('DD.MM.YYYY')
      setCalendar(calendarDay)
      let dateDay = {
        start_date: moment(new Date(day)).add(-(dayNumber - 1), 'days').format('Y-M-D'),
        end_date: moment(new Date(day)).add((7-dayNumber), 'days').format('Y-M-D')
      }
      setDay(dateDay)
      if (calendarType === 'lasts') {
        changeLastCalendarType('last7days')
      }
    } else if (calendarType === 'days') {
      let calendarDay = {startDay: '', endDay: ''}
      calendarDay.startDay = moment(new Date()).format('DD.MM.YYYY')
      let dateDay = {
        days: moment(new Date()).format('Y-M-D'),
      }
      setDay(dateDay)
      setCalendar(calendarDay)
    } else if (calendarType === 'months') {
      let calendarDay = {startDay: '', endDay: ''}
      calendarDay.startDay = moment(new Date()).format('MM.YYYY')
      let dateDay = {
        months: moment(new Date()).format('Y-M-D'),
      }
      setDay(dateDay)
      setCalendar(calendarDay)
    } else if (calendarType === 'period') {
      let calendarDay = {
        startDay: moment(new Date()).format('DD.MM.YYYY'),
        endDay: moment(new Date()).format('DD.MM.YYYY')
      };
      let dateDay = {
        start_period: moment(new Date()).format('DD/MM/YYYY'),
        end_period: moment(new Date()).format('DD/MM/YYYY')
      }
      setDay(dateDay)
      setCalendar(calendarDay)
    }
    setActiveCalendar(calendarType)
  };

  const changeDate = (value, dateString, type) => {
    setDatePickerValue(value);
    if (type === 'week') {
      let day = new Date(value);
      let dayNumber = day.getDay() - 1;
      let calendarDay = {startDay: '', endDay: ''}
      calendarDay.startDay = moment(new Date(day)).add(-(dayNumber), 'days').format('DD.MM.YYYY')
      calendarDay.endDay = moment(new Date(day)).add((6-dayNumber), 'days').format('DD.MM.YYYY')
      setDay({
        start_date: moment(new Date(day)).add(-(dayNumber), 'days').format('Y-M-D'),
        end_date: moment(new Date(day)).add((6-dayNumber), 'days').format('Y-M-D')
      })
      setCalendar(calendarDay)
    } else if (type === 'days') {
      let calendarDay = {startDay: '', endDay: ''}
      calendarDay.startDay = moment(new Date(value)).format('DD.MM.YYYY')
      setDay({
        days: moment(new Date(value)).format('Y-M-D'),
      })
      setCalendar(calendarDay)
    } else if (type === 'months') {
      let calendarDay = {startDay: '', endDay: ''}
      calendarDay.startDay = moment(new Date(value)).format('MM.YYYY')
      setDay({
        months: moment(new Date(value)).format('Y-M-D'),
      })
      setCalendar(calendarDay)
    } else if (type === 'period') {
      let calendarDay = {
        startDay: dateString[0],
        endDay: dateString[1]
      }
      setDay({
        start_period: dateString[0],
        end_period: dateString[1]
      })
      setCalendar(calendarDay)
    }
  };
  const changeLastCalendarType = (type) => {
    moment.locale('ru')
    let day = new Date();
    let dayNumber = day.getDay();

    let calendar7days = {startDay: '', endDay: ''}
    calendar7days.startDay = moment(new Date(day)).add(-6, 'days').format('DD MMM')
    calendar7days.endDay = moment(new Date(day)).format('DD MMM')
    setLast7days(calendar7days)

    let calendar30days = {startDay: '', endDay: ''}
    calendar30days.startDay = moment(new Date(day)).add(-30, 'days').format('DD MMM')
    calendar30days.endDay = moment(new Date(day)).format('DD MMM')
    setLast30days(calendar30days)

    let calendar90days = {startDay: '', endDay: ''}
    calendar90days.startDay = moment(new Date(day)).add(-90, 'days').format('DD MMM')
    calendar90days.endDay = moment(new Date(day)).format('DD MMM')
    setLast90days(calendar90days)

    let calendarWeekDay = {startDay: '', endDay: ''}
    calendarWeekDay.startDay = moment(new Date(day)).add(-(dayNumber - 1), 'days').format('DD MMM')
    calendarWeekDay.endDay = moment(new Date(day)).add((7-dayNumber), 'days').format('DD MMM')
    setLastWeek(calendarWeekDay)

    let calendarMonthDay = {startDay: '', endDay: ''}
    calendarMonthDay.startDay = moment(new Date()).format('DD MMM')
    setLastMonth(calendarMonthDay)

    let newDay = {};
    if (type === 'last7days') {
      setCalendar(calendar7days)
      newDay = {previousDay: '6'}
    } else if (type === 'last30days') {
      setCalendar(calendar30days)
      newDay = {previousDay: '30'}
    } else if (type === 'last90days') {
      setCalendar(calendar90days)
      newDay = {previousDay: '90'}
    } else if (type === 'lastWeek') {
      newDay = {start_date: moment(new Date(day)).add(-(dayNumber - 1), 'days').format('Y-M-D'), end_date: moment(new Date(day)).add((7-dayNumber), 'days').format('Y-M-D')}
      setCalendar(calendarWeekDay)
    } else if (type === 'lastMonth') {
      newDay = {start_date: moment(new Date()).format('Y-M-D')}
      setCalendar(calendarMonthDay)
    }
    setDay(newDay)
    setLastCalendarType(type)
  };

  return (
    <div>
      <div className="filter">
        Choose Date
        <div className="date" ref={target} onClick={() => setShow(!show)}>
          <button type="button">
            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7997 1.10636C12.4281 0.51287 11.4331 -0.426818 10.8047 0.216126L6.19638 4.51891C5.93454 4.7662 5.93454 5.21131 6.19638 5.4586L10.8047 9.81084C11.4331 10.4043 12.4281 9.46464 11.7997 8.87115L7.71504 5.01348L11.7997 1.10636Z" fill="#ffffff"/>
              <path d="M5.79971 8.89364C6.42812 9.48713 5.43313 10.4268 4.80472 9.78387L0.196379 5.48109C-0.0654586 5.2338 -0.0654585 4.78869 0.196379 4.5414L4.80473 0.189161C5.43314 -0.404326 6.42812 0.535362 5.79971 1.12885L1.71504 4.98652L5.79971 8.89364Z" fill="#ffffff"/>
            </svg>
          </button>
          <div>
            {calendar.startDay ? calendar.startDay : ''}
            {calendar.endDay ? ' - ' + calendar.endDay : ''}
          </div>
          <button type="button">
            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.200293 1.10636C-0.428118 0.51287 0.566865 -0.426818 1.19528 0.216126L5.80362 4.51891C6.06546 4.7662 6.06546 5.21131 5.80362 5.4586L1.19528 9.81084C0.566865 10.4043 -0.428118 9.46464 0.200293 8.87115L4.28496 5.01348L0.200293 1.10636Z" fill="#ffffff"/>
              <path d="M6.20029 1.10636C5.57188 0.51287 6.56687 -0.426818 7.19528 0.216126L11.8036 4.51891C12.0655 4.7662 12.0655 5.21131 11.8036 5.4586L7.19528 9.81084C6.56687 10.4043 5.57188 9.46464 6.20029 8.87115L10.285 5.01348L6.20029 1.10636Z" fill="#ffffff"/>
            </svg>
          </button>
        </div>
      </div>
      <Overlay target={target.current} show={show} placement="bottom">
        {(props) => (
        <OutsideClickHandler
        onOutsideClick={() => {
          setShow(false)
        }}
        >
          <Tooltip id="calendar-tooltip" className="calendar-tooltip" {...props}>
            <div className="button-group">
              <button type="button" className={activeCalendar === 'lasts' ? 'active' : ''} onClick={() => setCalendarType('lasts')}>Пресе...</button>
              <button type="button" className={activeCalendar === 'days' ? 'active' : ''} onClick={() => setCalendarType('days')}>Дни</button>
              <button type="button" className={activeCalendar === 'weeks' ? 'active' : ''} onClick={() => setCalendarType('weeks')}>Недели</button>
              <button type="button" className={activeCalendar === 'months' ? 'active' : ''} onClick={() => setCalendarType('months')}>Месяцы</button>
              <button type="button" className={activeCalendar === 'period' ? 'active' : ''} onClick={() => setCalendarType('period')}>Период</button>
            </div>
            <div className="calendar">
              {
                activeCalendar === 'lasts' ?
                <div className="lasts-calendar">
                  <div className={lastCalendarType === 'last7days' ? 'last-active' : ''} onClick={() => changeLastCalendarType('last7days')}>
                    <p>Последние 7 дней</p>
                    <span>{last7days.startDay} - {last7days.endDay}</span>
                  </div>
                  <div className={lastCalendarType === 'last30days' ? 'last-active' : ''} onClick={() => changeLastCalendarType('last30days')}>
                    <p>Последние 30 дней</p>
                    <span>{last30days.startDay} - {last30days.endDay}</span>
                  </div>
                  <div className={lastCalendarType === 'last90days' ? 'last-active' : ''} onClick={() => changeLastCalendarType('last90days')}>
                    <p>Последние 90 дней</p>
                    <span>{last90days.startDay} - {last90days.endDay}</span>
                  </div>
                  <div className={lastCalendarType === 'lastWeek' ? 'last-active' : ''} onClick={() => changeLastCalendarType('lastWeek')}>
                    <p>Последняя неделя</p>
                    <span>{lastWeek.startDay} - {lastWeek.endDay}</span>
                  </div>
                  <div className={lastCalendarType === 'lastMonth' ? 'last-active' : ''} onClick={() => changeLastCalendarType('lastMonth')}>
                    <p>Последний месяц</p>
                    <span>{lastMonth.startDay}</span>
                  </div>
                </div> :
                <Space direction="vertical">
                  {activeCalendar === 'weeks' ?
                  <DatePicker locale={locale} value={datePickerValue} onChange={(value, dateString) => changeDate(value, dateString, 'week')} format={dateFormat} picker="week" getPopupContainer={trigger => trigger.parentElement} open={true} style={{visibility: 'hidden', height: 0}}/> :
                  activeCalendar === 'months' ?
                  <DatePicker locale={locale} value={datePickerValue} onChange={(value, dateString) => changeDate(value, dateString, 'months')} format={monthFormat} picker="month" getPopupContainer={trigger => trigger.parentElement} open={true} style={{visibility: 'hidden', height: 0}}/> :
                  activeCalendar === 'days' ?
                  <DatePicker locale={locale} value={datePickerValue} onChange={(value, dateString) => changeDate(value, dateString, 'days')} format={dateFormat} getPopupContainer={trigger => trigger.parentElement} open={true} style={{visibility: 'hidden', height: 0}}/> :
                  activeCalendar === 'period' ?
                  <RangePicker locale={locale} value={datePickerValue} onChange={(value, dateString) => changeDate(value, dateString, 'period')} format={dateFormat} getPopupContainer={trigger => trigger.parentElement} open={true} style={{marginTop: '10px'}}/> : ''
                  }
                </Space>
              }
            </div>
          </Tooltip>
        </OutsideClickHandler>

        )}
      </Overlay>

    </div>
)
}

export default Calendar;
