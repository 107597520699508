import { performanceConstants } from '../constants/actionTypes';

const initialState = {
  performances: {},
  pagination: {},
};

export function performanceReducer(state = initialState, action) {
  switch (action.type) {
    case performanceConstants.PERFORMANCE_REQUEST:
      return {
        ...state,
        performances: {},
      };
    case performanceConstants.PERFORMANCE_SUCCESS:
      return {
        ...state,
        performances: action.performance.data,
        pagination: action.performance.pagination,
      };
    case performanceConstants.PERFORMANCE_FAILURE:
      return {
        ...state,
        performances: {},
      };

    default:
      return state;
  }
}
