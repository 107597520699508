import React, {useEffect, useState} from "react";
import close from "../../../assets/image/icons/close.svg";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import { authActions } from "../../../redux/actions/authActions";
import validator from 'validator';
import BeatLoader from "react-spinners/BeatLoader";

const Login = (props) => {

  const [form, setForm] = useState({remember: false});
  const [errorMsg, setErrorMsg] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setErrorMsg('')
    let formData = form ;
    let newFormInput = {};
    const name =  e.target.name;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    newFormInput[name] = value;
    setForm({...formData, ...newFormInput});
  };

  useEffect(() => {    if (form.email || form.password) {
      if (form.email !== undefined) {
        if (form.email) {
          if (!validator.isEmail(form.email)) {
            setErrorEmail('This email is not a valid.')
          } else {
            setErrorEmail('')
          }
        } else {
          setErrorEmail('This email is required')
        }
      }
      if (form.password !== undefined) {
        if (form.password) {
          if (form.password.toString().trim().length < 6) {
            setErrorPassword('The value must be more than 6 characters.')
          } else {
            setErrorPassword('')
          }
        } else {
          setErrorPassword('This password is required')
        }
      }
    } else {
      setErrorPassword(false)
      setErrorEmail(false)
    }
  },  [form.email, form.password]);


  const login = async (e) => {
    e.preventDefault()
    if (form.email) {
      if (!validator.isEmail(form.email)) {
        setErrorEmail('This email is not a valid.')
      } else {
        setErrorEmail('')
      }
    } else {
      setErrorEmail('This email is required')
    }
    if (form.password) {
      if (form.password.toString().trim().length < 6) {
        setErrorPassword('The value must be more than 6 characters.')
      }  else {
        setErrorPassword('')
      }
    } else {
      setErrorPassword('This password is required')
    }
    if (errorEmail === '' && errorPassword === '') {
      const formData = form;
      if(formData) {
        setLoading(true)
        await props.login(formData).then((res) => {
          if (res.status === 200) {
            props.getUserMe().then(() => {
              window.location.href = '/staff?tab=3'
            })
          }
        }).finally(() => {
          setTimeout(() => {
            setLoading(false)
          }, 500)
        })
      }
    }
  }

  useEffect(() => {
    if (props.error) {
      if (props.error.status === 422) {
        const data = props.error.data
        const keys = Object.keys(data)
        setErrorMsg(data[keys[0]][0])
      } else {
        setErrorMsg(props.error.data.message)
      }
    }
  },  [props.error]);




  return (
  <div>
    {loading ?
    <div className="loading-block">
      <BeatLoader color="#0A65FF" loading={loading} size={20}/>
    </div> : null
    }
    <form className="login" onSubmit={e => login(e)}>
      <div className="login-block">
        <div className="login-title">Login</div>
        <div className="login-form">
          <div className="form-block">
            <label htmlFor="email">MAIL</label>
            <input type="text" name='email'  onChange={handleChange}  id="email"/>
          </div>
          <span className="text-danger position-absolute">{errorEmail ? errorEmail : ''}</span>
          <div className="d-flex mt-3">
            <div className="form-block">
              <label htmlFor="password">PASSWORD</label>
              <input type="password" name='password'  onChange={handleChange}  id="password"/>
            </div>
            <button type="submit" className="login-button">LOGIN</button>
          </div>
          {errorPassword ?
          <span className="text-danger position-absolute">{errorPassword ? errorPassword : ''}</span> :
          <span className="text-danger position-absolute">{errorMsg ? errorMsg : ''}</span>
          }
          <div className="form-block-checkbox mt-4">
            <input type="checkbox" name='remember' onChange={handleChange}  id="checkbox"/>
            <label htmlFor="checkbox">Remember this device</label>
          </div>
          <button type="submit" className="login-button min-screen">LOGIN</button>
          <Link to="/forgot-password" className="forgot-password">I forgot my password I want to restore it</Link>
        </div>
        <div className="close">
          <img src={close} alt="Close"/>
        </div>
      </div>
    </form>
  </div>
  );
};


function mapStateToProps(state) {
  const { error } = state.authReducer;
  return { error };
}


const mapDispatchToProps = d => ({
  login: (data) => d(authActions.login(data)),
  getUserMe: () => d(authActions.getUserMe()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

