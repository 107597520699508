import {roleConstants} from '../constants/actionTypes';
import {roleService} from '../services';

export const roleActions = {
  getRolesList,
  roleCreate,
  roleUpdate,
  deleteRole,
  getRoleTypes
};

function getRolesList(data) {
  return dispatch => {
    dispatch(request());
    return roleService.getRolesList(data)
    .then(response => {
      dispatch(success(response.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response));
    })
  };

  function request() { return { type: roleConstants.GET_ROLES_LIST_REQUEST } }
  function success(roles) { return { type: roleConstants.GET_ROLES_LIST_SUCCESS, roles } }
  function failure(error) { return { type: roleConstants.GET_ROLES_LIST_FAILURE, error } }
}

function roleCreate(data) {
  return dispatch => {
    dispatch(request());
    return roleService.addRole(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: roleConstants.CREATE_ROLE_REQUEST } }
  function success(role) { return { type: roleConstants.CREATE_ROLE_SUCCESS, role } }
  function failure(error) { return { type: roleConstants.CREATE_ROLE_FAILURE, error } }
}

function roleUpdate(data) {
  return dispatch => {
    dispatch(request());
    return roleService.updateRole(data)
      .then(response => {
        return dispatch(success(response.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: roleConstants.UPDATE_ROLE_REQUEST } }
  function success(role) { return { type: roleConstants.UPDATE_ROLE_SUCCESS, role } }
  function failure(error) { return { type: roleConstants.UPDATE_ROLE_FAILURE, error } }
}

function deleteRole(data) {
  return dispatch => {
    dispatch(request());
    return roleService.deleteRole(data)
      .then(() => {
        return dispatch(success(data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: roleConstants.DELETE_ROLE_REQUEST } }
  function success(role) { return { type: roleConstants.DELETE_ROLE_SUCCESS, role } }
  function failure(error) { return { type: roleConstants.DELETE_ROLE_FAILURE, error } }
}

function getRoleTypes() {
  return dispatch => {
    dispatch(request());
    return roleService.getRoleTypes()
      .then(response => {
        return dispatch(success(response.data.data));
      })
      .catch(function (error) {
        dispatch(failure(error.response));
      })
  };

  function request() { return { type: roleConstants.GET_ROLE_TYPE_REQUEST } }
  function success(roleTypes) { return { type: roleConstants.GET_ROLE_TYPE_SUCCESS, roleTypes } }
  function failure(error) { return { type: roleConstants.GET_ROLE_TYPE_FAILURE, error } }
}


