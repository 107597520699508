import { userConstants } from '../constants/actionTypes';


const initialState = {
  users: [],
  pagination: '',
  user: {},
  history: [],
  userRole: {}
};

export function userReducer(state = initialState, action) {
    switch (action.type) {
    case userConstants.GET_USER_REQUEST:
      return {
        ...state,
        users: [],
      };
    case userConstants.UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.user.data,
      };
    case userConstants.GET_USER_SUCCESS:
      return {
        ...state,
        users: action.user.data,
        pagination: action.user.pagination,
      };
    case userConstants.GET_USER_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.history.data,
        pagination: action.history.pagination,
      };
    case userConstants.GET_USER_FAILURE:
          return {
              ...state,
              users: {},
          };
    case userConstants.GET_ALL_USER_REQUEST:
      return {
        ...state,
        allUsersList: [],
      };
    case userConstants.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        allUsersList: action.allUsers.data,
      };
    case userConstants.GET_ALL_USER_FAILURE:
      return {
        ...state,
        allUsersList: [],
      };
    case userConstants.CREATE_USER_SUCCESS:
      let usersList = [...state.users];
      if (action.user.data.role_id == 1) {
        usersList = [...state.users]
      } else if (action.role_id == 3 && action.user.data.array_role_id.includes(3) || action.role_id == 4 && action.user.data.array_role_id.includes(4) || action.role_id == 2) {
        usersList = [...state.users]
        usersList.unshift(action.user.data)
      }
      return {
        ...state,
        users: usersList,
      };
    case userConstants.CREATE_USER_FAILURE:
      return {...state};
    case userConstants.UPDATE_USER_SUCCESS:
      if (!action.role_id) {
        return {
          ...state,
          user: action.user.data,
        };
      } else {
        let usersListData = [...state.users];
        usersListData = usersListData.map(item => {
          if (action.user.data.id === item.id) {
            item = action.user.data
          }
          return item
        });
        if (action.role_id == 3 && !action.array_role_id.includes(3) || action.role_id == 4 && !action.array_role_id.includes(4) || action.role_id == 2 && !action.array_role_id.includes(2)) {
          usersListData = usersListData.filter(i => action.user.data.id !== i.id)
        }
        return {
          ...state,
          users: usersListData,
        };
      }
    case userConstants.SHOW_USER_SUCCESS:
          return {
              ...state,
              user: action.user,
          };
    case userConstants.SHOW_USER_REQUEST:
         return {
             ...state,
             user: '',
         };
    case userConstants.USER_ROLE_SUCCESS:
        return {
        ...state,
        userRole: action.data,
      };
    case userConstants.REMOVE_FILE_USER_SUCCESS:
      let files = [...state.user.user_file];
        files = files.filter(i => action.id !== i.id)
      return { ...state, user: {...state.user, user_file: files}}

    case userConstants.REMOVE_USERS_SUCCESS:
      let users = [...state.users];
      users = users.filter(i =>  !action.id.id.includes(i.id))
      return { ...state, users: users}

    case userConstants.ARCHIVE_USERS_SUCCESS:
      let usersArchive = [...state.users];
      usersArchive = usersArchive.filter(i =>  !action.id.id.includes(i.id))
      return { ...state, users: usersArchive}
    case userConstants.FILE_USER_SUCCESS:
      let array = [...state.user.user_file];
      array.push(action.file)
      return { ...state, user: {...state.user, user_file: array}}
    default:
      return state;
  }
}
