import React, { useEffect, useState } from "react";
import arrow from "../../../assets/image/icons/arrow.svg";
import avatar from "../../../assets/image/default-avatar.png";
import show from "../../../assets/image/icons/show.svg";
import edit from "../../../assets/image/icons/edit.svg";
import upload from "../../../assets/image/icons/upload.svg";
import zoom from "../../../assets/image/icons/zoom.svg";
import x from "../../../assets/image/icons/x.svg";
import doc from "../../../assets/image/icons/doc.png";
import pdf from "../../../assets/image/icons/pdf.png";
import xls from "../../../assets/image/icons/xls.png";
import deleteIcon from "../../../assets/image/icons/delete.svg";
import block from "../../../assets/image/icons/block.svg";
import close from "../../../assets/image/icons/close.svg";
import userPhoto from "../../../assets/image/icons/user-photo.svg";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {accountActions, userActions} from "../../../redux/actions";
import { connect } from "react-redux";
import validator from "validator";
import {toast, ToastContainer} from "react-toastify";
import archive from "../../../assets/image/icons/archive.svg";
import removeArchive from "../../../assets/image/icons/remove-archive.svg";
import MultiSelect from "react-multi-select-component";
import includes from "validator/es/lib/util/includes";
import {array} from "prop-types";



const StaffDetail = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalBlockShow, setModalBlockShow] = useState(false);
  const [modalArchiveShow, setModalArchiveShow] = useState(false);
  const [modalAddUserShow, setModalAddUserShow] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [userArchive, setUserArchive] = useState(0);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [optionsSelect, setOptionsSelect] = useState([]);
  const [workerTypeSelect, setWorkerTypeSelect] = useState([]);
  const [workerTypes, setWorkerTypes] = useState([]);
  const [roles, setRoles] = useState([]);
  const [account, setAccount] = useState([]);
  const [updateWorkerType, setUpdateWorkerType] = useState(false);


  useEffect(() => {
    props.showUser(props.match.params.id).then(() => {
      setUserArchive(props.user.in_archive)
    })
  },  []);

  const openEditModal = () => {
    setRoles([]);
    props.showUser(props.match.params.id).then(() => {
      let workerTypesData = props.user.worker_types.map(item => {
        return {label: item.type_name, value: item.id, rate_per_hour: item.rate_per_hour, tipping_percentage: item.pivot?.tipping_percentage, disabled: false}
      });
      props.user.workerTypes = workerTypesData
      setEditUser(props.user)
      setModalAddUserShow(true)
      props.getUserRole().then((response) => {
        if (response.data && response.data.length) {
          let userRolesList = [...response.data]
          let editRoles = [...roles]
          userRolesList.map((item) => {
            if (props.user.array_role_id.includes(item.id)) {
              if (editRoles && editRoles.length) {
                editRoles.map((i) => {
                  if (i.value !== item.id) {
                    editRoles.push({label: item.role_name, value: item.id, disabled: false})
                  }
                })
              } else {
                editRoles.push({label: item.role_name, value: item.id, disabled: false})
              }
            }
          })
          setRoles(editRoles)
          // let workerTypesData = props.user.worker_types.map(item => {
          //   return {label: item.type_name, value: item.id, rate_per_hour: item.rate_per_hour, tipping_percentage: item.pivot?.tipping_percentage, disabled: false}
          // });
          setUpdateWorkerType(true)
          // setEditUser({...editUserData, workerTypes: workerTypesData})
          setWorkerTypes(workerTypesData);
        }
      })
      props.getAccounts().finally(() => {
        if (props.user.account) {
          setAccount(props.user.account.id)
        }
      });
    })
  };

  useEffect(() => {
    // debugger
    rolesSelect()
  } , [roles]);

  const rolesSelect = () => {
    let rolesArr = [...roles];
    let optionsArr = [...optionsSelect];
    if (rolesArr && rolesArr.length) {
      if ((rolesArr[0].value !== 3 && rolesArr[0].value !== 4) && rolesArr.length > 1) {
        rolesArr = rolesArr.filter((item, index) => index !== 0)
        setRoles(rolesArr)
        setWorkerTypes([])
      }


      for(let i = 0; i < rolesArr.length; i++) {
        if (rolesArr[i].value === 3 || rolesArr[i].value === 4) {
          optionsArr = optionsArr.map(item => {
            if (item.value === 3 || item.value === 4) {
              item.disabled = false
            } else {
              item.disabled = true
            }
            return item
          })
        } else {
          optionsArr = []
          props.userRole.data.map((item) => {
            optionsArr.push({label: item.role_name, value: item.id, disabled: false})
          })
        }
      }
    } else {
      setWorkerTypes([])
      optionsArr = optionsArr.map(item => {
        item.disabled = false
        return item
      })
    }

    if (rolesArr && rolesArr.length) {
      setWorkerTypeSelect([])
      let form = editUser;
      form.role_id = rolesArr.map(item => {
        return item.value
      });
      setEditUser(form);
      if (editUser.role_id.includes(3) || editUser.role_id.includes(4)) {
        let workerArr = []
        editUser.role_id.map(item => {
          props.userRole.data[item - 1].worker_types.map(type => {
            workerArr.push({label: type.type_name, value: type.id, rate_per_hour: type.rate_per_hour, tipping_percentage: item.pivot?.tipping_percentage, disabled: false})
          });
        })
        setWorkerTypeSelect(workerArr)
        if (roles.length < 2 && !updateWorkerType) {
          setWorkerTypes([])
        } else {
          setUpdateWorkerType(false)
        }
      }

    }
    setOptionsSelect(optionsArr)
  }

  const hideAddModal = () => {
    setModalAddUserShow(false)
    setEditUser({})
  }

  const handleChange = (e) => {
    const name =  e.target.name;
    if (name === 'avatar') {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (event) => {
      setEditUser({...editUser, avatar: event.target.result})
      };
      reader.readAsDataURL(file)
    }
  };

  useEffect(() => {
    if (props.error) {
      if (props.error.status === 422) {
        const data = props.error.data
        const keys = Object.keys(data)
        setErrorMsg(data[keys[0]][0])
      } else {
        setErrorMsg(props.error.data)
      }
    }
  },  [props.error]);

  useEffect(() => {
    let label = []
    if (props.userRole.data && props.userRole.data.length) {
      props.userRole.data.map((item) => {
        label.push({label: item.role_name, value: item.id, disabled: false})
      })
      setOptionsSelect(label)
    }
  } , [props.userRole])

  const changeSalary = (category,e) => {
    let salary = 0
    if (category === 'salary_percent') {
      salary = +editUser.cash_percent + +editUser.retention_with_salary + +editUser.check + +editUser.check_mdm + +e.target.value
    } else if (category === 'cash_percent') {
      salary = +editUser.salary_percent + +editUser.retention_with_salary + +editUser.check + +editUser.check_mdm + +e.target.value
    } else if (category === 'retention_with_salary') {
      salary = +editUser.cash_percent + +editUser.salary_percent + +editUser.check + +editUser.check_mdm + +e.target.value
    } else if (category === 'check') {
      salary = +editUser.cash_percent + +editUser.retention_with_salary + +editUser.salary_percent + +editUser.check_mdm + +e.target.value
    } else if (category === 'check_mdm') {
      salary = +editUser.cash_percent + +editUser.retention_with_salary + +editUser.check + +editUser.salary_percent + +e.target.value
    }


    const re = /^[0-9\b]+$/;
    let item = document.getElementById(category + '_message')

    if (salary <= 100) {
      if (e.target.value === '' || re.test(e.target.value)) {
        setEditUser({...editUser, [category]:e.target.value})
        item.innerText = ''
      }
    } else {
      item.innerText = 'percentage should not be more than 100'
    }
  }


  const userUpdate = (e) => {
    e.preventDefault()
    setAddButtonDisabled(true)
    let formData = new FormData(e.currentTarget)
    if (!editUser.avatar) {
      formData.delete('avatar')
    }
    if (account) {
      formData.append('account_id', account)
    }
    editUser.role_id.map((item, index) => {
      if (editUser.role_id.includes(item)) {
        formData.append('role_id[' + index +']', item)
      }
    });
    if (account) {
      formData.append('account_id', account)
    }
    if (workerTypes && workerTypes.length) {
      workerTypes.map((item, index) => {
        formData.append('worker_type_id[' + index +']', item.value)
      });
    }
    if (editUser.no_salary) {
      formData.delete('salary_percent')
    }
     formData.append('id', editUser.id)
     props.updateUserData(formData, '', '').then(res => {
       if (res.type === 'UPDATE_USER_SUCCESS') {
         hideAddModal()
         toast.success('Пользователь обновлён');
       } else {
         toast.error(errorMsg);
       }
     }).finally(() => {
       setAddButtonDisabled(false)
     })
  };

  const validatorEmail = (e) => {
    if (!e.target.value) {
      e.target.setCustomValidity('This field is required')
    } else {
      if (!validator.isEmail(e.target.value)) {
        e.target.setCustomValidity("This email is not a valid");
      }
      else{
        e.target.setCustomValidity("");
      }
    }
  };

  const usersArchive = () => {
    props.archiveUsers({ id: [props.match.params.id]}).then(res => {
      toast.success(userArchive ? 'Сотрудник удален из Архива' : 'Сотрудник добавлен в Архив');
      setModalArchiveShow(false)
      setUserArchive(userArchive ? 0 : 1)
    })
  }

  const uploadFile = (event) => {
    let file = event.target.files;
    const formData = new FormData()
    formData.append('file', file[0])
    formData.append('id', props.user.id)
    props.uploadImage(formData).then(res => {
      if (res.type === 'FILE_USER_SUCCESS') {
        toast.success('Сотрудник добавлен');
      } else {
        const data = res.error.data
        const keys = Object.keys(data)
        toast.error(data[keys[0]][0]);
      }
    })
  }

  const changeWorkerTypeSalary = (e, index) => {
    const workerType = editUser.workerTypes.map((item, i) => {
      if (i === index) {
        item.tipping_percentage = e.target.value
      }
      return item
    })
    setEditUser({...editUser,  workerTypes: workerType})
  }

  const removeImage = (id) => {
    props.deleteImage(id)
  }

  const deleteUserThen = () => {
    setModalShow(false)
    props.history.goBack()
  }

  const removeUser = (id) => {
    props.deleteUser(id, deleteUserThen)
  }

  const blockedUser = (id) => {
    props.blockUser(id)
  }

  return (
    <div className="staff-detail">
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div onClick={() => props.history.goBack()} className="bread-crumbs"><img src={arrow} alt="arrow"/><p>Назад</p></div>
      <div className="bread-crumbs-mini"><img src={arrow} alt="arrow" onClick={() => props.history.goBack()}/><p>Страница сотрудника</p></div>
      <div className="page-title">Страница сотрудника</div>
      <div className="staff-user-detail">
        <div className="user">
          <div className="avatar">
            {props.user && props.user.avatar ?
            <img src={props.user.avatar} alt="User"/> :
            <img src={avatar} alt="User"/>
            }
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="name">
              <p>{props.user.first_name} {props.user.last_name}</p>
              <span>{props.user && props.user.role ? props.user.role.role_name : ''}</span>
            </div>
            <Link to={'/staff/history/' + props.user.id}><img src={show} alt="Show"/>show history</Link>
          </div>
        </div>
        <div className="user-info">
          <div className="subtitle">Информация</div>
          <div className="info d-flex justify-content-between">
            <div className="item">
              <div className="item-title">number</div>
              <div className="item-text">{props.user.phone_number}</div>
            </div>
            <div className="item">
              <div className="item-title">mail</div>
              <div className="item-text">{props.user.email}</div>
            </div>
            <div className="item">
              <div className="item-title">rate per hour</div>
              <div className="item-text">{props.user.rate_per_hour}</div>
            </div>
          </div>
          {props.admin && props.admin.role_id === 1 ?
            <button onClick={openEditModal} type="button" className="edit-button"><img src={edit} alt="Edit"/>edit</button> : null
          }
        </div>
        <div className="user-info">
          <div className="subtitle">Файлы</div>
          <div className="files">
            {props.user && props.user.user_file && props.user.user_file.length ?
              props.user.user_file.map((i , index) => {
                return (
                <div className="file" key={index} >
                  {i.file_type === 'docx' || i.file_type === 'doc' ?
                      <img src={doc} alt="" className="file-image"/> :
                        i.file_type === 'pdf' ?
                      <img src={pdf} alt="" className="file-image"/> :
                        i.file_type === 'xlsx' || i.file_type === 'xls' ?
                      <img src={xls} alt="" className="file-image"/> :
                      <img src={i.file} alt="" className="file-image"/>
                  }

                  <div className="zoom">
                    <img src={zoom} alt="Zoom" onClick={() => window.open(i.file)} className="mr-1"/>
                    <img src={x} onClick={() =>  removeImage(i.id)} alt="X"/>
                  </div>
                </div>
                )
              }) : ''
            }
          </div>
          {props.admin && props.admin.role_id === 1 ?
            <label htmlFor="file" className="edit-button">
              <input type="file" name='file' onChange={uploadFile} accept=".xlsx,.xls,image/*,.doc, .docx,.pdf" id="file" className="d-none"/>
              <img src={upload} alt="Upload"/>upload a file
            </label> : null
          }
        </div>
        <div className="bottom-buttons">
          {props.admin && props.admin.role_id === 1 ?
            <div className="d-flex">
              <button type="button" onClick={() => setModalShow(props.user.id)}><img src={deleteIcon} alt="Delete"/>delete</button>
              <button type="button" onClick={() => setModalBlockShow(props.user.id)}><img src={block} alt="Block"/>block</button>
            </div> :
            (userArchive ?
              <button className="remove-archive" type="button" onClick={() => setModalArchiveShow(props.user.id)}><img src={removeArchive} alt="Archive"/>remove from archive</button> :
              <button type="button" onClick={() => setModalArchiveShow(props.user.id)}><img src={archive} alt="Archive"/>archive</button>
            )

          }
        </div>
      </div>
      {/*user delete*/}
      <Modal
        show={modalShow}
        onHide={hideAddModal}
        centered
        dialogClassName="user-delete-modal"
      >
        <Modal.Body>
          <div className="title">Вы действитльно хотите удалить сотрудника?</div>
          <div className="text">Пользователь удален</div>
          <button onClick={() => removeUser(modalShow)} type="button">удалить</button>
          <div className="close" onClick={() => setModalShow(false)}>
            <img src={close} alt="Close"/>
          </div>
        </Modal.Body>
      </Modal>
      {/*user block */}
      <Modal
        show={modalBlockShow}
        onHide={() => setModalBlockShow(false)}
        centered
        dialogClassName="user-delete-modal"
      >
        <Modal.Body>
          <div className="title">Вы действитльно хотите заблокировать сотрудника?</div>
          <div className="text">Пользователь будет навсегда удален</div>
          <button onClick={() => blockedUser(modalBlockShow)} type="button">заблокировать</button>
          <div className="close" onClick={() => setModalBlockShow(false)}>
            <img src={close} alt="Close"/>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalArchiveShow}
        onHide={() => setModalArchiveShow(false)}
        centered
        dialogClassName="user-delete-modal"
      >
        <Modal.Body>
          <div className="title">{userArchive ? 'Вы действитльно хотите удалить сотрудника из архива?' : 'Вы действитльно хотите переместить сотрудника в архив?'}</div>
          <div className="text">{userArchive ? 'Пользователь будет удален из архива' : 'Пользователь будет перемещен в архив'}</div>
          <button onClick={() => usersArchive()} type="button">{userArchive ? 'удалить' : 'архив'}</button>
          <div className="close" onClick={() => setModalArchiveShow(false)}>
            <img src={close} alt="Close"/>
          </div>
        </Modal.Body>
      </Modal>
      {/*user edit modal*/}
      <Modal
          show={modalAddUserShow}
          onHide={() => setModalAddUserShow(false)}
          centered
          dialogClassName="add-user-modal"
      >
        <Modal.Body>
          <form className="add-user" onSubmit={(e) => userUpdate(e)}>
            <div className="user-avatar">
              <div className="avatar">
                {editUser && editUser.avatar ?
                    <img src={editUser.avatar} alt="User"/> :
                    <img src={userPhoto} alt="User"/>
                }
              </div>
              <label htmlFor="avatar" className="upload-file">
                <input type="file" id="avatar"
                       name="avatar"  onChange={(e) => handleChange(e)} className="d-none"/>
                добавить фото
              </label>
            </div>
            <div className="personal-data">
              <div className="title">Личные данные</div>
              <div className="form-group">
                <div className="form-block position-relative">
                  <label htmlFor="name">Имя</label>
                  <input name="last_name" value={editUser.last_name} onInput={(e) => e.target.setCustomValidity('')} onChange={(e) => setEditUser({...editUser, last_name:e.target.value})} onInvalid={(e) => e.target.setCustomValidity('This field is required')} required  type="text" id="name"/>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="number">Номер</label>
                  <input name="phone_number" value={editUser.phone_number} onInput={(e) => e.target.setCustomValidity('')} onChange={(e) => setEditUser({...editUser, phone_number:e.target.value})}  onInvalid={(e) => e.target.setCustomValidity('This field is required')} required  type="number" id="number"/>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="lastName">Фамилия</label>
                  <input name="first_name" value={editUser.first_name} onInput={(e) => e.target.setCustomValidity('')} onChange={(e) => setEditUser({...editUser, first_name:e.target.value})} onInvalid={(e) => e.target.setCustomValidity('This field is required')} required  type="text" id="lastName"/>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="email">Mail</label>
                  <input name="email"  value={editUser.email} onInvalid={(e) => validatorEmail(e)} required onChange={(e) => {validatorEmail(e);setEditUser({...editUser, email:e.target.value})}} type="text" id="email"/>
                </div>
              </div>
            </div>
            <div className="personal-data">
              <div className="title">Должность</div>
              <div className="form-group">
                <div className="form-block-select">
                  <label className="label-title" htmlFor="role">Role</label>
                  <MultiSelect
                    disableSearch
                    hasSelectAll={false}
                    options={optionsSelect}
                    value={roles}
                    onChange={setRoles}
                    labelledBy="Select"
                    id="role"
                  />
                </div>
                { ( props.userRole.data && editUser.role_id && typeof editUser.role_id === 'object' && editUser.role_id.includes(3) ) || ( props.userRole.data && editUser.role_id && typeof editUser.role_id === 'object' && editUser.role_id.includes(4) )   ?
                  <div className="form-block-select">
                    <label className="label-title" htmlFor="work_type">Worker Types</label>
                    <MultiSelect
                      id="work_type"
                      disableSearch
                      hasSelectAll={false}
                      options={workerTypeSelect}
                      value={workerTypes}
                      onChange={setWorkerTypes}
                      labelledBy="Select"
                    />
                  </div> : null
                }
                {editUser && editUser.workerTypes ? editUser.workerTypes.map((workerType, index) =>
                <div className='w-100 d-flex' key={index}>
                  <div className="form-block position-relative d-flex w-50">
                    <div className='pr-3' >
                      <div className="form-block position-relative w-50">
                        <label htmlFor="rate">rate per hour</label>
                      </div>
                      <input
                      disabled={true}
                      name="rate_per_hour"
                      value={workerType.rate_per_hour}
                      id="rate"
                      />
                      <div className="form-block position-relative w-50">
                        <label htmlFor="rate">Tipping Percentage %</label>
                      </div>
                      <input
                      name="tipping_percentage[]"
                      placeholder='Tipping Percentage %'
                      value={workerType.tipping_percentage}
                      onChange={(e) => changeWorkerTypeSalary(e, index)}
                      onInput={(e) => e.target.setCustomValidity('')}
                      onInvalid={(e) => e.target.setCustomValidity('This field is required')} required type="number"
                      id="tipping_percentage"
                      />
                    </div>
                  </div>
                  <div className="form-block position-relative w-50 pl-3">
                    <div className="form-block position-relative w-50">
                      <label htmlFor="rate">Worker types</label>
                    </div>
                    <div>
                      <div className="text-nowrap worker-type font-weight-bold">{workerType.label}</div>
                    </div>
                  </div>
                </div>
                ) : ''

                }
              </div>
            </div>
            <div className="personal-data">
              <div className="title">Зарплата</div>
              <div className="form-group">
                <div className="form-block position-relative">
                  <label htmlFor="salary_percent">W2</label>
                  <input name="salary_percent" value={editUser.salary_percent} onChange={(e) => changeSalary('salary_percent', e)} type="text"  id="salary_percent"/>
                  <span className="error-message" id="salary_percent_message"></span>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="account">Account</label>
                  <select className="select-picker cursor-pointer" value={account} onChange={ (event) => {setAccount(event.target.value)}} name="account_id" data-width="fit">
                    {props.accountsList && props.accountsList.length ?
                    props.accountsList.map((item, index) => {
                      return(
                      <option key={index} value={item.id}>{item.name}</option>
                      )
                    }) : null
                    }

                  </select>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="retention_with_salary">1099</label>
                  <input name="retention_with_salary" value={editUser.retention_with_salary} onChange={(e) => changeSalary('retention_with_salary', e)}  type="text" id="retention_with_salary"/>
                  <span className="error-message" id="retention_with_salary_message"></span>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="cash_percent">% cash</label>
                  <input name="cash_percent" value={editUser.cash_percent} onChange={(e) => changeSalary('cash_percent', e)}  type="text" id="cash_percent"/>
                  <span className="error-message" id="cash_percent_message"></span>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="check">Check</label>
                  <input name="check" value={editUser.check} onChange={(e) => changeSalary('check', e)}  type="text" id="check"/>
                  <span className="error-message" id="check_message"></span>
                </div>
                <div className="form-block position-relative">
                  <label htmlFor="check_mdm">Check Mdm</label>
                  <input name="check_mdm" value={editUser.check_mdm} onChange={(e) => changeSalary('check_mdm', e)}  type="text" id="check_mdm"/>
                  <span className="error-message" id="check_mdm_message"></span>
                </div>

              </div>
            </div>
            <button className={addButtonDisabled ? 'button-disabled submit' : 'submit'} disabled={addButtonDisabled}>Редактировать Пользователя</button>
            <div className="close" onClick={() => setModalAddUserShow(false)}>
              <img src={close} alt="Close"/>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};


function mapStateToProps(state) {
  const { admin } = state.authReducer;
  const { accountsList } = state.accountReducer;
  const { user, error, userRole } = state.userReducer;
  return { user, error, userRole, admin, accountsList };
}

const mapDispatchToProps = d => ({
  showUser: (params) => d(userActions.showUser(params)),
  uploadImage: (params) => d(userActions.uploadImage(params)),
  updateUserData: (params) => d(userActions.updateUser(params)),
  deleteImage: (id) => d(userActions.deleteImage(id)),
  getAccounts: () => d(accountActions.getAccounts()),
  getUserRole: () => d(userActions.getUserRole()),
  deleteUser: (id, deleteUserThen) => d(userActions.deleteUser(id, deleteUserThen)),
  blockUser: (id) => d(userActions.blockUser(id)),
  archiveUsers: (id) => d(userActions.archiveUsers(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetail);
