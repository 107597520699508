import {http} from "../../modules/http";

export const roleService = {
  getRolesList,
  addRole,
  updateRole,
  deleteRole,
  getRoleTypes
};

function getRolesList(params) {
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params);
  return http.get('/worker-type?' + q.toString()).then(res => {
    return res
  })
}

function getRoleTypes() {
  let params = {}
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params);
  return http.get('/get-roles?' + q.toString()).then(res => {
    return res
  })
}

function addRole(params) {
  params.branch_id = localStorage.getItem('branch')
  return http.post('/roles', params).then(res => {
    return res
  })
}

function updateRole(params) {
  return http.put('/roles/' + params.id, params).then(res => {
    return res
  })
}

function deleteRole(params) {
  return http.delete('/roles/' + params).then(res => {
    return res
  })
}

