import {authConstants} from '../constants/actionTypes';
import {authService} from '../services';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const authActions = {
  login,
  logout,
  resetPassword,
  getUserMe,
};

function login(data) {

  return dispatch => {
    dispatch(request());
   return authService.login(data)
    .then(response => {
      dispatch(success(response.data.user));
      if (data.remember) {
        localStorage.setItem('access_token', response.data.access_token)
      } else {
        cookies.set('access_token', response.data.access_token, { path: '/' });
      }
      localStorage.setItem('user', JSON.stringify(response.data.user));
      return response;
    })
    .catch(function (error) {
      dispatch(failure(error.response));
      return error;
    })
  };

  function request() { return { type: authConstants.LOGIN_REQUEST } }
  function success(user) { return { type: authConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function logout() {
  return dispatch => {
    authService.logout()
    .then(response => {
      dispatch(logout());
      cookies.remove('access_token');
      localStorage.removeItem('access_token')
      localStorage.removeItem('branch')
      localStorage.removeItem('user')
      window.location.href = '/login'
    })
  };

  function logout() {return {type: authConstants.LOGOUT}}
}

function resetPassword(data) {
  return dispatch => {
    return authService.resetPassword(data)
    .then(response => {
      return response
    })
    .catch(function (error) {
      dispatch(failure(error.response));
    })
  };

  function failure(error) { return { type: authConstants.RESET_PASSWORD_FAILURE, error } }
}

function getUserMe() {
  return dispatch => {
   return authService.getUserMe()
    .then(response => {
      localStorage.setItem('user', JSON.stringify(response.data))
      if (response.data.data.role_id === 2) {
        localStorage.setItem('branch', response.data.data.branch_id)
      }
      dispatch(success(response.data));
    })
  };

  function success(admin) { return { type: authConstants.USER_ME_SUCCESS, admin } }
}
