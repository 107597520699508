import {http} from "../../modules/http";

export const accountService = {
  getAccountsList,
  addAccount,
  updateAccount,
  deleteAccount,
  accountType,
  getAccounts
};

function getAccountsList(params) {
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params);
  return http.get('/accounts?' + q.toString()).then(res => {
    return res
  })
}

function getAccounts() {
  let params = {}
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params);
  return http.get('/get-accounts?' + q.toString()).then(res => {
    return res
  })
}

function accountType() {
  return http.get('/payments-accounting-type').then(res => {
    return res
  })
}

function addAccount(params) {
  params.branch_id = localStorage.getItem('branch')
  return http.post('/accounts', params).then(res => {
    return res
  })
}

function updateAccount(params) {
  return http.put('/accounts/' + params.id, params).then(res => {
    return res
  })
}

function deleteAccount(params) {
  return http.delete('/accounts/' + params).then(res => {
    return res
  })
}

