import React, { useEffect, useState } from "react";
import add from "../../../assets/image/icons/add.svg";
import edit from "../../../assets/image/icons/edit.svg";
import show from "../../../assets/image/icons/show.svg";
import undo from "../../../assets/image/icons/undo.svg";
import deleteUser from "../../../assets/image/icons/delete.svg";
import archive from "../../../assets/image/icons/archive.svg";
import print from "../../../assets/image/icons/print.svg";
import arrowLeft from "../../../assets/image/icons/arrow-left.svg";
import arrowRight from "../../../assets/image/icons/arrow-right.svg";
import avatar from "../../../assets/image/default-avatar.png";
import userPhoto from "../../../assets/image/icons/user-photo.svg";
import close from "../../../assets/image/icons/close.svg";
import { Modal } from "react-bootstrap";
import {Link} from "react-router-dom";
import arrowTable from "../../../assets/image/icons/arrow-table-filter.svg";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { accountActions } from "../../../redux/actions/accountActions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from "validator";
import removeArchive from "../../../assets/image/icons/remove-archive.svg";
import BeatLoader from "react-spinners/BeatLoader";
import MultiSelect from "react-multi-select-component";
import queryString from "query-string";

const Staff = (props) => {
  let query = queryString.parse(props.location.search);
  const [filter, setFilter] = useState(query.tab);
  const [count, setCount] = useState('5');
  const [page, setPage] = useState('1');
  const [checkUserArr, setCheckUserArr] = useState([]);
  const [updateUser, setUpdateUser] = useState(false);
  const [workerTypesError, setWorkerTypesError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalAddUserShow, setModalAddUserShow] = useState(false);
  const [editUser, setEditUser] = useState({
    'salary_percent': '',
    'cash_percent': '',
    'retention_with_salary': '',
    'check': '',
    'check_mdm': ''
  });
  const [modalArchiveShow, setModalArchiveShow] = useState(false);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [editUserBoolean, setEditUserBoolean] = useState(false);
  const [updateWorkerType, setUpdateWorkerType] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [optionsSelect, setOptionsSelect] = useState([]);
  const [account, setAccount] = useState([]);
  const [roles, setRoles] = useState([]);
  const [workerTypeSelect, setWorkerTypeSelect] = useState([]);
  const [workerTypes, setWorkerTypes] = useState([]);
  const [userForm, setUserForm] = useState({
    one_thousand_ninety_nine: '',
    no_salary: '',
    avatar: '',
    role_id: [],
    w2: '',
  });
  const [tableField, setTableField] = useState({
    name: false,
    worker_type_name: false,
    phone_number: false,
    email: false
  });


  useEffect(() => {
    getUsersList()
  },  []);

  const printPage = () =>{
    window.print();
  }

  const validatorEmail = (e) => {
    if (!e.target.value) {
      e.target.setCustomValidity('This field is required')
    } else {
      if (!validator.isEmail(e.target.value)) {
        e.target.setCustomValidity("This email is not a valid");
      }
      else{
        e.target.setCustomValidity("");
      }
    }
  };

  const handleChange = (e) => {
    const name =  e.target.name;
    if (name === 'avatar') {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (event) => {
        setUserForm({...userForm, avatar: event.target.result})
        if (editUser) {
          setEditUser({...editUser, avatar: event.target.result})
        }
      };
      reader.readAsDataURL(file)
    } else {
      let formData = userForm ;
      let newFormInput = {};
      let value;
      value = e.target.value
      newFormInput[name] = value;
      setUserForm({...formData, ...newFormInput});
    }
  };


  const filterTable = (filterName) => {
    setFilter(filterName)
    setCheckUserArr([])
    setUpdateUser(false)
    props.history.push('/staff?tab=' + filterName)
  };

  const hideAddModal = () => {
    setUserForm({
      one_thousand_ninety_nine: '',
      avatar: '',
      role_id: [],
    });
    setOptionsSelect([]);
    setAccount([]);
    setRoles([]);
    setWorkerTypeSelect([]);
    setWorkerTypes([]);
    setEditUser({});
    setModalAddUserShow(false)
  }

  useEffect(() => {
    getUsersList()
  },  [filter, count, page]);

  useEffect(() => {
    setUsersList(props.users)
  },  [props]);

  const pageCount = (e) => {
    setCount(e.target.value)
  };

  const clickRight = () => {
    if(props.pagination) {
      if (page < props.pagination.count_pages){
        setPage(+page + 1)
      }
    }
  };

  const clickLeft = () => {
    if (page > 1) {
      setPage(+page - 1)
    }
  };

  const addUserModalShow = () => {
    setEditUser({
      'salary_percent': '',
      'cash_percent': '',
      'retention_with_salary': '',
      'check': '',
      'check_mdm': ''
    })
    setUserForm({...userForm, role_id: []})
    setUserForm({...userForm, avatar: ''});
    setUserForm({...userForm, worker_type_id: []})
    setUserForm({...userForm, one_thousand_ninety_nine: ''})
    setEditUserBoolean(false);
    props.getUserRole()
    props.getAccounts()
    setModalAddUserShow(true)
  };

  useEffect(() => {
    let label = []
    if (props.userRole.data && props.userRole.data.length) {
      props.userRole.data.map((item) => {
        label.push({label: item.role_name, value: item.id, disabled: false})
      })
      setOptionsSelect(label)
    }
  } , [props.userRole])

  useEffect(() => {
    if(!editUserBoolean) {
      if (props.accountsList && props.accountsList.length) {
        setAccount(props.accountsList[0].id)
      }
    }
  } , [props.accountsList])

  useEffect(() => {
    rolesSelect()
  } , [roles]);

  const rolesSelect = () => {
    let rolesArr = [...roles];
    let optionsArr = [...optionsSelect];
    if (rolesArr && rolesArr.length) {
      if ((rolesArr[0].value !== 3 && rolesArr[0].value !== 4) && rolesArr.length > 1) {
        rolesArr = rolesArr.filter((item, index) => index !== 0)
        setRoles(rolesArr)
        setWorkerTypes([])
      }


      for(let i = 0; i < rolesArr.length; i++) {
        if (rolesArr[i].value === 3 || rolesArr[i].value === 4) {
          optionsArr = optionsArr.map(item => {
            if (item.value === 3 || item.value === 4) {
              item.disabled = false
            } else {
              item.disabled = true
            }
            return item
          })
        } else {
          optionsArr = []
          props.userRole.data.map((item) => {
            optionsArr.push({label: item.role_name, value: item.id, disabled: false})
          })
        }
      }
    } else {
      setWorkerTypes([])
      optionsArr = optionsArr.map(item => {
        item.disabled = false
        return item
      })
    }
    let form = userForm;
    form.role_id = rolesArr.map(item => {
      return item.value
    });
    setUserForm(form);
    setWorkerTypeSelect([])
    if (userForm.role_id.includes(3) || userForm.role_id.includes(4)) {
      let workerArr = []
      userForm.role_id.map(item => {
        props.userRole.data[item - 1].worker_types.map(type => {
          workerArr.push({label: type.type_name, value: type.id, rate_per_hour: type.rate_per_hour, tipping_percentage: type.pivot?.tipping_percentage, disabled: false})
        });
      })
      setWorkerTypeSelect(workerArr)
      if (roles.length < 2 && !updateWorkerType) {
        setWorkerTypes([])
      } else {
        setUpdateWorkerType(false)
      }
    }
    setOptionsSelect(optionsArr)
  }

  useEffect(() => {
    let workerTypeId = [];
    workerTypes.map(item => {
      workerTypeId.push(item.value)
    })
    setUserForm({...userForm, worker_type_id: workerTypeId})
  } , [workerTypes])


  const editUserModalShow = (user) => {
    props.getUserRole().then((response) => {
      if (response.data && response.data.length) {
        let userRolesList = [...response.data]
        let editRoles = [...roles]
        userRolesList.map((item) => {
          if (user.array_role_id.includes(item.id)) {
            editRoles.push({label: item.role_name, value: item.id, disabled: false})
          }
        })
        setRoles(editRoles)
      }
    })
    props.getAccounts().finally(() => {
      if (user.account) {
        setAccount(user.account.id)
      }
    });
    let workerTypesData = user.worker_types.map(item => {
      return {label: item.type_name, value: item.id, rate_per_hour: item.rate_per_hour, tipping_percentage: item.pivot?.tipping_percentage, disabled: false}
    });
    user.workerTypes = workerTypesData
    setUpdateWorkerType(true)
    setWorkerTypes(workerTypesData);
    let form = {...userForm}
    if (user.salary === 'no_salary') {
      form.no_salary = true
    } else {
      form.no_salary = false
    }
    form.avatar = ''
    setEditUser(user,() => {
      console.log("editUser: ", user);
    });
    setUserForm(form,() => {
      console.log("userForm: ", userForm);
    })
    setEditUserBoolean(true)
    setModalAddUserShow(true)
  };

  const getUsersList = () => {
    setLoading(true)
    props.getUsers({
      "role_id": filter,
      "page": page,
      "count": count
    }).finally(() => {
      setLoading(false)
    })
  };


  const deleteUsers = () => {
    props.removeUsers({ id: checkUserArr}).then(() => {
      toast.success('Сотрудник удален');
      setCheckUserArr([])
      setUpdateUser(false)
    })
  }

  const usersArchive = () => {
    props.archiveUsers({ id: checkUserArr}).then(res => {
      toast.success('Сотрудник добавлен в Архив');
      setCheckUserArr([])
      setUpdateUser(false)
    })
  }

  const checkUser = (event, id) => {
    event.stopPropagation();
    let arr = [...checkUserArr]
    if (arr.includes(id)) {
      arr = arr.filter( i => i !== id)
      setCheckUserArr(arr)
    } else {
      arr.push(id)
      setCheckUserArr(arr)
    }
    if (arr.length > 0) {
      setUpdateUser(true)
    } else {
      setUpdateUser(false)
    }
  };

  const userCreateUpdate = (e) => {
    e.preventDefault()
    setAddButtonDisabled(true)
    let required = true
    let formData = new FormData(e.currentTarget)
    if (!userForm.avatar) {
      formData.delete('avatar')
    }
    if (userForm.no_salary) {
      formData.delete('salary_percent')
    }
    userForm.role_id.map((item, index) => {
      formData.append('role_id[' + index +']', item)
    });
    if (account) {
      formData.append('account_id', account)
    }
    if (userForm.role_id[0] !== 1 && userForm.role_id[0] !== 2) {
      if (userForm.worker_type_id && userForm.worker_type_id.length) {
        userForm.worker_type_id.map((item, index) => {
          formData.append('worker_type_id[' + index +']', item)
        });
        setWorkerTypesError(false)
        required = true
      } else {
        setWorkerTypesError(true)
        required = false
        setAddButtonDisabled(false)
      }
    }


    if (editUserBoolean && required) {
      formData.append('id', editUser.id)
      props.updateUserData(formData, userForm.role_id, filter).then(res => {
        if (res.type === 'UPDATE_USER_SUCCESS') {
          hideAddModal()
          setEditUserBoolean(false)
          toast.success('Сотрудник обновлён');
        } else {
          if (res.status === 422) {
            const data = res.error.data
            const keys = Object.keys(data)
            toast.error(data[keys[0]][0])
          } else {
            toast.error(res.error.data.message)
          }
        }
      }).finally(() => {
        setAddButtonDisabled(false)
      })
    } else {
      if (required)  {
        props.createUser(formData, filter).then(res => {
          if (res.type === 'CREATE_USER_SUCCESS') {
            hideAddModal()
            toast.success('Сотрудник добавлен');
          } else {
            const data = res.error.data
            const keys = Object.keys(data)
            toast.error(data[keys[0]][0]);
          }
        }).finally(() => {
          setAddButtonDisabled(false)
        })
      }
    }
  };

  const changeSalary = (category,e) => {
    let salary = 0;
    if (category === 'salary_percent') {
      salary = +editUser.cash_percent + +editUser.retention_with_salary + +editUser.check + +editUser.check_mdm + +e.target.value
    } else if (category === 'cash_percent') {
      salary = +editUser.salary_percent + +editUser.retention_with_salary + +editUser.check + +editUser.check_mdm + +e.target.value
    } else if (category === 'retention_with_salary') {
      salary = +editUser.cash_percent + +editUser.salary_percent + +editUser.check + +editUser.check_mdm + +e.target.value
    } else if (category === 'check') {
      salary = +editUser.cash_percent + +editUser.retention_with_salary + +editUser.salary_percent + +editUser.check_mdm + +e.target.value
    } else if (category === 'check_mdm') {
      salary = +editUser.cash_percent + +editUser.retention_with_salary + +editUser.check + +editUser.salary_percent + +e.target.value
    }


    const re = /^[0-9\b]+$/;
    let item = document.getElementById(category + '_message')
		if (salary <= 100) {
      if (e.target.value === '' || re.test(e.target.value)) {
        setEditUser({...editUser, [category]:e.target.value})
        item.innerText = ''
      }
    } else {
      item.innerText = 'percentage should not be more than 100'
    }
  }

  const changeWorkerTypeSalary = (e, index) => {
    const workerType = workerTypes.map((item, i) => {
      if (i === index) {
        item.tipping_percentage = e.target.value
      }
      return item
    })
    setWorkerTypes(workerType)
  }


  const sortField = (field) => {
    if (usersList && usersList.length) {
      let users = [...usersList]
      users = users.sort((a, b) => {
        if (tableField[field]) {
          if (a[field] > b[field]) {
            return 1
          }
          if (a[field] < b[field]) {
            return -1
          }
          return 0
        } else {
          if (a[field] < b[field]) {
            return 1
          }
          if (a[field] > b[field]) {
            return -1
          }
          return 0
        }
      });
      setUsersList(users)
      setTableField({...tableField, [field]: !tableField[field]})
    }
  };

  const undoCheck = () => {
    setCheckUserArr([])
    setUpdateUser(false)
  }

  return (
    <div className="staff">
      {loading ?
        <div className="loading-block">
          <BeatLoader color="#0A65FF" loading={loading} size={20}/>
        </div> : null
      }
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bread-crumbs-mini"><p>Страница сотрудников</p></div>
      <div className="top-block">
        <div className="filter">
          <div className={filter == 3 ? 'active-filter' : ''} onClick={() => filterTable(3)}>Front</div>
          <div className={filter == 4 ? 'active-filter' : ''} onClick={() => filterTable(4)}>Back</div>
          {props.admin && props.admin.role_id === 1 ?
            <div className={filter == 2 ? 'active-filter' : ''} onClick={() => filterTable(2)}>Manager</div> : null
          }
          <div className={filter == 'archive' ? 'active-filter' : ''} onClick={() => filterTable('archive')}>Archive</div>
        </div>
        {updateUser ?
          <div className="d-flex">
            <button className="undo" onClick={undoCheck} type="button"><img src={undo} alt="Undo"/>undo</button>
            {props.admin && props.admin.role_id === 1 ?
                <button className="delete" type="button" onClick={() => setModalShow(true)}><img src={deleteUser} alt="Delete"/>delete</button> : props.admin && props.admin.role_id === 2 ?
                (filter === 'archive' ?
                  <button className="archive remove-archive" type="button" onClick={() => setModalArchiveShow(true)}><img src={removeArchive} alt="Archive"/>remove from archive</button> :
                  <button className="archive" type="button" onClick={() => setModalArchiveShow(true)}><img src={archive} alt="Archive"/>archive</button>
                ) : ''
            }
          </div>
          :
          (props.admin && props.admin.role_id === 1 ?
              <button className="add" type="button" onClick={() => addUserModalShow()}><img src={add} alt="Add"/>add a user</button> : null
          )
        }
      </div>
      <div className="user-table">
        <table>
          <thead>
            <tr className="th-filter">
              <th>
                <div onClick={() => sortField('name')}>
                  Name
                  <img src={arrowTable} alt="Filter"/>
                </div>
              </th>
              <th>
                <div onClick={() => sortField('worker_type_name')}>
                  Post
                  <img src={arrowTable} alt="Filter"/>
                </div>
              </th>
              <th>
                <div onClick={() => sortField('phone_number')}>
                  Number
                  <img src={arrowTable} alt="Filter"/>
                </div>
              </th>
              <th>
                <div onClick={() => sortField('email')}>
                  Email
                  <img src={arrowTable} alt="Filter"/>
                </div>
              </th>
              <th>
                <div>
                  Operation
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
          {usersList && usersList.length ?
            usersList.map((item, index) => {
            return (
            <tr key={index} className={checkUserArr.includes(item.id) ? 'check-tr' : ''}>
              <td>
                <div onClick={() => props.history.push('/staff/' + item.id)} className={checkUserArr.includes(item.id) ? 'check-user user' : 'user'}>
                  <input type="checkbox" readOnly={true} checked={checkUserArr.includes(item.id)} onClick={(e) => {checkUser(e, item.id)}}/>
                  <div className="avatar">
                    {item.avatar ?
                        <img src={item.avatar} alt="User"/> :
                        <img src={avatar} alt="User"/>
                    }
                  </div>
                  <div className="name">{item.first_name} {item.last_name}</div>
                </div>
              </td>
              <td>{item.role_id == 2 ? item.worker_type_name : ''}{item.role_id !== 2 ?
                item.worker_types.map((item, ind) => {
                  return (
                    <div key={ind}>{item.type_name}</div>
                  )
                }) : null
              }</td>
              <td>{item.phone_number}</td>
              <td>{item.email}</td>
              <td>
                <div className="table-button">
                  <Link to={'/staff/history/' + item.id}><img src={show} alt="Show"/>show history</Link>
                  {props.admin && props.admin.role_id === 1 ?
                    <button type="button" onClick={() => editUserModalShow(item)}><img src={edit} alt="Edit"/>edit</button> : null
                  }
                </div>
              </td>
            </tr>
            )
          }) : ''
          }
          </tbody>
        </table>
        <div className="table-pagination">
          <div className="page-count">
            <span>{props.pagination  && props.pagination.count_skip ? props.pagination.skip_page + 1 : '0'}-{props.pagination ? props.pagination.count_skip : ''}</span> of {props.pagination ? props.pagination.count : ''}
          </div>
          <div className="row-page">
            <div className="d-flex align-items-center mr-5">
              <p>Rows per page:</p>
              <select className="select-picker cursor-pointer" onChange={ (event) => {pageCount(event)}} data-width="fit">
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='15'>15</option>
              </select>
            </div>
            <div>
              <img src={arrowLeft} onClick={() => {clickLeft()}} alt="Left" className="cursor-pointer mr-5"/>
              <img src={arrowRight} onClick={() => {clickRight()}} className="cursor-pointer" alt="Right"/>
            </div>
          </div>
        </div>
      </div>
      <div className="user-table user-table-mini">
        <table>
          <thead>
          <tr>
            <th>Name</th>
            <th>number/email</th>
          </tr>
          </thead>
          <tbody>
          {usersList && usersList.length ?
            usersList.map((item, index) => {
               return (
                   <tr key={index} className={checkUserArr.includes(item.id) ? 'check-tr' : ''}>
                     <td>
                       <div className={checkUserArr.includes(item.id) ? 'check-user' : ''}>
                         <Link tag="div" to={'/staff/' + item.id} className="user">
                           <input type="checkbox"  readOnly={true}  checked={checkUserArr.includes(item.id)} onClick={(e) => {checkUser(e, item.id)}}/>
                           <div className="avatar">
                             {item.avatar ?
                               <img src={item.avatar} alt="User"/> :
                               <img src={avatar} alt="User"/>
                             }
                           </div>
                           <div className="name">{item.last_name} {item.first_name}</div>
                         </Link>
                         <div className="table-button">
                           <Link to={'/staff/history/' + item.id}><img src={show} alt="Show"/>show history</Link>
                           <button type="button" onClick={() => editUserModalShow(item)}><img src={edit} alt="Edit"/>edit
                           </button>
                         </div>
                       </div>
                     </td>
                     <td>
                       <div>{item.phone_number}</div>
                       <div>{item.email}</div>
                     </td>
                   </tr>
               )
             }) : ''
          }
          </tbody>
        </table>
        <div className="table-pagination">
          <div className="page-count">
            <span>{props.pagination && props.pagination.count_skip ? props.pagination.skip_page + 1 : '0'}-{props.pagination ? props.pagination.count_skip : ''}</span> of {props.pagination ? props.pagination.count : ''}
          </div>
          <div className="row-page">
            <div className="d-flex align-items-center mr-5">
              <p>Rows per page:</p>
              <select className="select-picker cursor-pointer" onChange={ (event) => {pageCount(event)}} data-width="fit">
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='15'>15</option>
              </select>
            </div>
            <div>
              <img src={arrowLeft} onClick={() => {clickLeft()}} alt="Left" className="cursor-pointer mr-5"/>
              <img src={arrowRight} onClick={() => {clickRight()}} className="cursor-pointer" alt="Right"/>
            </div>
          </div>
        </div>
      </div>
      <div className="cards-block">
        {usersList && usersList.length ?
          usersList.map((item, index) => {
              return (
                  <div key={index} className={checkUserArr.includes(item.id) ? 'check-card-item card-item' : 'card-item'}>
                    <div>
                      <Link to={'/staff/' + item.id} className="user">
                        <input type="checkbox"  readOnly={true} checked={checkUserArr.includes(item.id)} onClick={(e) => {checkUser(e, item.id)}}/>
                        <div className="avatar">
                          {item.avatar ?
                            <img src={item.avatar} alt="User"/> :
                            <img src={avatar} alt="User"/>
                          }
                        </div>
                        <div className="name-role">
                          <div>{item.name}</div>
                          <span>{item.role_id == 2 ? item.role.role_name : item.worker_type_name}</span>
                        </div>
                      </Link>
                      <div className="email-number">
                        <div>{item.phone_number}</div>
                        <div>{item.email}</div>
                      </div>
                    </div>
                    <div className="button-group">
                      <Link to={'/staff/history/' + item.id}><img src={show} alt="Show"/></Link>
                      <button type="button" onClick={() => editUserModalShow(item)}><img src={edit} alt="Edit"/></button>
                    </div>
                  </div>
              )
            }) : ''
        }
      </div>
      <div className="mobile-buttons">
        {updateUser ?
          <div className="d-flex">
            <button className="undo" onClick={undoCheck} type="button"><img src={undo} alt="Undo"/></button>
            {props.admin && props.admin.role_id === 1 ?
                <button className="delete" type="button" onClick={() => setModalShow(true)}><img src={deleteUser} alt="Delete"/></button> : props.admin && props.admin.role_id === 2 ?
                <button className="archive" type="button"onClick={() => setModalArchiveShow(true)} ><img src={archive} alt="Archive"/>archive</button> : ''
            }
          </div>
          :
          <button className="add" type="button" onClick={() => addUserModalShow()}><img src={add} alt="Add"/></button>
        }
      </div>
      <button type="button" onClick={printPage} className="print-page"><img src={print} alt="Print"/>Print this page</button>
      <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      centered
      dialogClassName="user-delete-modal"
    >
      <Modal.Body>
        <div className="title">Вы действитльно хотите удалить сотрудника?</div>
        <div className="text">Пользователь будет удален</div>
        <button onClick={() => {
          deleteUsers();
          setModalShow(false)}} type="button">удалить</button>
        <div className="close" onClick={() => setModalShow(false)}>
          <img src={close} alt="Close"/>
        </div>
      </Modal.Body>
    </Modal>
      {/*archive modal*/}
      <Modal
          show={modalArchiveShow}
          onHide={() => setModalArchiveShow(false)}
          centered
          dialogClassName="user-delete-modal"
      >
        <Modal.Body>
          <div className="title">{filter === 'archive' ? 'Вы действитльно хотите удалить сотрудника из архива?' : 'Вы действитльно хотите переместить сотрудника в архив?'}</div>
          <div className="text">{filter === 'archive' ? 'Пользователь будет удален из архива' : 'Пользователь будет перемещен в архив'}</div>
          <button onClick={() => {
            usersArchive();
            setModalArchiveShow(false)}} type="button">{filter === 'archive' ? 'удалить' : 'архив'}</button>
          <div className="close" onClick={() => setModalArchiveShow(false)}>
            <img src={close} alt="Close"/>
          </div>
        </Modal.Body>
      </Modal>
    <Modal
      show={modalAddUserShow}
      onHide={hideAddModal}
      centered
      dialogClassName="add-user-modal"
    >
      <Modal.Body>
        <form className="add-user" onSubmit={(e) => userCreateUpdate(e)}>
          <div className="user-avatar">
            <div className="avatar">
              {editUser && editUser.avatar && !userForm.avatar ?
                  <img src={editUser.avatar} alt="User"/> :
               editUser && editUser.avatar &&  userForm.avatar ?
                  <img src={userForm.avatar} alt="User"/> :
                editUser && !userForm.avatar && !editUser.avatar ?
                   <img src={userPhoto} alt="User"/> :
                !editUser && userForm.avatar ?
                  <img src={userForm.avatar} alt="User"/> :
                  <img src={userPhoto} alt="User"/>
              }
            </div>
            <label htmlFor="avatar" className="upload-file">
              <input type="file" accept="image/*" id="avatar"
                     name="avatar"  onChange={(e) => handleChange(e)} className="d-none"/>
              добавить фото
            </label>
          </div>
          <div className="personal-data">
            <div className="title">Личные данные</div>
            <div className="form-group">
              <div className="form-block position-relative">
                <label htmlFor="name">Имя</label>
                <input name="last_name" value={editUser.last_name} onInput={(e) => e.target.setCustomValidity('')} onChange={(e) => setEditUser({...editUser, last_name:e.target.value})} onInvalid={(e) => e.target.setCustomValidity('This field is required')} required  type="text" id="name"/>
              </div>
              <div className="form-block position-relative">
                <label htmlFor="number">Номер</label>
                <input name="phone_number" value={editUser.phone_number} onInput={(e) => e.target.setCustomValidity('')} onChange={(e) => setEditUser({...editUser, phone_number:e.target.value})}  onInvalid={(e) => e.target.setCustomValidity('This field is required')} required  type="number" id="number"/>
              </div>
              <div className="form-block position-relative">
                <label htmlFor="lastName">Фамилия</label>
                <input name="first_name" value={editUser.first_name} onInput={(e) => e.target.setCustomValidity('')} onChange={(e) => setEditUser({...editUser, first_name:e.target.value})} onInvalid={(e) => e.target.setCustomValidity('This field is required')} required  type="text" id="lastName"/>
              </div>
              <div className="form-block position-relative">
                <label htmlFor="email">Mail</label>
                <input name="email"  value={editUser.email} onInvalid={(e) => validatorEmail(e)} required onChange={(e) => {validatorEmail(e);setEditUser({...editUser, email:e.target.value})}} type="text" id="email"/>
              </div>
            </div>
          </div>
          <div className="personal-data">
            <div className="title">Должность</div>
            <div className="form-group">
              <div className="form-block-select">
                <label className="label-title" htmlFor="role">Role</label>
                <MultiSelect
                  disableSearch
                  hasSelectAll={false}
                  options={optionsSelect}
                  value={roles}
                  onChange={setRoles}
                  labelledBy="Select"
                  id="role"
                />
              </div>
              { (props.userRole.data && userForm.role_id.includes(3))  || (props.userRole.data && userForm.role_id.includes(4)) ?
                  <div className="form-block-select">
                    <label className="label-title" htmlFor="work_type">Worker Types</label>
                    <MultiSelect
                      id="work_type"
                      disableSearch
                      hasSelectAll={false}
                      options={workerTypeSelect}
                      value={workerTypes}
                      onChange={setWorkerTypes}
                      labelledBy="Select"
                    />
                    {workerTypesError  && workerTypes && !workerTypes.length?
                      <p className="text-danger">The worker type field is required</p> : ''
                    }
                  </div> : null
              }
              {workerTypes ? workerTypes.map((workerType, index) =>
                <div className='w-100 d-flex'>
                  <div className="form-block position-relative d-flex w-50">
                    <div className='pr-3'  key={index}>
                      <div className="form-block position-relative w-50">
                        <label htmlFor="rate">rate per hour</label>
                      </div>
                      <input
                      disabled={true}
                      name="rate_per_hour"
                      value={workerType.rate_per_hour}
                      id="rate"
                      />
                      <div className="form-block position-relative w-50">
                        <label htmlFor="rate">Tipping Percentage %</label>
                      </div>
                      <input
                      name='tipping_percentage[]'
                      placeholder='Tipping Percentage %'
                      value={workerType.tipping_percentage}
                      onChange={(e) => changeWorkerTypeSalary(e, index)}
                      onInput={(e) => e.target.setCustomValidity('')}
                      onInvalid={(e) => e.target.value > 100 ? e.target.setCustomValidity('This field is max value 100') : e.target.setCustomValidity('This field is required')}
                      required
                      max="100"
                      type="number"
                      id="tipping_percentage"
                      />
                    </div>
                  </div>
                  <div className="form-block position-relative w-50 pl-3">
                    <div className="form-block position-relative w-50">
                      <label htmlFor="rate">Worker types</label>
                    </div>
                    <div>
                      <div key={index} className="text-nowrap worker-type font-weight-bold">{workerType.label}</div>
                    </div>
                  </div>
                </div>
                 ) : ''

                 }
            </div>
          </div>
          <div className="personal-data">
            <div className="title">Зарплата</div>
            <div className="form-group">
              <div className="form-block position-relative">
                <label htmlFor="salary_percent">W2</label>
                <input name="salary_percent" value={editUser.salary_percent} onChange={(e) => changeSalary('salary_percent', e)} type="number"  id="salary_percent"/>
                <span className="error-message" id="salary_percent_message"></span>
              </div>

              <div className="form-block">
                <label htmlFor="name">Account</label>
                <select className="select-picker cursor-pointer" value={account} onChange={ (event) => {setAccount(event.target.value)}} name="account_id" data-width="fit">
                  {props.accountsList && props.accountsList.length ?
                    props.accountsList.map((item, index) => {
                      return(
                        <option key={index} value={item.id}>{item.name}</option>
                        )
                    }) : null
                  }

                </select>
              </div>
              <div className="form-block position-relative">
                <label htmlFor="retention_with_salary">1099</label>
                <input name="retention_with_salary" value={editUser.retention_with_salary} onChange={(e) => changeSalary('retention_with_salary', e)}  type="number" id="retention_with_salary"/>
                <span className="error-message" id="retention_with_salary_message"></span>
              </div>
              <div className="form-block position-relative">
                <label htmlFor="cash_percent">% cash</label>
                <input name="cash_percent" value={editUser.cash_percent} onChange={(e) => changeSalary('cash_percent', e)}  type="text" id="cash_percent"/>
                <span className="error-message" id="cash_percent_message"></span>
              </div>
              <div className="form-block position-relative">
                <label htmlFor="check">Check</label>
                <input name="check" value={editUser.check} onChange={(e) => changeSalary('check', e)}  type="number" id="check"/>
                <span className="error-message" id="check_message"></span>
              </div>
              <div className="form-block position-relative">
                <label htmlFor="check_mdm">Check Mdm</label>
                <input name="check_mdm" value={editUser.check_mdm} onChange={(e) => changeSalary('check_mdm', e)}  type="number" id="check_mdm"/>
                <span className="error-message" id="check_mdm_message"></span>
              </div>

            </div>
          </div>
          <button type="submit" className={addButtonDisabled ? 'button-disabled submit' : 'submit'} disabled={addButtonDisabled}>{editUserBoolean ? 'Редактировать Пользователя' : 'Создать Пользователя'}</button>
          <div className="close" onClick={() => setModalAddUserShow(false)}>
            <img src={close} alt="Close"/>
          </div>
        </form>
      </Modal.Body>
    </Modal>
    </div>
  );
};


function mapStateToProps(state) {
  const { admin } = state.authReducer;
  const { accountsList } = state.accountReducer;
  const { users, pagination, userRole } = state.userReducer;
  return { users, pagination, admin , userRole, accountsList};
}

const mapDispatchToProps = d => ({
  getUsers: (params) => d(userActions.getUsers(params)),
  updateUserData: (params, array_role_id, filter) => d(userActions.updateUser(params, array_role_id, filter)),
  createUser: (params, filter) => d(userActions.createUser(params, filter)),
  getUserRole: () => d(userActions.getUserRole()),
  getAccounts: () => d(accountActions.getAccounts()),
  removeUsers: (id) => d(userActions.removeUsers(id)),
  archiveUsers: (id) => d(userActions.archiveUsers(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
