import React, {useEffect, useState} from "react";
import arrowLeft from "../../../assets/image/icons/arrow-left.svg";
import arrowRight from "../../../assets/image/icons/arrow-right.svg";
import avatar from "../../../assets/image/default-avatar.png";
import print from "../../../assets/image/icons/print.svg";
import moment from 'moment';
import Calendar from "../../components/calendar";
import {connect} from "react-redux";
import {performanceActions} from "../../../redux/actions/performanceActions";
import {toast, ToastContainer} from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";

const Performance = (props) => {
    const [scroll, setScroll] = useState(0);
    const [form, setForm] = useState();
    const [count, setCount] = useState('5');
    const [page, setPage] = useState('1');
    const [newDay, setNewDay] = useState();
    const [loading, setLoading] = useState(false);

    const pageCount = (e) => {
        setCount(e.target.value)
    };

    useEffect(() => {
        setTime();
    } , [props.performances])

    const clickRight = () => {
        if(props.pagination) {
            if (page < props.pagination.count_pages){
                setPage(+page + 1)
            }
        }
    };

    const clickLeft = () => {
        if (page > 1) {
            setPage(+page - 1)
        }
    };

    const printPage = () =>{
        window.print();
    };

  const setTime = () => {
      setTimeout(() => {
          if (Object.keys(props.performances).length) {
              dataSort()
          }
      }, 0);
  }


  useEffect(() => {
      getPerformances()
  },  [count, page]);


  const  getPerformances = async (day = undefined) => {
      let data = await getPerformanceData(day)
      if (data) {
          setLoading(true)
          props.getPerformance(data).finally(() => {
              setLoading(false);
          })
      }
  }

  const getPerformanceData = async (day) => {
      if (day || newDay) {
            if (!day) {
                day = newDay
            }
          if (day && day.previousDay) {
                return {
                    previousDay: day.previousDay,
                    page: page,
                    count: count
                }
            } else if (day.start_date && day.end_date) {
                return {
                    start_date: day.start_date,
                    end_date: day.end_date,
                    page: page,
                    count: count
                }
            } else if (day.start_date && !day.end_date && !day.previousDay) {
                return {
                    start_date: day.start_date,
                    page: page,
                    count: count
                }
            } else if (day.days) {
                return {
                    days: day.days,
                    page: page,
                    count: count
                }
            } else if (day.start_period && day.end_period) {
                return {
                    end_period: day.end_period,
                    start_period: day.start_period,
                    page: page,
                    count: count
                }
            } else if (day.months) {
                return {
                    months: day.months,
                    page: page,
                    count: count
                }
            }
        } else {
          return  {
                previousDay: '6',
                page: page,
                count: count
            }
        }
    }
    const filter = (calendar) => {
        if (calendar.day) {
          setNewDay(calendar.day)
          getPerformances(calendar.day)
        }
    };


    const createprice = (e, day, id, user_id) => {
        if (e.target.value.length <= 10 && e.target.value.length > 0 && e.target.value !== '00') {
            props.performanceCreate({
                performance_type_id: id,
                user_id: user_id,
                date: day,
                rating: e.target.value,
            }).then(res => {
                if (res.type === 'CREATE_PERFORMANCE_FAILURE') {
                    if (res.error.status === 422) {
                        const data = res.error.data
                        const keys = Object.keys(data)
                        toast.error(data[keys[0]][0])
                    } else {
                        toast.error(res.error.data.message)
                    }
                }
            }).finally(() => {
                setLoading(false);
            })

        }
    };

    const changeprice = (e, index, perIndex, ind) => {
      let {value, maxLength} = e.target
        let price
        let data = [...form]
        price = value.slice(0, maxLength)
      if (price.length <= 10) {
        data[index].performances[perIndex].arraySort[ind].price = price
            let sum = 0;
            for (let i = 1; i < data[index].performances[perIndex].arraySort.length; i++) {
                sum += +data[index].performances[perIndex].arraySort[i].price
            }
            data[index].performances[perIndex].total = sum
            setForm(data)
        }
    };

    const dataSort = () => {
        let newData = [];
        if (props.performances) {
            for (const [index, [key, value]] of Object.entries(Object.entries(props.performances))) {
                newData.push({user: value.user, weekDay: [], performances: []})
                for (const [inx, [key, item]] of Object.entries(Object.entries(value.performances))) {
                    let sum = 0;
                    let array = [];
                    let arraySort = [];
                    for (const [k, val] of Object.entries(item)) {
                        array.push(val)
                        if (val.price) {
                            sum += +val.price
                        }
                    }

                    function day(a, b) {
                        if (a.hasOwnProperty('day') && b.hasOwnProperty('day') && a.day < b.day) {
                            return -1
                        } else if (a.hasOwnProperty('day') && b.hasOwnProperty('day') && a.day > b.day) {
                            return 1
                        } else {
                            return 0
                        }
                    }

                    arraySort = array.sort(day)
                        arraySort.map((it) => {
                            if (it.hasOwnProperty('day')) {
                                let d
                                if (arraySort.length <= 8) {
                                    let weekDay = moment(it.day).locale('EN').format('dddd')
                                    d = weekDay + ' (' + it.day + ')'
                                } else {
                                    d = it.day
                                }
                                if (!newData[index].weekDay.includes(d)) {
                                    newData[index].weekDay.push(d)
                                }
                            }
                            return it
                        })
                    newData[index].performances.push({type: key, arraySort, total: sum})
                }
            }
            setForm([])
            setForm(newData)
        }
        setLoading(false)
    };
  return (
        <div className="performance">
            {loading ?
              <div className="loading-block">
                  <BeatLoader color="#0A65FF" loading={loading} size={20}/>
              </div> : null
            }
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
          <div className="bread-crumbs-mini"><p>Производительность</p></div>
          <div className="performance-block">
                <Calendar calendarFilter={(calendar) => filter(calendar)}/>
                <div className="scroll-table">
                  <div className="scroll-touch scroll-div">
                    {form ? form.map((item , index) => {
                      return (
                      <table id={index === 0 ? 'w-table' : ''} key={index}>
                        <thead>
                        <tr>
                          <th>
                            <div className="user">
                              <div className="avatar">
                                {item.user.avatar ?
                                <img src={item.user.avatar} alt=""/> :
                                <img src={avatar} alt=""/>
                                }
                              </div>
                              <div className="name">
                                {item.user && item.user.last_name ? item.user.last_name : ''} {item.user && item.user.first_name ? item.user.first_name : ''} <span>({item.user && item.user.worker_type_name ? item.user.worker_type_name : ''})</span>
                              </div>
                            </div>
                          </th>
                          {item.weekDay ? item.weekDay.map((w, t) => {
                            return (
                            <th className="week-day" key={t}>{w}</th>
                            )
                          }): ''
                          }
                          <th className="total">total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {item.performances ? item.performances.map((i, perIndex) => {
                          return (
                          <tr key={perIndex}>
                            {i.arraySort ? i.arraySort.map((it, ind) => {
                              return (
                              <td key={ind}>{it.day ? <input value={it.price} maxLength="10" onChange={(e) => {e.target.value === '00' ?  e.target.value = '' :  changeprice(e, index, perIndex, ind)}} onInput={(e) => createprice(e, it.day, perIndex +1, item.user.id)} type="number"/>: it}</td>
                              )
                            })
                            : ''
                            }
                            <td className="total"><input readOnly={true} value={i.total} type="number"/></td>
                          </tr>
                          )
                        }): ''
                        }
                        </tbody>
                      </table>
                      )
                    }) :  <div className='m-4 alert alert-primary'>This branch don't have workers!</div>
                    }
                  </div>
                    <div className="table-pagination">
                        <div className="page-count">
                            <span>{props.pagination  && props.pagination.count_skip ? props.pagination.skip_page + 1 : '0'}-{props.pagination ? props.pagination.count_skip : ''}</span> of {props.pagination ? props.pagination.count : ''}
                        </div>
                        <div className="row-page">
                            <div className="d-flex align-items-center mr-5">
                                <p>Rows per page:</p>
                                <select className="select-picker cursor-pointer" onChange={ (event) => {pageCount(event)}} data-width="fit">
                                    <option value='5'>5</option>
                                    <option value='10'>10</option>
                                    <option value='15'>15</option>
                                </select>
                            </div>
                            <div>
                                <img src={arrowLeft} onClick={() => {clickLeft()}} alt="Left" className="cursor-pointer mr-5"/>
                                <img src={arrowRight} onClick={() => {clickRight()}} className="cursor-pointer" alt="Right"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" onClick={printPage} className="print-page"><img src={print} alt="Print"/>Print this page</button>
        </div>
  );
};


const mapStateToProps = state => {
    return {
        performances: state.performanceReducer.performances,
        pagination: state.performanceReducer.pagination,
    };
}

const mapDispatchToProps = branch => ({
    getPerformance: (data) => branch(performanceActions.getPerformance(data)),
    performanceCreate: (data) => branch(performanceActions.performanceCreate(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Performance);
