import React, {useEffect, useState} from "react";
import print from "../../../assets/image/icons/print.svg";
import Calendar from "../../components/calendar";
import {connect} from "react-redux";
import {salesReportsActions} from "../../../redux/actions";
import moment from "moment";
import BeatLoader from "react-spinners/BeatLoader";
import {toast, ToastContainer} from "react-toastify";

const SalesReport = (props) => {
  const [loading, setLoading] = useState(false);
  const [salesReports, setSalesReports] = useState([]);

  const getSales = (day) => {
    if (day) {
      setLoading(true)
      if (day && day.previousDay) {
        props.getSalesReport({
          previousDay: day.previousDay,
        }).then(() => {
          setTimeout(() => {
            setLoading(false)
          },1000)
        })
      } else if (day.start_date && day.end_date) {
        props.getSalesReport({
          start_date: day.start_date,
          end_date: day.end_date,
        }).then(() => {
          setTimeout(() => {
            setLoading(false)
          },1000)
        })
      } else if (day.start_date && !day.end_date && !day.previousDay) {
        props.getSalesReport({
          start_date: day.start_date,
        }).then(() => {
          setTimeout(() => {
            setLoading(false)
          },1000)
        })
      } else if (day.days) {
        props.getSalesReport({
          days: day.days,
        }).then(() => {
          setTimeout(() => {
            setLoading(false)
          },1000)
        })
      } else if (day.start_period && day.end_period) {
        props.getSalesReport({
          end_period: day.end_period,
          start_period: day.start_period,
        }).then(() => {
          setTimeout(() => {
            setLoading(false)
          },1000)
        })
      } else if (day.months) {
        props.getSalesReport({
          months: day.months,
        }).then(() => {
          setTimeout(() => {
            setLoading(false)
          },1000)
        })
      }
    }
  };

  useEffect(() => {
    setSalesReports(props.salesReports)
  }, [props.salesReports])

  const printPage = () =>{
    window.print();
  };

  const filter = (calendar) => {
    if (calendar.day) {
      getSales(calendar.day)
    }
  };

  const crateSales = (e , day , typeId, index) => {
    props.salesReportCreate({
      'weekly_sales_report_type_id': typeId,
      'rating': e.target.value,
      'date': day,
    }).then(res => {
      if (res.type === 'CREATE_SALES_REPORTS_FAILURE') {
        if (res.error.status === 422) {
          const data = res.error.data
          const keys = Object.keys(data)
          toast.error(data[keys[0]][0])
        } else {
          toast.error(res.error.data.message)
        }
      }
    })

    let sales = [...salesReports]
    sales[index][typeId] = e.target.value
    setSalesReports(sales)
  };

  return (
    <div className="performance">
      {loading ?
          <div className="loading-block">
            <BeatLoader color="#0A65FF" loading={loading} size={20}/>
          </div> : null
      }
      <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
      />
      <div className="bread-crumbs-mini"><p>Недельный отчет</p></div>
      <div className="performance-block">
        <Calendar calendarFilter={(calendar) => filter(calendar)}/>
        <div className="scroll-table payments-table sales-table">
          <div className="scroll-touch scroll-div">
            <table>
              <thead>
              <tr>
                <th>
                </th>
                <th>Transferred cash</th>
                <th>Transferred check</th>
                <th>Cash sales</th>
                <th>Check sales</th>
                <th>Cash wage</th>
                <th>Cash difference</th>
                <th>Withdraw from bank</th>
                <th>Deposited Cash</th>
                <th>Deposited check</th>
                <th>Transferred Cash</th>
                <th>Transferred check</th>
              </tr>
              </thead>
              <tbody>
              {salesReports && salesReports.length ? salesReports.map((item, index) => {
                return (
                <tr key={index}>
                  {item ? item.map((valueType, i) => {
                    return (
                    i === 0 ?
                    <td key={i}>{moment(valueType).locale('EN').format('dddd')}<span>({valueType})</span></td> :
                    <td key={i}><input onChange={(e) => e.target.value === '00' ? e.target.value = '' : crateSales(e, item[0], i, index)} value={valueType} type="number"/></td>
                    )
                  }) : ''
                  }
                </tr>
                )
              })
              : ''
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button type="button" onClick={printPage} className="print-page"><img src={print} alt="Print"/>Print this page</button>
    </div>
  );
};


const mapStateToProps = state => {
  return {
    salesReports: state.salesReportsReducer.salesReports,
    pagination: state.salesReportsReducer.pagination,
  };
}

const mapDispatchToProps = d => ({
  getSalesReport: (params) => d(salesReportsActions.getSalesReport(params)),
  salesReportCreate: (params) => d(salesReportsActions.salesReportCreate(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport);
