import {salesReportsConstants} from '../constants/actionTypes';
import {salesReportsService} from '../services';

export const salesReportsActions = {
  getSalesReport,
  salesReportCreate,
};

function getSalesReport(data) {
  return dispatch => {
    dispatch(request());
    return  salesReportsService.salesReport(data)
    .then(response => {
      dispatch(success(response.data.data.performances));
    })
    .catch(function (error) {
      dispatch(failure(error.response));
    })
  };

  function request() { return { type: salesReportsConstants.SALES_REPORTS_REQUEST } }
  function success(salesReports) { return { type: salesReportsConstants.SALES_REPORTS_SUCCESS, salesReports } }
  function failure(error) { return { type: salesReportsConstants.SALES_REPORTS_FAILURE, error } }
}

function salesReportCreate(data) {
  return dispatch => {
    return  salesReportsService.addSalesReport(data)
    .then(response => {
      return dispatch(success(response.data));
    })
    .catch(function (error) {
      return dispatch(failure(error.response));
    })
  };

  function success(salesReports) { return { type: salesReportsConstants.CREATE_SALES_REPORTS_SUCCESS, salesReports } }
  function failure(error) { return { type: salesReportsConstants.CREATE_SALES_REPORTS_FAILURE, error } }
}


