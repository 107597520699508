import {http} from "../../modules/http";

export const performanceService = {
  getPerformance,
  addPerformance,
};

function getPerformance(params) {
  params.branch_id = localStorage.getItem('branch')
  const q = new URLSearchParams(params)
  return http.get('/performance?' + q.toString()).then(res => {
    return res
  })
}

function addPerformance(params) {
  return http.post('/performance', params).then(res => {
    return res
  })
}

