import {branchConstants} from '../constants/actionTypes';
import {branchService} from '../services';

export const branchActions = {
  getBranch,
  createBranch,
  updateBranch,
  showBranch,
  deleteBranch,
};

function getBranch() {
  return dispatch => {
    dispatch(request());
    branchService.getBranchAll()
    .then(response => {
      dispatch(success(response.data.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response.data));
    })
  };

  function request() { return { type: branchConstants.GET_BRANCH_REQUEST } }
  function success(branch) { return { type: branchConstants.GET_BRANCH_SUCCESS, branch } }
  function failure(error) { return { type: branchConstants.GET_BRANCH_FAILURE, error } }
}

function createBranch(data) {
  return dispatch => {
    return branchService.addBranch(data)
    .then(response => {
      return dispatch(success(response.data.data));
    })
    .catch(function (error) {
      return dispatch(failure(error.response.data));
    })
  };

  function success(branch) { return { type: branchConstants.CREATE_BRANCH_SUCCESS, branch } }
  function failure(error) { return { type: branchConstants.GET_BRANCH_FAILURE, error } }
}

function updateBranch(data) {
  return dispatch => {
    return branchService.editBranch(data)
    .then(response => {
      return dispatch(success(response.data));
    })
    .catch(function (error) {
      return dispatch(failure(error.response.data));
    })
  };

  function success(branch) { return { type: branchConstants.UPDATE_BRANCH_SUCCESS, branch } }
  function failure(error) { return { type: branchConstants.GET_BRANCH_FAILURE, error } }
}


function showBranch(data) {
  return dispatch => {
   return branchService.showBranch(data)
    .then(response => {
      dispatch(success(response.data));
    })
    .catch(function (error) {
      dispatch(failure(error.response.data));
    })
  };
  function failure(error) { return { type: branchConstants.GET_BRANCH_FAILURE, error } }
  function success(branch) { return { type: branchConstants.SHOW_BRANCH_SUCCESS, branch } }
}

function deleteBranch(id) {
  return dispatch => {
    return branchService.deleteBranch(id)
    .then(response => {
      return dispatch(success(id));
    })
    .catch(function (error) {
      return dispatch(failure(error.response.data));
    })
  };
  function failure(error) { return { type: branchConstants.DELETE_BRANCH_FAILURE, error } }
  function success(id) { return { type: branchConstants.DELETE_BRANCH_SUCCESS, id } }
}

