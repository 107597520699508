import Login from "../../components/pages/SignIn/login";
import forgotPassword from "../../components/pages/SignIn/forgotPassword";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory({
  hashType: "slash",
  basename: ""
});

export const routes = [
  {
    path: "/login",
    component: Login,
    name: "Login",
  },
  {
    path: "/forgot-password",
    component: forgotPassword,
    name: "forgotPassword",
  }
];
