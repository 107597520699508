export const authConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    USER_ME_SUCCESS: 'USER_ME_SUCCESS',
    LOGOUT: 'LOGOUT',
};

export const branchConstants = {
  GET_BRANCH_REQUEST: 'GET_BRANCH_REQUEST',
  GET_BRANCH_SUCCESS: 'GET_BRANCH_SUCCESS',
  GET_BRANCH_FAILURE: 'GET_BRANCH_FAILURE',
  CREATE_BRANCH_SUCCESS: 'CREATE_BRANCH_SUCCESS',
  UPDATE_BRANCH_SUCCESS: 'UPDATE_BRANCH_SUCCESS',
  SHOW_BRANCH_SUCCESS: 'SHOW_BRANCH_SUCCESS',
  DELETE_BRANCH_SUCCESS: 'DELETE_BRANCH_SUCCESS',
  DELETE_BRANCH_FAILURE: 'DELETE_BRANCH_FAILURE',
};

export const userConstants = {
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  GET_ALL_USER_REQUEST: 'GET_ALL_USER_REQUEST',
  GET_ALL_USER_SUCCESS: 'GET_ALL_USER_SUCCESS',
  GET_ALL_USER_FAILURE: 'GET_ALL_USER_FAILURE',

  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',


  UPDATE_USER_INFO_SUCCESS: 'UPDATE_USER_INFO_SUCCESS',
  CREATE_USER_INFO_FAILURE: 'CREATE_USER_INFO_FAILURE',

  UPDATE_USER_PASSWORD_SUCCESS: 'UPDATE_USER_PASSWORD_SUCCESS',
  CREATE_USER_PASSWORD_FAILURE: 'CREATE_USER_PASSWORD_FAILURE',

  USER_ROLE_SUCCESS: 'USER_ROLE_SUCCESS',

  SHOW_USER_SUCCESS: 'SHOW_USER_SUCCESS',
  SHOW_USER_REQUEST: 'SHOW_USER_REQUEST',


  GET_USER_HISTORY_SUCCESS: 'GET_USER_HISTORY_SUCCESS',


  REMOVE_USERS_SUCCESS: 'REMOVE_USERS_SUCCESS',
  ARCHIVE_USERS_SUCCESS: 'ARCHIVE_USERS_SUCCESS',

  FILE_USER_SUCCESS: 'FILE_USER_SUCCESS',
  FILE_USER_FAILURE: 'FILE_USER_FAILURE',


  REMOVE_FILE_USER_SUCCESS: 'REMOVE_FILE_USER_SUCCESS',
};

export const performanceConstants = {
  PERFORMANCE_REQUEST: 'PERFORMANCE_REQUEST',
  PERFORMANCE_SUCCESS: 'PERFORMANCE_SUCCESS',
  PERFORMANCE_FAILURE: 'PERFORMANCE_FAILURE',

  CREATE_PERFORMANCE_REQUEST: 'CREATE_PERFORMANCE_REQUEST',
  CREATE_PERFORMANCE_SUCCESS: 'CREATE_PERFORMANCE_SUCCESS',
  CREATE_PERFORMANCE_FAILURE: 'CREATE_PERFORMANCE_FAILURE',


};

export const paymentsAccountingConstants = {
    PAYMENTS_ACCOUNTING_REQUEST: 'PAYMENTS_ACCOUNTING_REQUEST',
    PAYMENTS_ACCOUNTING_SUCCESS: 'PAYMENTS_ACCOUNTING_SUCCESS',
    PAYMENTS_ACCOUNTING_FAILURE: 'PAYMENTS_ACCOUNTING_FAILURE',

    CREATE_PAYMENTS_ACCOUNTING_SUCCESS: 'CREATE_PAYMENTS_ACCOUNTING_SUCCESS',
    CREATE_PAYMENTS_ACCOUNTING_FAILURE: 'CREATE_PAYMENTS_ACCOUNTING_FAILURE',

    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    USER_WORKER_TYPE_SUCCESS: 'USER_WORKER_TYPE_SUCCESS',

};

export const salesReportsConstants = {
    SALES_REPORTS_REQUEST: 'SALES_REPORTS_REQUEST',
    SALES_REPORTS_SUCCESS: 'SALES_REPORTS_SUCCESS',
    SALES_REPORTS_FAILURE: 'SALES_REPORTS_FAILURE',

    CREATE_SALES_REPORTS_SUCCESS: 'CREATE_SALES_REPORTS_SUCCESS',
    CREATE_SALES_REPORTS_FAILURE: 'CREATE_SALES_REPORTS_FAILURE',

};

export const bonusConstants = {
  BONUS_REQUEST: 'BONUS_REQUEST',
  BONUS_SUCCESS: 'BONUS_SUCCESS',
  BONUS_FAILURE: 'BONUS_FAILURE',

  PAYMENTS_TYPE_REQUEST: 'PAYMENTS_TYPE_REQUEST',
  PAYMENTS_TYPE_SUCCESS: 'PAYMENTS_TYPE_SUCCESS',
  PAYMENTS_TYPE_FAILURE: 'PAYMENTS_TYPE_FAILURE',

  CREATE_BONUS_REQUEST: 'CREATE_BONUS_REQUEST',
  CREATE_BONUS_SUCCESS: 'CREATE_BONUS_SUCCESS',
  CREATE_BONUS_FAILURE: 'CREATE_BONUS_FAILURE',

  UPDATE_BONUS_REQUEST: 'UPDATE_BONUS_REQUEST',
  UPDATE_BONUS_SUCCESS: 'UPDATE_BONUS_SUCCESS',
  UPDATE_BONUS_FAILURE: 'UPDATE_BONUS_FAILURE',

  DELETE_BONUS_REQUEST: 'DELETE_BONUS_REQUEST',
  DELETE_BONUS_SUCCESS: 'DELETE_BONUS_SUCCESS',
  DELETE_BONUS_FAILURE: 'DELETE_BONUS_FAILURE',

  SCHEDULE_BONUS_SUCCESS: 'SCHEDULE_BONUS_SUCCESS',
};

export const paymentConstants = {
  PAYMENT_REQUEST: 'PAYMENT_REQUEST',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  PAYMENT_FAILURE: 'PAYMENT_FAILURE',

  CREATE_PAYMENT_REQUEST: 'CREATE_PAYMENT_REQUEST',
  CREATE_PAYMENT_SUCCESS: 'CREATE_PAYMENT_SUCCESS',
  CREATE_PAYMENT_FAILURE: 'CREATE_PAYMENT_FAILURE',

  UPDATE_PAYMENT_REQUEST: 'UPDATE_PAYMENT_REQUEST',
  UPDATE_PAYMENT_SUCCESS: 'UPDATE_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_FAILURE: 'UPDATE_PAYMENT_FAILURE',

  DELETE_PAYMENT_REQUEST: 'DELETE_PAYMENT_REQUEST',
  DELETE_PAYMENT_SUCCESS: 'DELETE_PAYMENT_SUCCESS',
  DELETE_PAYMENT_FAILURE: 'DELETE_PAYMENT_FAILURE',

  DELETE_PAYMENT_FILE_REQUEST: 'DELETE_PAYMENT_FILE_REQUEST',
  DELETE_PAYMENT_FILE_SUCCESS: 'DELETE_PAYMENT_FILE_SUCCESS',
  DELETE_PAYMENT_FILE_FAILURE: 'DELETE_PAYMENT_FILE_FAILURE',
};

export const spendingCashConstants = {
  SPENDING_CASH_REQUEST: 'SPENDING_CASH_REQUEST',
  SPENDING_CASH_SUCCESS: 'SPENDING_CASH_SUCCESS',
  SPENDING_CASH_FAILURE: 'SPENDING_CASH_FAILURE',

  CREATE_SPENDING_CASH_REQUEST: 'CREATE_SPENDING_CASH_REQUEST',
  CREATE_SPENDING_CASH_SUCCESS: 'CREATE_SPENDING_CASH_SUCCESS',
  CREATE_SPENDING_CASH_FAILURE: 'CREATE_SPENDING_CASH_FAILURE',

  CHANGE_REIMBURSED_SPENDING_CASH_REQUEST: 'CHANGE_REIMBURSED_SPENDING_CASH_REQUEST',
  CHANGE_REIMBURSED_SPENDING_CASH_SUCCESS: 'CHANGE_REIMBURSED_SPENDING_CASH_SUCCESS',
  CHANGE_REIMBURSED_SPENDING_CASH_FAILURE: 'CHANGE_REIMBURSED_SPENDING_CASH_FAILURE',

  CHANGE_RECEIPT_SPENDING_CASH_REQUEST: 'CHANGE_RECEIPT_SPENDING_CASH_REQUEST',
  CHANGE_RECEIPT_SPENDING_CASH_SUCCESS: 'CHANGE_RECEIPT_SPENDING_CASH_SUCCESS',
  CHANGE_RECEIPT_SPENDING_CASH_FAILURE: 'CHANGE_RECEIPT_SPENDING_CASH_FAILURE',
};

export const checkConstants = {
  CHECK_REQUEST: 'CHECK_REQUEST',
  CHECK_SUCCESS: 'CHECK_SUCCESS',
  CHECK_FAILURE: 'CHECK_FAILURE',

  CREATE_CHECK_REQUEST: 'CREATE_CHECK_REQUEST',
  CREATE_CHECK_SUCCESS: 'CREATE_CHECK_SUCCESS',
  CREATE_CHECK_FAILURE: 'CREATE_CHECK_FAILURE',
};

export const searchConstants = {
  SEARCH_REQUEST: 'SEARCH_REQUEST',
  SEARCH_SUCCESS: 'SEARCH_SUCCESS',
  SEARCH_FAILURE: 'SEARCH_FAILURE'
};

export const roleConstants = {
  GET_ROLES_LIST_REQUEST: 'GET_ROLES_LIST_REQUEST',
  GET_ROLES_LIST_SUCCESS: 'GET_ROLES_LIST_SUCCESS',
  GET_ROLES_LIST_FAILURE: 'GET_ROLES_LIST_FAILURE',

  CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILURE: 'CREATE_ROLE_FAILURE',

  UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAILURE: 'UPDATE_ROLE_FAILURE',

  DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILURE: 'DELETE_ROLE_FAILURE',

  GET_ROLE_TYPE_REQUEST: 'GET_ROLE_TYPE_REQUEST',
  GET_ROLE_TYPE_SUCCESS: 'GET_ROLE_TYPE_SUCCESS',
  GET_ROLE_TYPE_FAILURE: 'GET_ROLE_TYPE_FAILURE',
};

export const accountConstants = {
  GET_ACCOUNTS_LIST_REQUEST: 'GET_ACCOUNTS_LIST_REQUEST',
  GET_ACCOUNTS_LIST_SUCCESS: 'GET_ACCOUNTS_LIST_SUCCESS',
  GET_ACCOUNTS_LIST_FAILURE: 'GET_ACCOUNTS_LIST_FAILURE',

  CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILURE: 'CREATE_ACCOUNT_FAILURE',

  UPDATE_ACCOUNT_REQUEST: 'UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAILURE: 'UPDATE_ACCOUNT_FAILURE',

  DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILURE: 'DELETE_ACCOUNT_FAILURE',

  GET_ACCOUNTS_REQUEST: 'GET_ACCOUNTS_REQUEST',
  GET_ACCOUNTS_SUCCESS: 'GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAILURE: 'GET_ACCOUNTS_FAILURE',

  GET_ACCOUNT_TYPE_SUCCESS: 'GET_ACCOUNT_TYPE_SUCCESS',
};
