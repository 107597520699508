import { bonusConstants } from '../constants/actionTypes';

const initialState = {
  bonuses: [],
  pagination: {},
  bonusSchedule: {},
  paymentsType: []
};

export function bonusReducer(state = initialState, action) {
  switch (action.type) {
    case bonusConstants.BONUS_REQUEST:
      return {...state};
    case bonusConstants.SCHEDULE_BONUS_SUCCESS:
      return {...state,
        bonusSchedule: action.bonusSchedule
      };

    case bonusConstants.PAYMENTS_TYPE_SUCCESS:
      return {...state,
        paymentsType: action.paymentsType.data
      };
    case bonusConstants.BONUS_SUCCESS:
      return {
        ...state,
        bonuses: action.bonuses.data,
        pagination: action.bonuses.pagination,
      };
    case bonusConstants.BONUS_FAILURE:
      return {
        ...state,
        bonuses: [],
      };
    case bonusConstants.CREATE_BONUS_REQUEST:
      return {...state};
    case bonusConstants.CREATE_BONUS_SUCCESS:
      let bonusesList = [...state.bonuses];
      bonusesList.unshift(action.bonus.data)
      return {
        ...state,
        bonuses: bonusesList,
      };
    case bonusConstants.CREATE_BONUS_FAILURE:
      return {...state};
    case bonusConstants.UPDATE_BONUS_REQUEST:
      return {...state};
    case bonusConstants.UPDATE_BONUS_SUCCESS:
      let bonusesListData = [...state.bonuses];
      bonusesListData = bonusesListData.map(item => {
        if (item.id === action.bonus.data.id) {
          item = action.bonus.data
        }
        return item
      });
      return {
        ...state,
        bonuses: bonusesListData,
      };
    case bonusConstants.UPDATE_BONUS_FAILURE:
      return {...state};
    case bonusConstants.DELETE_BONUS_REQUEST:
      return {...state};
    case bonusConstants.DELETE_BONUS_SUCCESS:
      let deleteBonusesList = [...state.bonuses];
      deleteBonusesList = deleteBonusesList.filter(item => item.id !== action.bonus);
      return {
        ...state,
        bonuses: deleteBonusesList,
      };
    case bonusConstants.DELETE_BONUS_FAILURE:
      return {...state};
    default:
      return state;
  }
}
