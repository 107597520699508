import * as React from 'react';
import {BrowserRouter, Route , Switch} from 'react-router-dom';
import PageLayout from "./components/layouts/PageLayout";
import AuthPageLayout from "./components/layouts/AuthPageLayout";
import BranchPageLayout from "./components/layouts/BranchPageLayout";
import Cookies from 'universal-cookie';

import {Provider} from "react-redux";

import {history} from "./modules/pageLayout/routes";
import { store } from "./redux/store";

function App() {

  const cookies = new Cookies();
  const access_token = cookies.get('access_token') ? cookies.get('access_token') : localStorage.getItem('access_token');

  return (
    <BrowserRouter>
      <Provider store={store} >
        <Switch>
          <Route history={history}  path="/" component={() => (access_token !== null && !localStorage.getItem('branch') && JSON.parse(localStorage.getItem('user'))?.data.role_id === 1 ? <BranchPageLayout/> : access_token !== null  && (localStorage.getItem('branch') || JSON.parse(localStorage.getItem('user'))?.data.role_id === 2) ? <PageLayout/> :  <AuthPageLayout/>)} />
        </Switch>
      </Provider>
    </BrowserRouter>
  );
}


export default App;
