import { salesReportsConstants } from '../constants/actionTypes';

const initialState = {
  salesReports: []
};

export function salesReportsReducer(state = initialState, action) {
  switch (action.type) {
    case salesReportsConstants.SALES_REPORTS_REQUEST:
      return {...state,
        salesReports: []
      };

    case salesReportsConstants.SALES_REPORTS_SUCCESS:
      return {
        ...state,
        salesReports: action.salesReports,
      };
    case salesReportsConstants.SALES_REPORTS_FAILURE:
      return {
        ...state,
        salesReports: [],
      };

    default:
      return state;
  }
}
