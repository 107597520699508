import { combineReducers } from 'redux';

import { authReducer } from './authReducer';
import { branchReducer } from './branchReducer';
import { userReducer } from "./userReducer";
import { performanceReducer } from "./performanceReducer";
import { paymentsAccountingReducer } from "./paymentsAccountingReducer";
import { bonusReducer } from "./bonusReducer";
import { salesReportsReducer } from "./salesReportsReducer";
import { paymentReducer } from "./paymentReducer";
import { spendingCashReducer } from "./spendingCashReducer";
import { checkReducer } from "./checkReducer";
import { searchReducer } from "./searchReducer";
import { roleReducer } from "./roleReducer";
import { accountReducer } from "./accountReducer";


const rootReducer = combineReducers({
    authReducer,
    branchReducer,
    userReducer,
    performanceReducer,
    paymentsAccountingReducer,
    bonusReducer,
    salesReportsReducer,
    paymentReducer,
    spendingCashReducer,
    checkReducer,
    searchReducer,
    roleReducer,
    accountReducer
});

export default rootReducer;
