import React from "react";
import graph from "../../../assets/image/icons/graph.svg";
import arrowRight from "../../../assets/image/icons/arrow-right.svg";
import {Link} from "react-router-dom";

const Reports = (props) => {
  return (
    <div className="reports">
      <div className="page-title">Отчеты</div>
      <Link to="/reports/bonus-schedule" className="graph-type">
        <div className="d-flex align-items-center">
          <img src={graph} alt="Graph"/>
          <div className="title">
            График бонусов
            <span>(03.20.2021)</span>
          </div>
        </div>
        <div className="right-arrow"><img src={arrowRight} alt="Arrow"/></div>
      </Link>
      <Link to="/reports/all" className="graph-type">
        <div className="d-flex align-items-center">
          <img src={graph} alt="Graph"/>
          <div className="title">
            Все отчеты
            <span>(03.20.2021)</span>
          </div>
        </div>
        <div className="right-arrow"><img src={arrowRight} alt="Arrow"/></div>
      </Link>
    </div>
  );
};

export default Reports;
