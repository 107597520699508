import {performanceConstants} from '../constants/actionTypes';
import {performanceService} from '../services';

export const performanceActions = {
  getPerformance,
  performanceCreate,
};

function getPerformance(data) {
  return dispatch => {
    dispatch(request());
    return  performanceService.getPerformance(data)
    .then(response => {
      dispatch(success(response.data));
      return response
    })
    .catch(function (error) {
      dispatch(failure(error.response));
      return error
    })
  };

  function request() { return { type: performanceConstants.PERFORMANCE_REQUEST } }
  function success(performance) { return { type: performanceConstants.PERFORMANCE_SUCCESS, performance } }
  function failure(error) { return { type: performanceConstants.PERFORMANCE_FAILURE, error } }
}

function performanceCreate(data) {
  return dispatch => {
    // dispatch(request());
    return  performanceService.addPerformance(data)
    .then(response => {
      return dispatch(success(response.data));
    })
    .catch(function (error) {
      return dispatch(failure(error.response));
    })
  };

  function success(performance) { return { type: performanceConstants.CREATE_PERFORMANCE_SUCCESS, performance } }
  function failure(error) { return { type: performanceConstants.CREATE_PERFORMANCE_FAILURE, error } }
}


