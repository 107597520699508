import {authConstants, userConstants} from '../constants/actionTypes';

const initialState = {
  user:  JSON.parse(localStorage.getItem('user')),
  admin: {},
  error: '',
  resetPasswordError: ''
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        user: {},
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case userConstants.UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        admin: action.user.data,
      };
    case authConstants.USER_ME_SUCCESS:
      return {
        ...state,
        admin: action.admin.data,
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        user: {},
        error: action.error
      };
    case authConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        user: {},
        resetPasswordError: action.error
      };
    case authConstants.LOGOUT:
      return {
        ...state,
        user: {},
      };
    default:
      return state;
  }
}
