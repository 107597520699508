import React, {useEffect, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom'
import exit from "../../../assets/image/icons/exit.svg";
import close from "../../../assets/image/icons/close.svg";
import { authActions, branchActions } from "../../../redux/actions";
import { connect } from "react-redux";
import swal from "sweetalert";

const Sidebar = (props) => {
  const history = useHistory()
  const navigation = props.navigationPages

  useEffect(() => {
    props.showBranch(localStorage.getItem('branch')).then(() => {})
  },  []);

  const closeMenu = () => {
    if (window.screen.width <= 420) {
      let sidebar = document.getElementById('sidebar')
      sidebar.style.display = 'none'
    }
  };

  const selectBranchPage = () => {
    if (props.admin.role_id === 1) {
      localStorage.removeItem('branch')
      history.push('/branch-selection')
    }
  };

  const logoutUser = () => {
    swal({
      title: "Вы действительно хотите выйти?",
      buttons: ["Отмена", "Выйти"],
      dangerMode: true,
    }).then((logOut) => {
      if (logOut) {
        props.logout()
      }
    })
  };

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-bg">
      <div className="top-block">
        <div className="logo">
          <img src={props.activeBranch ? props.activeBranch.image : ''} alt={props.activeBranch ? props.activeBranch.name : ''}/>
        </div>
        <div onClick={selectBranchPage} className="title">{props.activeBranch ? props.activeBranch.name : ''}</div>
      </div>
      <div className="close" onClick={closeMenu}>
        <img src={close} alt="Close"/>
      </div>
      <ul className="menu">
        {navigation.map((item, index) => {
        return (
          <div key={index}>
            {item.links.show ?
            <NavLink onClick={() => closeMenu()} activeClassName="active" tag="li" to={item.links.path} className="menu-item" key={index}>
              <img src={item.links.icon} alt="Icon" className="icon"/>
              <p>{item.name}</p>
            </NavLink >
            : null
            }
          </div>
        )
        }
        )}
      </ul>
      <button type="button" onClick={logoutUser} className="bottom-block">
        <img src={exit} alt="Exit"/>
        <p>Exit</p>
      </button>
      </div>
    </div>
 )
}

function mapStateToProps(state) {
  const {admin} = state.authReducer;
  const {activeBranch} = state.branchReducer;
  return {admin, activeBranch};
}

const mapDispatchToProps = d => ({
  logout: () => d(authActions.logout()),
  showBranch: (data) => d(branchActions.showBranch(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
